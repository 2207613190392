export function getFromStorage(key) {
    if(!key)
        return null;

    try {
        const value = localStorage.getItem(key);
        if(value)
            return JSON.parse(value);
        return null;
    }catch(err){
        return null;
    }
}

export function setInStorage(key, object){
    if(!key)
        console.log("Key is missing");
    
    try{
        localStorage.setItem(key, JSON.stringify(object));
    }catch(err){
        console.log(err);
    }
}

export function getFromStorageSession(key) {
    if(!key)
        return null;

    try {
        const value = sessionStorage.getItem(key);
        if(value)
            return JSON.parse(value);
        return null;
    }catch(err){
        return null;
    }
}

export function setInStorageSession(key, object){
    if(!key)
        console.log("Key is missing");
    
    try{
        sessionStorage.setItem(key, JSON.stringify(object));
    }catch(err){
        //console.log(err);
    }
}
export function resetStorage(){
    try{
        localStorage.clear();
        localStorage.setItem("language", "gb");
    }catch(err){
        console.log(err);
    }
}