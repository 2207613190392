import React from "react";
import { getFromStorage, setInStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import GetProducts from "./GetProducts";

import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  FormText,
  Label,
  Col,
  Table
} from "reactstrap";

class CartItemsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      item: 0,
      loading: false,
      vat: 20,
    };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems = () => {
    this.setState({ loading: true });

    try {
      fetch(
        apiLink +
          "/api/cart/getCartItems/?idCustomer=" +
          getFromStorage("idAccount"),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: getFromStorage("token"),
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((responseJson) => {
          if (responseJson.status === 200) {
            let rawData = responseJson.data;

            let products = rawData.reduce((prods, current) => {
              if (!prods[current.id])
                prods[current.id] = { ...current, count: 1 };
              else prods[current.id].count++;

              return prods;
            }, {});

            let items = Object.values(products);

            this.setState({ items: items, loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.props.onLogOut();
        });
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  deleteFromCart(item) {
    //console.log(this.props);
    try {
      fetch(apiLink + "/api/cart/deleteFromCart?id=" + item, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getFromStorage("token"),
        },
      })
        .then((res) => {
          this.props.removeFromCart();
          this.getItems();
          // setInStorage("cartCount", getFromStorage("cartCount") - 1);
          return res.text();
        })
        .catch((error) => {
          this.setState({ notificationError: true });
        });
    } catch (err) {}
  }

  addToCart(item) {
    try {
        fetch(apiLink + "/api/cart/addProdusToCart", {
            method: "POST",
            headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: getFromStorage("token"),
            },
            body: JSON.stringify({
                id_product: item,
                id_customer: getFromStorage("idAccount")
            }),
        }).then((res) => {
            this.props.addToCart();
            this.getItems();

            return res.text();
        }).catch((error) => {
            this.setState({ notificationError: true });
        });
    } catch (err) {}
  }

  render() {
    let language1 = null;
    if (getFromStorage("language") !== null) {
      language1 = require(`../utils/language_` + getFromStorage("language"));
    } else {
      language1 = require(`../utils/language_gb`);
    }
    let language = language1.language;
    let products = [];

    let total = 0;
    let total_vat = 0;

    if (this.state.loading)
      return <ClipLoader size={35} color={"#F79434"} loading={true} />;

    if (!!this.state.items?.length) {
      total =
        this.state.items.reduce((sum, current) => {
          return sum + current.unit_amount * current.count;
        }, 0) / 100;
      total_vat = total + total * this.state.vat / 100;

      products = this.state.items.map(
        function (item, i) {
          let base_price = item.unit_amount;
          let vat_price = base_price + base_price * this.state.vat / 100;

          return (
            <tr key={i} style={{ color: "#000" }}>
              <td style={{ color: "#000" }}>{item.name}</td>
              <td>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => this.deleteFromCart(item.cartID)}
                >
                  -
                </Button>
              </td>
              <td style={{ color: "#000" }}>{item.count}</td>
              <td>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() => this.addToCart(item.id)}
                >
                  +
                </Button>
              </td>
              <td style={{ color: "#000" }}>{base_price / 100} €</td>
              <td style={{ color: "#000" }}>{vat_price / 100} €</td>
            </tr>
          );
        }.bind(this)
      );
    }

    return (
      <>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr style={{ color: "#000" }}>
                  <th style={{ color: "#000" }}>{language.name}</th>
                  <th style={{ color: "#000" }} colSpan="3">
                    {language.qty}
                  </th>
                  <th style={{ color: "#000" }}>{language.amountcart}</th>
                  <th style={{ color: "#000" }}>{`${language.withvat} (${this.state.vat}%)`}</th>
                </tr>
              </thead>
              <tbody style={{ color: "#000" }}>
                {products}
                <tr>
                  <td style={{ color: "#000" }}>{language.totalbalance}</td>
                  <td style={{ color: "#000" }} colSpan="4"></td>
                  {/* <td style={{ color: "#000" }}>{total} €</td> */}
                  <td style={{ color: "#000" }}>{total_vat} €</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <a href={`./checkoutpage/`}>
              <Button color="primary">{language.gotocheckout}</Button>
            </a>
          </Col>
        </Row>
      </>
    );
  }
}

export default CartItemsModal;
