import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import CreditCardInput from 'react-credit-card-input';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';

import Layout from '../utils/Layout';
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  FormText,
  Label,
  Col
} from "reactstrap";

import { useState } from "react";
const NewPaymentMethodInput = (props) => { 
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  const handleFormSubmit = async ev => {
    ev.preventDefault();
      setProcessingTo(true);
      
      const cardElement = await elements.getElement("card");
      let config = {
        headers: {
          'Authorization': getFromStorage('token'),
        }
      }
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      const getTranslate = (value) =>{ 
           let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        return language[value];
      }

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }
    //console.log(paymentMethodReq);
      try {
            await fetch(apiLink + '/api/users/createPaymentMethod', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Authorization': getFromStorage('token'),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({customerId:getFromStorage('idStripe'), payment: paymentMethodReq.paymentMethod.id})
            }).then((res) => {
                  return res.text();   
              }
            ).then( async (responseJson) => {
              var obj = JSON.parse(responseJson);
              //console.log(obj);
              if(obj.status === 200){
                setProcessingTo(false);
                props.prop.notify("tc", getTranslate("successPMAdd"));
                props.prop.updatePaymentMethods();
              }else{
                setProcessingTo(false);
                props.prop.notify("tc", getTranslate("errorResponce"));
              }
            })
          .catch((error) => { 
            props.prop.notify("tc", getTranslate("errorResponce"));
          });

        // 

        
      } catch (err) {
        //console.log(err);
      }finally{
        if(props.prop.success != undefined){
          props.prop.success();
        }
      }
  };    let language1 = null
  if(getFromStorage('language') !== null){
    language1 = require(`../utils/language_`+getFromStorage('language')); 
  }else{
    language1 = require(`../utils/language_gb`); 
  }
  let language = language1.language;
    return (
      <>
          <Row>
            <Col md="12">
            <Card>
                <CardHeader>
                  <h5 className="title">{language.newpaymentmethod}</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <form onSubmit={handleFormSubmit}>
                        <CardElement
                          className="form-control"
                          hidePostalCode={true}
                          options={{
                            style: {
                              base: {
                                fontSize: '16px',
                                color: '#fff',
                                '::placeholder': {
                                  color: '#aab7c4',
                                },
                              },
                              invalid: {
                                color: '#9e2146',
                              },
                            },
                          }}
                          onChange={handleCardDetailsChange}
                        />
                          
                        <Button disabled={isProcessing || !stripe} className="btn-fill" color="primary" type="submit" style={{marginTop: '10px'}}>
                          {isProcessing ? language.processing : language.addcard}
                        </Button>
                      </form>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
      </>
    );
  }


export default NewPaymentMethodInput;
