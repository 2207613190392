import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import CreditCardInput from 'react-credit-card-input';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import DatePicker from "react-datepicker";

import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  FormText,
  Label,
  Col,
  Modal
} from "reactstrap";
class Chat extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loading: true,
            messages: [],
            idDispute: 0,
            mesaj: '',
            customerInfo: [],
            currentDate: '',

        }
        this.section3 = React.createRef();
    }

    handleChange = (e) => {
      this.setState({[e.target.name]: e.target.value})
    }

    componentDidMount() {

        this.state.mesaj = ''
        let search = window.location.search;
        let params = new URLSearchParams(search);
        this.state.idDispute = params.get('idDispute');
        if(getFromStorage("tokenAdmin") != null){
          try{
            fetch(apiLink + '/api/users/getCommentsAdmin?idDispute=' + this.state.idDispute, {
                      method: 'GET',
                      headers: {
                          'Accept': 'application/json',
                          'Authorization': getFromStorage('tokenAdmin'),
                          'Content-Type': 'application/json',
                      }
                  }).then((res) => {
                      return res.text();
                  }
              ).then((responseJson) => {
                  var obj = JSON.parse(responseJson);
                  this.setState({messages: obj.messages});
                  this.setState({customerInfo: obj.customerInfo});
                  this.setState({loading: false});
                  this.setState({data: this.state.messages[0]?.date});
                  console.log(this.state.data)
              })
              .catch((error) => {
                this.props.onLogOut();
              });
        }catch(err){
            //
         }
        }
        else{
        try{
            fetch(apiLink + '/api/users/getComments?idCustomer='+ getFromStorage('idStripe') + '&idDispute=' + this.state.idDispute, {
                      method: 'GET',
                      headers: {
                          'Accept': 'application/json',
                          'Authorization': getFromStorage('token'),
                          'Content-Type': 'application/json',
                      }
                  }).then((res) => {
                      return res.text();
                  }
              ).then((responseJson) => {
                  var obj = JSON.parse(responseJson);
                  this.setState({messages: obj.messages});
                  this.setState({customerInfo: obj.customerInfo});
                  this.setState({loading: false});
              })
              .catch((error) => {
                this.props.onLogOut();
              });
        }catch(err){
            //
         }
        }   
         if(getFromStorage("tokenAdmin") != null){

          try{
            fetch(apiLink + '/api/users/markMessagesAsReadAdmin?idCustomer='+ getFromStorage('idStripe') + '&idDispute=' + this.state.idDispute, {
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Authorization': getFromStorage('tokenAdmin'),
                          'Content-Type': 'application/json',
                      }
                  }).then((res) => {
                      return res.text();
                  }
              ).then((responseJson) => {
                  var obj = JSON.parse(responseJson);
              })
              .catch((error) => {
                this.props.onLogOut();
              });
          }catch(err){
              console.log(err)
          }
        }else{
          try{
            fetch(apiLink + '/api/users/markMessagesAsRead?idCustomer='+ getFromStorage('idStripe') + '&idDispute=' + this.state.idDispute, {
                      method: 'POST',
                      headers: {
                          'Accept': 'application/json',
                          'Authorization': getFromStorage('token'),
                          'Content-Type': 'application/json',
                      }
                  }).then((res) => {
                      return res.text();
                  }
              ).then((responseJson) => {
                  var obj = JSON.parse(responseJson);
              })
              .catch((error) => {
                this.props.onLogOut();
              });
          }catch(err){
              console.log(err)
          }
        }
        //this.section3.current.scrollTo(0,this.section3.current.scrollHeight);
        this.scrollToContent(3);
    }

    sendMessage = () => {
      if(getFromStorage("tokenAdmin") != null){
        if(this.state.mesaj.trim() !== ''){
              try{
                fetch(apiLink + '/api/users/addCommentAdmin' , {
                          method: 'POST',
                          headers: {
                              'Accept': 'application/json',
                              'Authorization': getFromStorage('tokenAdmin'),
                              'Content-Type': 'application/json',
                          },
                          body:JSON.stringify({
                            idDispute: this.state.idDispute,
                            idCustomer: getFromStorage('idStripe'),
                            message: this.state.mesaj
                          })
                      }).then((res) => {
                          return res.text();
                      }
                  ).then((responseJson) => {
                      var obj = JSON.parse(responseJson);
                      this.componentDidMount();
                  })
                  .catch((error) => {
                    this.props.onLogOut();
                  });
              }catch(err){
                  //
              }
            }
            }else{

            if(this.state.mesaj != ''){
              try{
                fetch(apiLink + '/api/users/addComment' , {
                          method: 'POST',
                          headers: {
                              'Accept': 'application/json',
                              'Authorization': getFromStorage('token'),
                              'Content-Type': 'application/json',
                          },
                          body:JSON.stringify({
                            idDispute: this.state.idDispute,
                            idCustomer: getFromStorage('idStripe'),
                            message: this.state.mesaj
                          })
                      }).then((res) => {
                          return res.text();
                      }
                  ).then((responseJson) => {
                      var obj = JSON.parse(responseJson);
                      this.componentDidMount();
                  })
                  .catch((error) => {
                    this.props.onLogOut();
                  });
              }catch(err){
                  //
              }
          }
    }
    }
    convertDate = (unix_timestamp) =>{
      let date = new Date(unix_timestamp);
      // Hours part from the timestamp
      let day = date.getDate();
      // Minutes part from the timestamp
      let month = date.getMonth()+1;
      // Seconds part from the timestamp
      let year = date.getFullYear();

      let hour = date.getHours();
      let minutes = date.getMinutes();

      // Will display time in 10:30:23 format
      // let formattedTime = hour + ":" + minutes;
      return date.toLocaleTimeString('fr');
    }
    compareDates = (unix_timestamp) =>{
      let date = new Date(unix_timestamp);
      let day = date.getDate();
      let month = date.getMonth()+1;
      let year = date.getFullYear();

      let date2 = new Date(this.state.data);
      let day2 = date2.getDate();
      let month2 = date2.getMonth()+1;
      let year2 = date2.getFullYear();

      if(day != day2 || month != month2 || year != year2){
          this.state.data = unix_timestamp;
          return true;
      }else{
        return false;
      } 
    }
    getYearMonthDay = (unix_timestamp) =>{
      let date = new Date(unix_timestamp);
      // Hours part from the timestamp
      let day = date.getDate();
      // Minutes part from the timestamp
      let month = date.getMonth()+1;
      // Seconds part from the timestamp
      let year = date.getFullYear();

      // Will display time in 10:30:23 format
      // let formattedTime = year + "-" + month +"-" + day;
      return date.toLocaleDateString('fr');
    }
    showMessage = (tip, mesaj, dataNoua, citit) => {
        let template = []
        if(this.compareDates(dataNoua)){
          template.push(
            <>
            <h2 style={{width: '100%', textAlign:'center', borderBottom:'1px solid #000', lineHeight: '0.1em', margin:' 10px 0 20px'}}>
              <span style={{background: '#27293d', padding: '0 10px'}}>{this.getYearMonthDay(dataNoua)}</span>
              </h2>
            </>
          );
        }
        if(getFromStorage("tokenAdmin") != null){
          if(tip === 1){
            template.push(<>
              <Col md={5} style={{ marginTop: 15, marginBottom: 15,}}>
              <Row>
              <Col md={8}>
              <div style={{padding: 15, borderRadius: 45, background: 'black', color:"white"}}>
              {mesaj}
            </div>
              </Col>
              <Col md={2}><h6>{this.convertDate(dataNoua)}</h6></Col>
              </Row>
              </Col></>
          )
          }else{
            template.push(<Col md={{ size: 5, offset: 7}}  style={{ marginTop: 15, marginBottom: 15,}}>
              <Row>
              <Col md={2}><h6>{this.convertDate(dataNoua)}</h6></Col>
              <Col md={8}>
              <div style={{padding: 15, borderRadius: 45, background: 'black', color:"white"}}>
              {(citit) ? (<>{mesaj}</>) : (<div style={{color: 'green'}}>{mesaj}</div>)}
            </div>
              </Col>
              </Row>
              </Col>)
          }
      }else{
        if(tip === 0){
          template.push(<>
            <Col md={5} style={{ marginTop: 15, marginBottom: 15,}}>
            <Row>
            <Col md={8}>
            <div style={{padding: 15, borderRadius: 45, background: 'black', color:"white"}}>
            {mesaj}
          </div>
            </Col>
            <Col md={2}><h6>{this.convertDate(dataNoua)}</h6></Col>
            </Row>
            </Col></>)
        }else{
          template.push(<Col md={{ size: 5, offset: 7}}  style={{ marginTop: 15, marginBottom: 15,}}>
            <Row>
            <Col md={2}><h6>{this.convertDate(dataNoua)}</h6></Col>
            <Col md={8}>
            <div style={{padding: 15, borderRadius: 45, background: 'black', color:"white"}}>
            {(citit) ? (<>{mesaj}</>) : (<div style={{color: 'green'}}>{mesaj}</div>)}
          </div>
            </Col>
            </Row>
            </Col>)
        }
      }
      
        return template;
    }
    scrollToContent = (content) =>  {
      console.log(content);
      switch(content) {
            case 3:
              if(this.section3.current)
              this.section3.current.scrollTo({
                top: this.section3?.current?.scrollHeight,
                behavior: 'smooth'
              });
              break;
      }
    }
    componentDidUpdate() {
      this.scrollToContent(3);
    }
    render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;

    let template = []
    this.state.messages.forEach((item) => {
      template.push(this.showMessage(item.sent,item.message,item.date,item.citit));
    });

    return(
        
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h3 className="title"> {(getFromStorage("tokenAdmin") != null) ? (<>{language.chatsupport} {language.with} {this.state.customerInfo[0]?.name} {language.from} {this.state.customerInfo[0]?.company}</>) : language.chatsupport }</h3>
                </CardHeader>
                <div></div>
                {   
                    (!this.state.loading) ?
                      (<>
                    <div></div>
                    <div  ref={this.section3} style={{overflowY: 'auto', maxHeight: 400}}>
                    <Card >
                      {template}
                      </Card></div>
                    <div></div>
                    <Row>
                         <Col md="5">
                                <FormGroup>
                                  <Input 
                                     cols="100"
                                     type="textarea"
                                     placeholder={language.chatmessage}
                                      rows="4"
                                      name="mesaj"
                                      style={{ margin: 25}}
                                      id="mesaj"
                                      onChange={this.handleChange}
                                      value={this.state.mesaj}
                                      disabled={this.state.customerInfo[0]?.status}
                                      onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                          this.sendMessage();
                                        }
                                      }}
                                  />
                                  <div style={{marginLeft: 25, marginBottom: 5}}>{this.state.mesaj.length} characters of 255</div>
                                 </FormGroup>
                              </Col>
                    </Row>
                    <Row>
                           <Col>
                            <Button color="primary" onClick={this.sendMessage} style={{ marginLeft: 25, marginBottom: 25}} disabled={this.state.customerInfo[0]?.status}>{language.send}</Button>
                          </Col>
                         </Row>
                         </>):(
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                />
                            </Col>
                    </Row>
                      )
                }
              </Card>
            </Col>
          </Row>
        </div>
      </>
        )
    }

}

export default Chat;