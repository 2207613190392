import React from "react";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ClipLoader from "react-spinners/ClipLoader";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
import ChangePassword from './ChangePassword.js';

import {
    Button,
    Card,
    UncontrolledAlert,
    FormFeedback,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    FormText,
    Label,
    Col,
    ButtonGroup
  } from "reactstrap";
import ChangeProfile from "./ChangeProfile";


  class ProfileInfo extends  React.Component {

    constructor(props){
        super(props);
        console.log(props);
        this.state = {
            loading: false,
            showButton: false,
            nume: null,
            country: null, 
            region: null,
            address1: null,
            borderColor: "#2b3553",
            taxIDs:this.props.taxIDs,
            disableInput: false,
            postal_code: null,
            companie: null,
            cui: null,
            loadingInfo: false,
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeHide = this.handleChangeHide.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        }

    selectCountry (val) {
    this.setState({ country: val });
    }

    selectRegion (val) {
    this.setState({ region: val });
    }
    handleChange(event) {
    this.setState({ [event.target.name] : event.target.value})
    }

    handleChangeHide(event) {
        this.setState({ [event.target.name] : event.target.value, showButton: true })
    }

    getTranslate = (value) =>{
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        return language[value];
    }

    componentDidMount() {
        //console.log(this.state);
        try{
            this.setState({loadingInfo: true});
            fetch(apiLink + '/api/users/getCustomerInfo?idCustomer=' + getFromStorage('idStripe'), {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('token'),
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    console.log(obj);
                    this.setState({nume:obj.data?.name, country: obj.data.address?.country, region: obj.data.address?.city, address1: obj.data.address?.line1, postal_code: obj.data.address?.postal_code, companie: obj.data.description, cui: obj.data.metadata?.cui, loadingInfo: false});
                })
            .catch((error) => {
                this.setState({loadingInfo: false});
                this.props.onLogOut();
            });
        }catch(err){
            this.setState({notificationError: true, loading: true, loadingInfo: false})
        }
    }

    onSubmit = () => {
        this.setState({loading: true})
        // //console.log(this.state);
        try{
            fetch(apiLink + '/api/users/updateCustomer', {
                method: 'POST',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getFromStorage("token")
                },
                body: JSON.stringify({...this.state, idCustomer: getFromStorage('idStripe')} )
            }).then((res) => {
                //console.log(res);
                    this.setState({loading: false});
                    if(res.status == 200){
                        this.props.notify("tc", this.getTranslate("successCustomerUpdate"));
                        //
                    } else {
                        this.props.notify("tc", this.getTranslate("errorResponce"));
                    }this.setState({loading: false});
                    return res.text();   
                }
            )
            .catch((error) => {
            this.setState({notificationError: true, loading: true})
            }).finally(() => {
                this.props.updatePage();
            })
        }catch(err){
            this.props.notify("tc", this.getTranslate("errorResponce"));
            this.setState({notificationError: true, loading: true})
        }
    }

    addTax = () => {
        this.setState({loading: true})
        //console.log(this.state);
        try{
            fetch(apiLink + '/api/users/addTax', {
                method: 'POST',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getFromStorage("token")
                },
                body: JSON.stringify({...this.state, idCustomer: getFromStorage('idStripe')} )
            }).then((res) => {
                this.setState({loading: false})
                if(res.status == 200){
                    this.props.notify("tc", this.getTranslate("successTaxIdAdd"));
                    this.props.updatePage();
                } else {
                    this.props.notify("tc", this.getTranslate("errorResponce"));
                }
                this.componentDidMount();
                return res.text();   
            })
            .catch((error) => {
            this.setState({notificationError: true, loading: true})
            });
        }catch(err){
            this.setState({notificationError: true, loading: true})
        }
    }

    render() {
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        //console.log(this.state.taxIDs.length);
        if(this.props.taxIDs?.length >= 1){
            this.state.disableInput = true;
        }

        console.log(this.props)
        const { country, region } = this.state;
        return(
            <Row>
            <Col md="12">
            <Card>
                <CardHeader>
                  <h5 className="title">{language.updateprofile}</h5>
                </CardHeader>
                <CardBody>
                  {this.state.loadingInfo ? ( <ClipLoader
                      size={43}
                      color={"#F79434"}
                      loading={this.state.loadingInfo}
                    /> ) : ( <>
                    <Row>
                        <Col>
                            <Label>{language.nume}</Label>
                            <Input
                                placeholder= {language.nume}
                                name="nume"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.nume}
                                // invalid={this.state.fieldError}
                            />
                        </Col>
                        <Col>
                            <Label>{language.companie}</Label>
                            <Input
                                placeholder= {language.companie}
                                name="companie"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.companie}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" style={{color:'#fff'}}>   
                            <Label>{language.country}</Label>    
                            <CountryDropdown classes="form-control"
                                value={country}
                                valueType="short"
                                onChange={(val) => this.selectCountry(val)} 
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>{language.region}</Label>
                            <RegionDropdown classes="form-control"
                                country={country}
                                value={region}
                                countryValueType="short"
                                onChange={(val) => this.selectRegion(val)} 
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>{language.address}</Label>
                            <Input
                                placeholder= {language.address}
                                name="address1"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.address1}
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
        <Label>{language.postal}</Label>
                            <Input
                                placeholder= {language.postal}
                                name="postal_code"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.postal_code}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>N° TVA (FR0000000) or Siren N°, NIF</Label>
                            <Input
                                placeholder= "CUI"
                                name="cui"
                                type="text"
                                disabled = {(this.state.disableInput)? "disabled" : ""}
                                onChange={this.handleChange}
                                value={this.state.cui}
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>{language.taxid}</Label>
                            <Input
                                placeholder={language.taxid}
                                id="tax"
                                name="tax_id"
                                type="text"
                                disabled = {(this.state.disableInput)? "disabled" : ""}
                                onChange={this.handleChangeHide}
                            />                             
                            {
                                (this.state.showButton && !this.state.disableInput) ?
                                (   
                                    <ButtonGroup
                                        className="btn-group-toggle float-right"
                                        data-toggle="buttons"
                                    >   
                                        <Button size="sm"  className="btn-fill" color="primary" type="submit" onClick={this.addTax}>
                                            {language.addTax}
                                        </Button>
                                    </ButtonGroup>
                                ):(
                                    <></>
                                )
                            }
                        </Col>
                    </Row>
                        {
                                (!this.state.loading) ?
                                ( <Row style={{marginTop: "15px"}}>
                                        <Col>
                                            <Button className="btn-fill" color="primary" type="submit" onClick={this.onSubmit}>
                                                {language.update}
                                            </Button>
                                        </Col>
                                        
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                        >
                                            <ChangeProfile notify={this.props.notify} />
                                            <ChangePassword notify={this.props.notify}/>
                                        </ButtonGroup>
                                        
                                    </Row>                                 
                                ):(<Row>
                                    <Col>
                                    <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                    />
                                    </Col>
                                    </Row>
                                )
                            }
                           </> )}
                </CardBody>
                <CardFooter>
                  
                </CardFooter>

            </Card>
            </Col>
            </Row>
        )
    }
  }
  export default ProfileInfo;