import React from "react";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ClipLoader from "react-spinners/ClipLoader";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
import ChangePassword from './ChangePassword.js';

import {
    Button,
    Card,
    UncontrolledAlert,
    FormFeedback,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    FormText,
    Label,
    Col,
    ButtonGroup
  } from "reactstrap";
import { language } from "utils/language_gb";


  class SubscriptionSetting extends  React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            error: false,
            subscription: {},
            options: {},
            ip: "",
            ora: ""
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value})
    }

    getTranslate = (value) =>{
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        return language[value];
      }

    componentDidMount() {
        //console.log(this.props);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let idSubs = params.get('idSubs');
        fetch(apiLink + '/api/users/getSubscriptionInfo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': getFromStorage('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({idSubscription: idSubs} )
        }).then((res) => {
            return res.text();   
        }).then((responseJson) => {
            let obj = JSON.parse(responseJson);
            if(obj.status != 200){
                this.props.notify("tc", this.getTranslate("erroroccurred"));
                this.setState({error: true, loading: true})
            }else{
                //console.log(obj)
                this.setState({subscription: obj.data, options: obj.data2, ip: obj.data2.ip, ora: obj.data2.ora, loading: false})
            }
        }).catch((error) => {
            this.props.notify("tc", this.getTranslate("erroroccurred"));
            this.setState({error: true, loading: true})
        });
    }
    

    onSubmit = () => {
        fetch(apiLink + '/api/users/subssettings', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': getFromStorage('token'),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({idCustomer: this.state.subscription.customer, plan: this.state.subscription.id, ip: this.state.ip, ora: this.state.ora} )
        }).then((res) => {
            return res.text();   
        }).then((responseJson) => {
            let obj = JSON.parse(responseJson);
            if(obj.status != 200){
                this.props.notify("tc", this.getTranslate("erroroccurred"));
                this.setState({error: true, loading: true})
            }else{
                //console.log("DASDASDASDASDASDA")
                this.props.notify("tc", this.getTranslate("setarisalvate"));
            }
        }).catch((error) => {
            this.props.notify("tc", this.getTranslate("erroroccurred"));
            this.setState({error: true, loading: true})
        });
    }

    render() {
        let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    let template = [];
        return(
            <>
                <div className="content">
                    {(!this.state.loading) ? (
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                    <h4 className="title">{language.subscriptionSetting}</h4>
                                </CardHeader>
                                <CardBody>
                                    <form >
                    <Label>{language.ipaddress}</Label>
                                        <Input
                                            placeholder= "00.00.00.00"
                                            name="ip"
                                            type="text"
                                            value={this.state.ip}
                                            onChange={this.handleChange}
                                        />
                                        <Label>{language.timeSMS}</Label>
                                        <Input
                                            placeholder= "(0-24)"
                                            name="ora"
                                            type="text"
                                            value={this.state.ora}
                                            onChange={this.handleChange}
                                        />
                                        <Button color="primary" onClick={this.onSubmit}>
                                            {language.save}
                                        </Button>
                                    </form>
                                </CardBody>
                                <CardFooter>
                                
                                </CardFooter>

                            </Card>
                        </Col>
                    </Row>):(
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Row>)}
                </div>
            </>
        )
    }
  }
  export default SubscriptionSetting;