import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import CreditCardInput from 'react-credit-card-input';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';

import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  FormText,
  Label,
  Col
} from "reactstrap";

class ListSubscriptions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      subscriptions: []
    }
  }
  componentDidMount() {
      this.setState({loading: true})
    try{
        fetch(apiLink + '/api/users/getSubscriptions?idCustomer=' + getFromStorage('idStripe'), {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                this.setState({subscriptions: obj.data.data, loading:false});
            })
        .catch((error) => {
            this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }

  cancel(idSubscription){
    //console.log(idSubscription);
    try{
        fetch(apiLink + '/api/users/cancelSubscription', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': getFromStorage('token'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({idSubscription: idSubscription, idCustomer: getFromStorage("idStripe")} )
            }).then((res) => {
                this.componentDidMount();
                return res.text();   
            }
        ).catch((error) => {
            this.setState({notificationError: true, loading: true})
            
        });
    }catch(err){
        //
    }
  }

  convertDate = (unix_timestamp) =>{
        let date = new Date(unix_timestamp * 1000);
        // Hours part from the timestamp
        let day = date.getDate();
        // Minutes part from the timestamp
        let month = date.getMonth()+1;
        // Seconds part from the timestamp
        let year = date.getFullYear();

        // Will display time in 10:30:23 format
        let formattedTime = day+ "/"+month+"/"+year;
        return formattedTime;
  }


  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    let template = [];
    if(this.state.subscriptions?.length > 0){
        template = this.state.subscriptions.map(function(item, i){
            return(
                <tr key={i}>
                    <td>
                        {item.plan.nickname}
                    </td>
                    <td>
                        {this.convertDate(item.start_date)}
                    </td>
                    <td>
                    {(item.cancel_at_period_end)? "-":this.convertDate(item.current_period_end)}{}
                    </td>
                    <td>
                        {item.plan.amount/100} {item.plan.currency}/{item.plan.interval}
                    </td>
                    <td>
                        {(item.cancel_at_period_end)? language.canceled + this.convertDate(item.current_period_end)+")":item.status}
                    </td>
                    <td>{(item.cancel_at_period_end)?(<></>):
                        (<Button className="btn-sm" color="danger" onClick={() => this.cancel(item.id)}>
                            {language.cancelplan}
                        </Button>)}
                        </td><td>
                    { // <a href={`./subscriptionSet?idSubs=` + item.id}>
        }
                        <Button disabled className="btn-sm" color="primary">
                        {language.setari}
                        </Button>
                    </td>
                </tr>
            )
        }.bind(this))
    }
    return (
      <>
          <Row>
            <Col md="12">
            <Card>
                <CardHeader>
                  <h3 className="title">{language.subscriptionid}</h3>
                </CardHeader>
                
                <CardBody>
                {
                    (!this.state.loading) ?
                      (                                    
                        <Row>
                        <Col>  
                            <Table>
                                <thead className="text-primary">
                                    <tr>
                                        <th>
                                        {language.subscriptionplan}
                                        </th>
                                        <th>{language.startingdate}</th>
                                        <th>{language.nextchargedate}</th>
                                        <th>{language.priceid}</th>
                                        <th>{language.status}</th>
                                        <th>{language.actions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {template}
                                </tbody>
                            </Table> 
                        </Col>
                    </Row>
                      ):(
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Row>
                      )
                  }
                    
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
      </>
    );
  }
}

export default ListSubscriptions;
