import React from "react";
import { useState } from "react";
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import axios from "axios";

import { apiLink } from './constants';

import { getFromStorage, setInStorage, resetStorage } from './storage';
import {Row, Button, Col} from 'reactstrap';
import { language } from "./language_gb";

const CheckoutOpenInvoices = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    // if(props.customer.nameValid && props.customer.emailValid && props.customer.email.length > 5){

      setProcessingTo(true);
      
      const cardElement = elements.getElement("card");
      //console.log("ASDDSSAS")

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
    });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      try {

          const { data: clientSecret } = await axios.post(apiLink + '/api/users/paymentIntentCheckoutFinal?idInvoice=' + props.idInvoice, {
            });
            const { error } = await stripe.confirmCardPayment(clientSecret, {
              payment_method: paymentMethodReq.paymentMethod.id
            });

          if (error) {
            setCheckoutError(error.message);
            setProcessingTo(false);
            //console.log(error);
            //return;
          }
          window.location.href = "/admin/invoices";
      } catch (err) {
        //console.log(err);
      }finally {
        
      }
    // }else{
    //   props.notify("tc");
    //   //console.log("nope");
    // }
  };

  let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
  
    return(
      
      <form onSubmit={handleFormSubmit}>
            <CardElement
              className="form-control"
              hidePostalCode={true}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#000',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
              onChange={handleCardDetailsChange}
            /><Button disabled={isProcessing || !stripe} className="btn-fill" color="primary" type="submit">
                    {isProcessing ? language.processing : language.pay}
                  </Button>
        </form>
    )
  
}
export default CheckoutOpenInvoices;
