import React from 'react';
import { getFromStorage } from '../utils/storage';
import { apiLink, language } from '../utils/constants';

import {
    Button,
    Card,
    UncontrolledAlert,
    FormFeedback,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    FormText,
    Label,
    Col,
    Modal
  } from "reactstrap";
  import ClipLoader from "react-spinners/ClipLoader";

class ListCustomers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            loading: true,
            filter: ''
        }
    }

    setFilter = (e) => {
        this.setState({filter: e.target.value})
    }

    componentDidMount() {
        fetch(apiLink + '/api/admin/getCustomers?page=0', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': getFromStorage('tokenAdmin'),
                'Content-Type': 'application/json',
            }
            }).then((res) => {
                return res.text();   
            }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                console.log(obj);
                this.setState({customers: obj.customers, loading: false});
            })
        .catch((error) => {
            this.props.onLogOut();
        });
    }

    render() {
        let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
        let template = [];
        if(this.state.customers && this.state.customers.length > 0){
            template = this.state.customers.map(function(item, i){
                if(item.name?.toLowerCase().includes(this.state.filter) || item.description?.toLowerCase().includes(this.state.filter))
                return (
                    <>
                        <tr>
                            <td>{item.name}</td>
                            <td>{item.description}</td>
                            <td>{item.email}</td>
                            <td>
                                {item.address?.country}
                            </td>
                            <td>
                            <a href={`./customerInfo?idCustomer=` + item.id}><Button className="btn-sm" color="info" type="submit">
                                {language.viewcustomer}
                                </Button></a>
                            </td>
                        </tr>
                    </>
                )
            }.bind(this))
        }
        return(<>
        <div className="content">
        
            <Row>
            <Col md="12">
            <Card>
                <CardHeader>
        <h5 className="title">{language.customers}</h5>
                </CardHeader>
                
                <CardBody>
                <Row>
                <Col>
        <Label>{language.nume}</Label>
                            <Input
                                placeholder= {language.nume}
                                name="nume"
                                type="text"
                                onChange={this.setFilter}
                                value={this.state.filter}
                                // invalid={this.state.fieldError}
                            />
                </Col>
            </Row>{
                    (!this.state.loading) ?
                      (                                    
                        <Row>
                        <Col>  
                            <Table className="tablesorter" >
                                <thead className="text-primary">
                                    <tr>
                                        <th>{language.customername}
                                        
                                        </th>
                      <th>{language.companie}</th>
                      <th>{language.email}</th>
                      <th>{language.country}</th>
                      <th>{language.actiuni}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {template}
                                </tbody>
                            </Table> 
                        </Col>
                    </Row>
                      ):(
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Row>
                      )
                  }
                    
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
                    </div>
        </>)
    }
}

export default ListCustomers;