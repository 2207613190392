import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import CreditCardInput from 'react-credit-card-input';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';

import Layout from '../utils/Layout';
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  FormText,
  Label,
  Col
} from "reactstrap";

import { useState } from "react";
import NewPaymentMethodInput from "./NewPaymentMethodInput";
class NewPaymentMethod extends React.Component { 
  constructor(props){
    super(props);
  }
    render() {
      let language1 = null
      if(getFromStorage('language') !== null){
        language1 = require(`../utils/language_`+getFromStorage('language')); 
      }else{
        language1 = require(`../utils/language_gb`); 
      }
      let language = language1.language;
    return (
      <>
                    <Layout>
                        <NewPaymentMethodInput prop = {this.props}></NewPaymentMethodInput>
                    </Layout>
                    
      </>
    );
  }
}

export default NewPaymentMethod;
