import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import GetProducts from "./GetProducts";

import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  FormText,
  Label,
  Col
} from "reactstrap";

class AddProduct extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      titlu: "",
      descriere: "",
      pret: 0,
      productType: "product",
      loading: false,
      fieldError: false,
      notificationError: false,
      notificationSuccess: false,
      file: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.inputFile = this.inputFile.bind(this);
    this.upload = this.upload.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name] : event.target.value, fieldError: false })
  }

  inputFile(event) {
    this.setState({ file: event.target.files[0]})
  }

  onSubmit = () => {
    this.setState({loading: true})
    try{
      fetch(apiLink + '/api/produse/addProdus', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getFromStorage("token")
          },
          body: JSON.stringify(this.state)
        }).then((res) => {
              return res.text();   
          }
        ).then((responseJson) => {
          var obj = JSON.parse(responseJson);
          if(obj.status === 200){
            this.setState({notificationSuccess: true, loading: false, 
              titlu: "",
              descriere: "",
              pret: 0,
              productType: "product"});
          }else{
            if(obj.data === "101"){
              this.setState({fieldError: true, loading: false})
            }else{
              this.setState({notificationError: true, loading: false})
            }
          }
      })
      .catch((error) => {
        this.setState({notificationError: true, loading: false})
      });
    }catch(err){
      this.setState({notificationError: true, loading: false})
    }
  }

  upload() {
    let config = {
      headers: {
        'Authorization': getFromStorage("token")
      }
    }
    const data = new FormData()
    data.append('file', this.state.file);
    axios.post(apiLink + '/upload', data, config, {}).then(res => { 
      //console.log(res); 
    })
  }


  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    //console.log(language)
    return (
      <>
        <div className="content">
          
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{language.newproduct}</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                      {
                          (this.state.notificationSuccess) ? 
                          (
                            <UncontrolledAlert color="primary">
                                <span>
                                  <b>{language.addproduct}</b>
                                </span>
                            </UncontrolledAlert>
                          ):(<></>)
                        }
                        {
                          (this.state.notificationError) ? 
                          (
                            <UncontrolledAlert color="danger">
                                <span>
                                  <b>{language.problemconnection}</b>
                                </span>
                            </UncontrolledAlert>
                          ):(<></>)
                        }
                        </Col>
                    </Row>
                    <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>{language.producttitle}</label>
                          <Input
                            placeholder="Title"
                            name="titlu"
                            type="text"
                            onChange={this.handleChange}
                            invalid={this.state.fieldError}
                          />
                          <FormFeedback>{language.shorttitle}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col className="px-md-1" md="4">
                        <FormGroup>
                          <label>{language.price}</label>
                          <Input
                            placeholder="100"
                            name="pret"
                            type="number"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                          {language.producttype}
                          </label>
                          <Input type="select" name="productType" onChange={this.handleChange}>
                            <option value="product">{language.product}</option>
                            <option value="subscription">{language.subscription}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="8">
                        <FormGroup>
                          <label>{language.description}</label>
                          <Input
                            cols="80"
                            placeholder={language.yourdescription}
                            rows="4"
                            type="textarea"
                            name="descriere"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                  {
                    (!this.state.loading) ?
                      (                                    
                        <Button className="btn-fill" color="primary" type="submit" onClick={this.onSubmit}>
                          {language.save}
                        </Button>
                      ):(
                        <ClipLoader
                          size={43}
                          color={"#F79434"}
                          loading={this.state.loading}
                        />
                      )
                  }
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <GetProducts/>
        </div>
      </>
    );
  }
}

export default AddProduct;
