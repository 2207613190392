import React from "react";
import {Container, Row, Col, FormGroup, Input, FormFeedback, ButtonGroup} from 'reactstrap';
import 'assets/css/login_style.css';
import logo from "assets/img/logo.png";
import ClipLoader from "react-spinners/ClipLoader";
import PhoneInput from 'react-phone-input-2';
import ReactFlagsSelect from 'react-flags-select';
import { setInStorage, getFromStorage,resetStorage } from 'utils/storage';
import ForgotPassword from 'views/ForgotPassModal.js';
import NotificationAlert from "react-notification-alert";
import axios from "axios";

import { apiLink } from 'utils/constants';
class Login extends React.Component {
  constructor(props) {
    super(props);
    
    this.state ={
      company: "",
      password: "",
      loading: false,
      showError: false,
      errorMessage: '',
      borderColor: "#000",
      login: 0,
      country: "ro",
      name: "",
      email: "",
      phone: "",
      confirmPass:"",
      lastDigits: 1234,
      idAccount: 0,
      code: "",
      componentName: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
  }

  notify = (place,text) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleChange(event) {
    this.setState({ [event.target.name] : event.target.value, showError: false })
  }
  selectLanguage(val) {
    //console.log(val);
    //console.log(val.toLowerCase());
    this.props.updateLanguage(val.toLowerCase());
  }

  onLogin = () => {
    //console.log("DASDASDASDASDSD")
    this.setState({loading: true})
    try{
      fetch(apiLink + '/api/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username: this.state.username,
            password: this.state.password
          })
        }).then((res) => {
              return res.text();   
          }
        ).then((responseJson) => {
          var obj = JSON.parse(responseJson);
          if(obj.status === 200){
            this.setState({showError: false, loading: false});
            
            resetStorage();
            
          console.log(obj.token2);
            setInStorage('token', obj.data);
            setInStorage('idAccount', obj.idAccount);
            setInStorage('idStripe', obj.idStripe);
            setInStorage('customerName', obj.name);
            setInStorage("customerAvatar", obj.avatar);
            
            axios.defaults.headers.common['Authorization'] = obj.data;
          console.log(obj.token2);
          if(obj.token2 != null){
            setInStorage('tokenAdmin', obj.token2);
          }
            setInStorage('isNotSet', false);
            //console.log(obj.idStripe)
            this.props.updateToken(obj.data);
          }else if(obj.status === 203){
            this.setState({loading: false, login: 2, lastDigits: obj.lastDigits, idAccount: obj.idAccount});
          }else
            this.setState({showError: true,errorMessage: obj.data, loading: false});
      })
      .catch((error) => {
            this.setState({showError: true,errorMessage: "Connection Problem", loading: false});
      });
    }catch(err){
     this.setState({showError: true,errorMessage: "Connection Problem", loading: false});
    }
 }
 onSendCode = () => {
  //console.log("DASDASDASDASDSD")
  this.setState({loading: true})
  try{
    fetch(apiLink + '/api/login/checkCode', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idAccount: this.state.idAccount,
          code: this.state.code
        })
      }).then((res) => {
            return res.text();   
        }
      ).then((responseJson) => {
        var obj = JSON.parse(responseJson);
        if(obj.status === 200){
          this.setState({showError: false, loading: false});
          setInStorage('token', obj.data);
          setInStorage('token', obj.data);
          setInStorage('idAccount', obj.idAccount);
          this.props.updateToken(obj.data);
        }else
          this.setState({showError: true,errorMessage: obj.data, loading: false});
    })
    .catch((error) => {
          this.setState({showError: true,errorMessage: "Connection Problem", loading: false});
    });
  }catch(err){
   this.setState({showError: true,errorMessage: "Connection Problem", loading: false});
  }
}
getTranslate = (value) =>{
  let language1 = null
  if(getFromStorage('language') !== null){
    language1 = require(`../utils/language_`+getFromStorage('language')); 
  }else{
    language1 = require(`../utils/language_gb`); 
  }
  let language = language1.language;
  return language[value];
}
 onRegister = () => {
   if(this.ValidateEmail(this.state.email)){
    if(this.state.password === this.state.confirmPass && this.state.password.trim() !==""){
      this.setState({loading: true})
      try{
        fetch(apiLink + '/api/users/addCustomer', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              company: this.state.company,
              password: this.state.password,
              name: this.state.name,
              country: this.state.country,
              email:this.state.email,
              phone: this.state.phone
            })
          }).then((res) => {
                return res.text();
            }
          ).then((responseJson) => {
            var obj = JSON.parse(responseJson);
            if(obj.status === 200){
              this.setState({showError: false, loading: false, login: 0});
            }else
              this.setState({showError: true,errorMessage: obj.data, loading: false, componentName: obj.componentName});
        })
        .catch((error) => {
              this.setState({showError: true,errorMessage: "Connection Problem", loading: false});
        });
      }catch(err){
      this.setState({showError: true,errorMessage: "Connection Problem", loading: false});
      }
    }else{
      this.setState({showError: true,errorMessage: this.getTranslate("errorpass"), loading: false, componentName: "password"});
    }
  }else{
    this.setState({showError: true,errorMessage: this.getTranslate("validemail"), loading: false, componentName: "email"});
  }
}

ValidateEmail = (mail) =>
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
        return (true)
    }
        return (false)
}

 onFocusPhone = () => {
  this.setState({borderColor: "#F79434"})
 }
 onBlur = () => {
    this.setState({
      borderColor: '#000'
    })
  }

  onChangeCountry = (obj) => {
    this.setState({country: obj.toLowerCase()})
  }

  onRegisterPage = (n) => {
    this.setState({login: n, password: '', confirmPass: ''})
  }
  forgotPass = () => {
    //console.log("sdfsdfsdfsdfsd");
  }

  render() {
    //console.log(this.props);
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    return (
        <div className="wrapper" style={{backgroundImage: 'linear-gradient(to right,#ff5722, #ff9800)'}}>
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
            <Container>
                <Row>
                    <Col md={{ size: 10, offset: 1 }} style={{marginTop: '20vh'}}>
                        <Row>
                            <Col md="5" className="login_left">
                                <img src={logo} ></img>
    <h3>{language.welcome}</h3>
    <p>{language.descrierestillco}</p>
                                <a href="http://www.stillco.ro/#service">
                                  <button className="btn btn-primary">
                                      {language.getstarted}
                                  </button>
                                </a> 
                                <Row>
                                <Col md="4"></Col>
                                <Col md="2">
                                  <ReactFlagsSelect 
                                    countries={["GB", "RO", "FR"]}
                                    placeholder=""
                                    className="classInput2"
                                    defaultCountry={getFromStorage('language') ? getFromStorage('language').toUpperCase():"GB"}
                                    showSelectedLabel={false} 
                                    showOptionLabel={false}
                                    selectedSize={16} 
                                    optionsSize={14}
                                    style={{borderColor: "white"}}
                                    onSelect={this.selectLanguage}
                                  />
                                </Col>
                                <Col md="5"></Col>
                                </Row>
                            </Col>
                            <Col md="7" className="login_right">
                              
                              {
                                (this.state.login===0) ?
                              
                              (<><h2>{language.registerlogin}</h2> <div className="login-form">
                                <form>
                                  <FormGroup>
                                    <Input type="text" placeholder={language.email} name="username" onChange={this.handleChange} style={{color: "#000"}} invalid={this.state.showError}></Input>
                                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                    <Input type="password" placeholder={language.password} name="password" onChange={this.handleChange} style={{color: "#000"}} invalid={this.state.showError}></Input>
                                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                  </FormGroup>
                                  <div>
                                  {
                                    (!this.state.loading) ?
                                      (
                                        <button type="submit" className="btn btn-primary" onClick={this.onLogin}>{language.login}</button>
                                      ):(
                                        <ClipLoader
                                          size={43}
                                          color={"#F79434"}
                                          loading={this.state.loading}
                                        />
                                      )
                                  }
                                    {/* <div className="buttonText" style={{display: "inline", float: "center", marginTop: "15px", marginLeft: "15px"}} onClick={() => {this.forgotPass()}}>{language.forgot}</div> */}
                                    <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                            style={{display: "inline", float: "center", marginTop: "15px", marginLeft: "15px"}}
                                        >
                                            <ForgotPassword notify={this.notify}/>
                                    </ButtonGroup>
                                    <div className="buttonText" style={{display: "inline", float: "right", marginTop: "15px"}} onClick={() => {this.onRegisterPage(1)}}>{language.noaccount}</div>
                                  </div>
                                  </form>
                                </div></>):(<></>)}
                                {
                                  (this.state.login===1) ?
                                
                                (<><h2>{language.registerhere}</h2> 
                                <div className="login-form">
                                  <FormGroup>
                                    <Input type="text" placeholder={language.name} name="name" onChange={this.handleChange} style={{color: "#000"}} value={this.state.name} ></Input>
                                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                    <Input type="email" placeholder={language.email} name="email" onChange={this.handleChange} style={{color: "#000"}} invalid={this.state.componentName === "email" ? this.state.showError : ''} value={this.state.email}></Input>
                                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                    <Input type="text" placeholder={language.company} name="company" onChange={this.handleChange} style={{color: "#000"}} value={this.state.company}></Input>
                                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                    <Input type="password" placeholder={language.password} name="password" onChange={this.handleChange} style={{color: "#000"}} invalid={this.state.componentName === "password" ? this.state.showError : ''}></Input>
                                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                  </FormGroup>
                                  <FormGroup>
                                    <Input type="password" placeholder={language.confirmpassword} name="confirmPass" onChange={this.handleChange} style={{color: "#000"}} invalid={this.state.componentName === "password" ? this.state.showError : ''}></Input>
                                    <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                  </FormGroup>

                                  <FormGroup>
                                    <ReactFlagsSelect defaultCountry={this.state.country.toUpperCase()} onSelect={this.onChangeCountry} searchable={true}/>
                                  </FormGroup>
                                  <FormGroup>
                                    <PhoneInput
                                      value={this.state.phone}
                                      inputStyle={{color: "#000", width: "100%", borderColor: this.state.borderColor, backgroundColor: "transparent", boxShadow: 'none'}}
                                      containerStyle={{color: "#000"}}
                                      searchStyle={{color: "#000"}}
                                      onFocus={() => {this.onFocusPhone()}}
                                      onBlur={() => {this.onBlur()}}
                                      country={this.state.country}
                                      onChange={phone => this.setState({ phone })}
                                    />
                                    <div>
                                    {
                                    (!this.state.loading) ?
                                      (
                                        <button className="btn btn-primary" onClick={this.onRegister}>{language.register}</button>
                                      ):(
                                        <ClipLoader
                                          size={43}
                                          color={"#F79434"}
                                          loading={this.state.loading}
                                        />
                                      )
                                  }
                                  <div className="buttonText" style={{display: "inline", float: "right", marginTop: "15px"}} onClick={() => {this.onRegisterPage(0)}}>{language.back}</div>
                                  </div>
                                  </FormGroup>
                                </div></>):(<></>)}
                                {
                                  (this.state.login === 2) ? (
                                    <>
                                    <h2>{language.verificationcode}</h2> 
                                    <div className="login-form">
                                  {language.codenumber}{this.state.lastDigits}{language.access}
                                      <FormGroup>
                                        <Input type="number" placeholder="Code" name="code" onChange={this.handleChange} style={{color: "#000"}}></Input>
                                        <FormFeedback>{this.state.errorMessage}</FormFeedback>
                                      </FormGroup>
                                      <div>
                                      {
                                        (!this.state.loading) ?
                                          (
                                          <button className="btn btn-primary" onClick={this.onSendCode}>{language.send}</button>
                                          ):(
                                            <ClipLoader
                                              size={43}
                                              color={"#F79434"}
                                              loading={this.state.loading}
                                            />
                                          )
                                      }
                                      <div className="buttonText" style={{display: "inline", float: "right", marginTop: "15px"}} >{language.resend}</div>
                                    </div>
                                    </div>
                                    </>
                                  ):(<></>)
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
}

const styles = {
  exampleStyle: {
    backgroundColor: 'red',
  }
};

export default Login;
