import React , { Component } from 'react';
import Login from  './layouts/Login';
import AdminLayout from "layouts/Admin/Admin.js";
import { getFromStorage, setInStorage } from 'utils/storage';
import axios from "axios";

export default class Main extends Component {

    constructor(props){
        super(props);

        this.state = {
            token: getFromStorage("token"),
            language: getFromStorage("language") ? getFromStorage("language"):'gb'
        }

        this.updateToken = this.updateToken.bind(this);
        this.updateLanguage = this.updateLanguage.bind(this);
    }

    updateToken(token) {
        this.setState({token: token});
    } 
    updateLanguage(language) {
        setInStorage('language', language);
        this.setState({language: language});
    } 

    onLogOut = () => {
        axios.defaults.headers.common['Authorization'] = null;

        ////console.log(this.props);
         setInStorage("token", null);
         setInStorage("idAccount", null);
         setInStorage("customerName", null);
         setInStorage("customerAvatar", null);
         this.updateToken(null);
      }

    render() {
        //console.log(this.state.token)
        // let style1 = require('assets/scss/black-dashboard-react.scss'); 
        // let style2 = require('assets/demo/demo.css'); 
        // let style3 = require('assets/css/nucleo-icons.css'); 

        if(getFromStorage("language") === null){
            //console.log(getFromStorage("language"));
            setInStorage('language', 'gb')
        }
        if(this.state.token)
            return(<AdminLayout updateToken = {this.updateToken} onLogOut={this.onLogOut} updateLanguage = {this.updateLanguage} {...this.props}/>)
        else
            return(<Login updateToken = {this.updateToken}  updateLanguage = {this.updateLanguage} {...this.props}/>);
    }
}