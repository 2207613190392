/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import ClipLoader from "react-spinners/ClipLoader";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import AccountVerified from "./AccountVerified.js";

import { getFromStorage } from '../utils/storage';
import { apiLink, language } from '../utils/constants';
// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.js";
import axios from "axios";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      balance: 0,
      subscriptionsCount: 0,
      invoiceCount: 0,
      month: null,
      year: null,
      loadingGraph: false,
      invoices: [],
      currentCustomer: null,
      dataChart: null,
      lang: null,
    };
  }

  componentDidMount() {
    let date = new Date();

    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    this.setState({ lang: language1.language, selection: language1.language.invoiceData });

    this.setState({month: date.getMonth(), year: date.getFullYear()} );

    if (!!getFromStorage("tokenAdmin"))
      this.getCustomers()
        .then((res) => this.setState({ customers: res.customers } ))
        .catch((err) => this.setState({ customers: [] }));
    else this.setState({ currentCustomer: getFromStorage("idStripe") }, () => {
      this.initData().then(
        () => this.loadData()
      ).catch((err)=> console.error("Failed to initialize"));
    } );
}

initData = async () => {
  this.setState({loadingGraph: true});

  try{
    await fetch(apiLink + '/api/users/getBalance?idCustomer=' + this.state.currentCustomer, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Authorization': getFromStorage('token'),
          'Content-Type': 'application/json',
      }
    }).then((res) => {
        return res.json();   
    }).then((responseJson) => {
        this.setState({balance:responseJson.balance});
    }).catch((error) => {
        this.props.onLogOut();
    });
  }catch(err){

  }

  try{
    await fetch(apiLink + '/api/users/getSubscriptionsCount?idAccount=' + getFromStorage('idAccount'), {
              method: 'GET',
              headers: {
                  'Accept': 'application/json',
                  'Authorization': getFromStorage('token'),
                  'Content-Type': 'application/json',
              }
          }).then((res) => {
              return res.json();   
          } ).then((responseJson) => {
          this.setState({subscriptionsCount:responseJson.subscriptionsCount});
      })
      .catch((error) => {
        this.props.onLogOut();
      });
  }catch(err){
      //
  }

  try{
    await fetch(apiLink + '/api/users/getInvoicesCount?idCustomer=' + this.state.currentCustomer, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': getFromStorage('token'),
            'Content-Type': 'application/json',
      }
    }).then((res) => {
              return res.json();   
    }).then((responseJson) => {
      let invoices = responseJson.invoiceCount.filter((element) => {
        return element.status!="draft";
      });
            
      this.setState({invoices: invoices});
    }).catch((error) => {
      this.props.onLogOut();
    });
  }catch(err){
      //
  }

  this.setState({loadingGraph: false});
}

loadData = () => {
  this.setState({loadingGraph: true});

  try{
    fetch(apiLink + '/api/users/getCharges', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': getFromStorage('token'),
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              idCustomer: this.state.currentCustomer,
              chargeDate: new Date(this.state.year, this.state.month, 1),
          })
      }).then((res) => {
        //console.log(res);
          return res.json();   
      }).then((responseJson) => {
        if(responseJson.status === 200){
            //console.log(obj.data)
            this.generateData(responseJson.data);
        }
        this.setState({loadingGraph: false});
      }).catch((error) => {
        this.setState({loadingGraph: false});
        this.props.onLogOut();
      });
  }catch(err){
    this.setState({loadingGraph: false});
  }
}

selectMonth = (evt) => {
  this.setState({ month: evt.target.value }, () => this.loadData());
};

selectDataset = (evt) => {
  this.setState({ selection: evt.target.value }, () => this.setDataset());
};

selectYear = (evt) => {
  this.setState({ year: evt.target.value }, () => this.loadData());
};

selectCustomer = (evt) => {
  this.setState({
    currentCustomer: evt.target.value,
    remaining: 0,
    succesful: 0,
    total: 0,
    consumptionDate: null,
  }, () => {
    this.initData().then(() => this.loadData());
  } );
};

getCustomers() {
  return axios({
    method: "get",
    url: `${apiLink}/api/admin/getCustomers`,
    params: {
      page: 0,
    },
    headers: {
      Accept: "application/json",
      Authorization: getFromStorage("tokenAdmin"),
      "Content-Type": "application/json",
    },
    timeout: 3000,
  }).then((res) => res.data);
}

generateData(data1) {
  var date = new Date(this.state.year, this.state.month, 1);
  var firstDay = new Date(date.getFullYear(), date.getMonth() , 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  let minTime = Math.floor(firstDay / 1000);
  let maxTime = Math.floor(lastDay / 1000);
  let instances = this.state.invoices.filter( instance => instance.created >= minTime && instance.created <= maxTime );
  this.setState({ invoiceCount: instances.length });

  let diffTime = Math.abs(firstDay - lastDay);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  let labels = [];

  let language = this.state.lang;

  let datasets = {
    [language.invoiceData]: [],
    [language.paymentData]: [],
    [language.refundData]: [],
  };

  console.log(firstDay + " " + lastDay);
  for(let i = 0;i<diffDays;i++){
    let payments = data1.filter(function(item){ let asd = new Date(item.created*1000);return asd.getDate() -1 ===i})
    let red = {amount: 0, refunded: 0, credited: 0};

    if(payments.length > 0) {
      red = payments.reduce((a, b) => {
        let payment = {...a};
        
        if(!b.paid)
          return payment;

        payment.amount += b.amount ?? 0;
        if(b.refunds.total_count > 0)
          payment.refund += b.amount_refunded ?? 0;

        let invoice = instances.find( inv => inv.id === b.metadata.invoice );
        if(!!invoice){
          payment.credited += invoice.post_payment_credit_notes_amount ?? 0;
          payment.credited += invoice.pre_payment_credit_notes_amount ?? 0;
        }

        return payment; // returns object with property x
      }, {amount: 0, refunded: 0, credited: 0});
    }

    const invoiced = red.amount / 100;
    const paid = (red.amount - red.refunded - red.credited) / 100;
    const credited = red.credited / 100;

    datasets[language.invoiceData].push(invoiced);
    datasets[language.paymentData].push(paid);
    datasets[language.refundData].push(credited);

    labels.push((i+1).toString());
  }

  let data = canvas => {  
    const settings = {
      fill: true,
    };

    const colors = {
      [language.invoiceData]: {
        backgroundColor: "rgb(247, 148, 52, 0.5)",
        borderColor: "rgb(247, 148, 52)",
      },
      [language.paymentData]: {
        backgroundColor: "rgb(0, 152, 240, 0.5)",
        borderColor: "rgb(0, 152, 240)",
      },
      [language.refundData]: {
        backgroundColor: "rgb(237, 211, 198, 0.5)",
        borderColor: "rgb(237, 211, 198)",
      },
    };

    return {
      labels: labels,
      datasets: [language.invoiceData, language.paymentData].map( ds => {
        return {
          label: ds,
          data: datasets[ ds ],
          // backgroundColor: gradientStroke,
          ...colors[ ds ],
          ...settings,
        }
      }),
    };
  }

  this.setState({dataChart: data});
}

  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
  };

  render() {

    const language = this.state.lang;
    let canLoad = !this.state.loadingGraph && !!this.state.month && !!this.state.year && !!this.state.currentCustomer;

    if(!language)
      return (<></>);

    return (
      <>
        <div className="content">
          <AccountVerified notify={this.props.notify} />

          <Row>
            <Col md="4" xs="12">
              <Card className="card-chart" color="primary">
                <CardHeader>
                  <CardTitle tag="h3">
                    <b>{language.totalabonamente}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <b>
                        <h1>{this.state.subscriptionsCount}</h1>
                      </b>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="12">
              <Card className="card-chart" color="warning">
                <CardHeader>
                  <CardTitle tag="h3">
                    <b>{language.totalfacturi}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <b>
                        <h1>{this.state.invoiceCount}</h1>
                      </b>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="12">
              <Card className="card-chart" color="info">
                <CardHeader>
                  <CardTitle tag="h3">
                    <b>{language.smsramase}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <b>
                        <h1>{this.state.balance}</h1>
                      </b>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="8">
                      <h5 className="card-category">
                        {language.totalshipment}
                      </h5>
                      <CardTitle tag="h2">
                        {language.paymentthismounth}
                      </CardTitle>
                    </Col>
                    <Col sm="4">
                      {!!getFromStorage("tokenAdmin") && (
                        <Input
                          type="select"
                          onChange={this.selectCustomer}
                          placeholder={language.selectCustomerText}
                          style={{ textTransform: "capitalize", width: "100%" }}
                        >
                          <option value={null} key="clearable">
                            {language.selectCustomerClear}
                          </option>
                          {this.state.customers?.map((customer, index) => (
                            <option
                              value={customer.id}
                              style={{ textTransform: "capitalize" }}
                              key={`option_customer_${index}`}
                            >
                              {customer.name}
                            </option>
                          ))}
                        </Input>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ alignItems: "center" }}>
                    <Col xs="6" md="3">
                      <Input
                        type="select"
                        style={{ textTransform: "capitalize", width: "100%" }}
                        onChange={this.selectMonth}
                        value={this.state.month}
                      >
                        {language.months.map((month, index) => (
                          <option
                            value={index}
                            style={{ textTransform: "capitalize" }}
                            key={`option_month_${index}`}
                          >
                            {month}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col xs="6" md="3">
                      <Input
                        type="select"
                        style={{ textTransform: "capitalize", width: "100%" }}
                        onChange={this.selectYear}
                        value={this.state.year}
                      >
                        {[2020, 2021, 2022, 2023, 2024].map((year, index) => (
                          <option value={year} key={`option_year_${index}`}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    {/* <Col xs="12" md="4">
                      <Button
                        tag="label"
                        className={classNames("btn-simple")}
                        style={{ width: "100%" }}
                        color="primary"
                        size="sm"
                        disabled={!canLoad}
                        active={canLoad}
                        onClick={() => this.loadData()}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ margin: "auto 5px" }}>
                            {language.dashboardLoadButton}
                          </span>
                          <ClipLoader
                            size={10}
                            color={"#F79434"}
                            loading={this.state.loadingGraph}
                          />
                        </span>
                      </Button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    {(!!this.state.dataChart && canLoad) ? (
                      <Line
                        data={this.state.dataChart}
                        options={{
                          ...chartExample1.options,
                          tooltips: {
                            intersect: false,
                            mode: "index",
                            axis: "x",
                          },
                          maintainAspectRatio: false,
                        }}
                      />
                    ) : (
                      <ClipLoader
                      size={35}
                      color={"#F79434"}
                      loading={this.state.loadingGraph}
                    />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
