import React from "react";
import { Card, CardHeader, CardBody, Row, Col,Button, CardFooter, Modal, Input } from "reactstrap";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';

import ClipLoader from "react-spinners/ClipLoader";

class ChangePassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalPass: false,
            idAccount: getFromStorage('idAccount'),
            loading: false,
            pass: "",
            passConfirm: ""
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value})
      }

    toggleModalPass = () => {
        this.setState({
          modalPass: !this.state.modalPass
        });
    };

    getTranslate = (value) =>{
      let language1 = null
      if(getFromStorage('language') !== null){
        language1 = require(`../utils/language_`+getFromStorage('language')); 
      }else{
        language1 = require(`../utils/language_gb`); 
      }
      let language = language1.language;
      return language[value];
    }
    
    componentDidMount(){
    }

    onSavePass(){
      //console.log(this.props);
      if(this.state.pass === this.state.passConfirm && this.state.pass.trim() !==""){
          try{
              fetch(apiLink + '/api/users/savePassord', {
                  method: 'POST',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': getFromStorage("token")
                  },
                  body: JSON.stringify(this.state)
                }).then((res) => {
                  console.log(res)
                  if(res.status == 200){
                    this.props.notify("tc", this.getTranslate("successPasswordUpdate"));
                    this.toggleModalPass();
                    return res.text(); 
                  } else {
                    this.props.notify("tc", this.getTranslate("errorResponce"));
                  }  
                }).catch((error) => {
                      this.props.notify("tc", this.getTranslate("errorResponce"));
                      this.setState({loading: true});
                  });
            } catch(err){
              this.props.notify("tc", this.getTranslate("errorResponce"));
              this.setState({loading: true});
            }
          }else{
            this.props.notify("tc", this.getTranslate("errorResponce"));
          }
        }

  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    return (
        <>

                <Col>
                  <Button color="info" type="submit" onClick={this.toggleModalPass}>{language.changepassword}</Button>
                </Col>
                

                <Modal
                    modalClassName="modal-pass"
                    isOpen={this.state.modalPass}
                    toggle={this.toggleModalPass}
                    
                    >
                    <div className="modal-header">
                          <h3 style={{color: "#000"}}>{language.changepassword}</h3>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalPass}
                        >
                        <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body" style={{color:"#000 !important"}}>
                        <Row>
                            <Col>
                              <label>{language.newpassword}</label>
                            <Input
                                name="pass"
                                type="password"
                                style={{color: "black"}}
                                onChange={this.handleChange}
                                invalid={this.state.fieldError}
                            />
                            </Col>
                            <Col>
                              <label>{language.confirmpassword}</label>
                            <Input
                                name="passConfirm"
                                type="password"
                                style={{color: "black"}}
                                onChange={this.handleChange}
                                invalid={this.state.fieldError}
                            />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer" style={{color:"#000 !important"}}>
                      { !this.state.loading ? 
                              <Row><Col><Button onClick={() => this.onSavePass()}>{language.save}</Button></Col></Row> : 
                              <Row><Col><ClipLoader size={43} color={"#F79434"} loading={this.state.loading}/></Col></Row>
                      }
                    </div>
                </Modal>
            
        </>
    );
  
  }
}

export default ChangePassword;