import React, { Fragment } from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import CreditCardInput from 'react-credit-card-input';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import DatePicker from "react-datepicker";

import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  FormText,
  Label,
  Col,
  Modal
} from "reactstrap";

import Layout from '../utils/Layout';
import CheckoutOpenInvoices from "utils/CheckoutOpenInvoices";
import "react-datepicker/dist/react-datepicker.css";

class ListInvoices extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      invoices: [],
      modalPay: false,
      idInvoice: '',
      total: 0,
      taxable: false,
      cupon: null,
      couponCode: '',
      percent_off: 0,
      modalCupon: false,
      invoice : {
          number: '',
      },
      startDate: new Date(),
      fromDate: null,
      toDate: null,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleChangeDate2 = this.handleChangeDate2.bind(this);

  }

    toggleModalPay = (id) => {
        this.setState({
        modalPay: !this.state.modalPay,
        idInvoice: id
        });
    };
    togglemodalCupon = () => {
        this.setState({
        modalCupon: !this.state.modalCupon
        });
    };

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value, fieldError: false })
    }

    handleChangeDate = date => {
        this.setState({
          fromDate: date
        });
    };

    handleChangeDate2 = date => {
        this.setState({
          toDate: date
        });
    };

  componentDidMount() {
      
    try{
        const fromDate = new Date(new Date().getFullYear(), 0, 1);;
        const toDate = new Date();
        this.setState({fromDate: fromDate, toDate: toDate}, () => this.getInvoices() );

        fetch(apiLink + '/api/users/gettaxable', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        idCustomer: getFromStorage('idStripe'),
                    })
                }).then((res) => {
                  return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                this.state.taxable = obj.taxable;
                if(this.state.taxable){
                    console.log(this.state.total);
                    this.state.total = this.state.total + ((this.state.total)*20)/100;
                }
                console.log(obj.taxable);
            })
        .catch((error) => {
            this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }

  getInvoices(){
    // this.state.stringDateFrom = `01-${this.state.startDate.getMonth()+1}-${this.state.startDate.getFullYear()}`
    //   this.state.stringDateTo = `${this.state.startDate.getDate()}-${this.state.startDate.getMonth()+1}-${this.state.startDate.getFullYear()}`
    this.setState({loading: true});

    try{
        if(getFromStorage("tokenAdmin") != null){
            fetch(apiLink + '/api/users/getInvoicesAdmin', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': getFromStorage('tokenAdmin'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  fromDate: this.state.fromDate,
                  toDate: this.state.toDate
                })
            }).then((res) => {
                return res.text();   
            }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                //console.log(obj.data.data)
                    console.log(obj.creditNotes)
                    this.setState({invoices: obj.invoices.data, creditNotes: obj.creditNotes.data, loading: false});
            })
            .catch((error) => {
                this.setState({loading: false});  
                this.props.onLogOut();
            });
        }
        else{
            fetch(apiLink + '/api/users/getInvoices?idCustomer=' + getFromStorage('idStripe'), {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('token'),
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          fromDate: this.state.fromDate,
                          toDate: this.state.toDate
                        })
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    console.log(obj.creditNotes)
                    this.setState({invoices: obj.invoices.data, creditNotes: obj.creditNotes.data, loading: false});
                })
            .catch((error) => {
                this.props.onLogOut();
            });
        }
    }catch(err){
        //
    }

  }

  getInvoice(id){
    try{
        fetch(apiLink + '/api/users/getInvoice?idInvoice=' + id, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                console.log(obj.data);
                this.setState({invoice: obj.data});
                    fetch(apiLink + '/api/users/getCustomerInfo?idCustomer=' + obj.data.customer, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('token'),
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    this.setState({customerInfo: obj.data});
                    console.log(this.state);

                    for(let i=0;i<this.state.invoiceLines.length;i++){
                        this.state.total +=this.state.invoiceLines[i].amount/100;
                    }

                    this.toggleModalPay(id);
                }).catch((error) => {
                    this.props.onLogOut();
        });
            })
        .catch((error) => {
            this.props.onLogOut();
        });
    }catch(err){
        //
    }
    try{
        fetch(apiLink + '/api/users/getInvoiceItem?idInvoice=' + id, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                //console.log(obj.data.data);
                this.setState({invoiceLines: obj.data.data});
            })
        .catch((error) => {
            this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }

  checkCoupon = () => {
    try{
      fetch(apiLink + '/api/users/checkCoupon', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      cop: this.state.couponCode
                    })
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                console.log(obj);
                if(obj.status === 200){
                    if(this.state.percent_off > 0){
                        console.log(this.state.total);
                        this.state.total = this.state.total - ((this.state.total)*this.state.percent_off)/100;
                    }
                    this.setState({percent_off: obj.coupon, modalCupon: !this.state.modalCupon, blockCoupon: true})
                }
            })
        .catch((error) => {
            this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }

  convertDate = (unix_timestamp) =>{
        let date = new Date(unix_timestamp * 1000);
        // Hours part from the timestamp
        let day = date.getDate();
        // Minutes part from the timestamp
        let month = date.getMonth()+1;
        // Seconds part from the timestamp
        let year = date.getFullYear();

        // Will display time in 10:30:23 format
        // let formattedTime = day+ "/"+month+"/"+year;
        return date.toLocaleDateString('fr');
  }

//   refund(value) {
//     try{
//         fetch(apiLink + '/api/users/refund', {
//             method: 'POST',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json',
//                 'Authorization': getFromStorage('token'),
//             },
//             body: JSON.stringify({
//                 payment_intent: value,
//                 customer: getFromStorage('idStripe'),
//             })
//         }).then((res) => {
//             return res.text();   
//         }).then((responseJson) => {
//             var obj = JSON.parse(responseJson);
//             //console.log(obj);
//         })
//         .catch((error) => {
//             //
//         });
//     }catch(err){
//         //
//     }
// }

  render() {
      console.log(this.state.startDate);
      console.log(this.state.fromDate);
      console.log(this.state.toDate);
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    //console.log(this.state);

    let products = [];
    //console.log(this.state.taxable);

    if(this.state.modalPay){
        products=this.state.invoiceLines.map(function(item, i) {
            return(
                <tr key={i}>
                    <td>{item.description}</td>
                    {/* <td>{this.convertDate(item.period.start)}</td>
                    <td>{this.convertDate(item.period.end)}</td> */}
                    <td>{item.quantity}</td>
                    <td>{item.amount/100} €</td>
                </tr>
            )
        }.bind(this))
    }

    let template = [];
     if(this.state.invoices && this.state.invoices.length > 0){
         template = this.state.invoices.map(function(item, i){
             let credit = this.state.creditNotes.filter(obj => obj.invoice === item.id);
            
            // if(credit.length > 0)
            //     item.status = "credited";

             //  console.log(credit);
             let credit_template = credit.map((item, index) => {
                 return (
                    <tr key={`credit_${i}_${index}`}>
                        <td></td>
                        <td>{item.number}</td>
                        <td>-{item.amount/100}</td>
                        <td>
                            {item.currency.toUpperCase()}
                        </td>
                        <td>{item.status}</td>
                        <td>
                            {this.convertDate(item.created)}
                        </td>
                        <td></td>
                        {!!getFromStorage("tokenAdmin") && (<td></td>)}
                        <td>
                        <a href={`./creditnote?idCredit=` + item.id}><Button className="btn-sm" color="info" type="submit">
                            {language.viewcreditnote}
                            </Button></a>
                        </td>
                    </tr>)
             })
            if(item.status!="draft")
                return(
                    <Fragment key={`invoice_${i}_rows`}>
                    <tr>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.number}
                        </td>
                        <td></td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.total/100}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.currency.toUpperCase()}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.status}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {this.convertDate(item.created)}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {this.convertDate(item.period_end)}
                        </td>
                        {!!getFromStorage("tokenAdmin") && (<td>{item.customer_name}</td>)}
                        <td>
                        <a href={`./invoice?idInvoice=` + item.id}><Button className="btn-sm" color="info" type="submit">
                            {language.viewinvoice}
                            </Button>
                        </a>
                        <a href={`./support?idInvoice=` + item.id}><Button className="btn-sm" color="warning" type="submit" style={{marginLeft: '20px'}}>
                            {language.suport}
                        </Button></a> {item.status == "open" ? <Button className="btn-sm" color="primary" type="submit" data-target="#payModal" data-toggle="modal" style={{marginLeft: '20px'}} onClick={() => this.getInvoice(item.id)}>
                            {language.payinvoice}
                        </Button> : <></>}
                        </td>
                    </tr>
                    { (credit.length > 0) ? (<>{credit_template}</>):(<></>) }
                    </Fragment>
                )
                else
                return(<></>)
         }.bind(this))
    }
    return (
      <>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h3 className="title">{language.invoices}</h3>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col>
                    <Label>{language.createdbefore}</Label>
                  </Col>
                  <Col>
                    <Label>{language.createdafter}</Label>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <DatePicker
                      selected={this.state.fromDate}
                      // value={this.state.stringDateFrom}
                      onChange={this.handleChangeDate}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                  <Col>
                    <DatePicker
                      selected={this.state.toDate}
                      // value={this.state.stringDateTo}
                      onChange={this.handleChangeDate2}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                  <Col>
                    <Button
                      className="btn-sm"
                      color="success"
                      type="submit"
                      onClick={() => this.getInvoices()}
                      disabled={this.state.loading}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ margin: "auto 5px" }}>
                          {language.listinvoices}
                        </span>
                        <ClipLoader
                          size={10}
                          color={"#F79434"}
                          loading={this.state.loading}
                        />
                      </span>
                    </Button>
                  </Col>
                </Row>
                <br></br>
                {!this.state.loading ? (
                  <Row>
                    <Col>
                      <Table className="tablesorter">
                        <thead className="text-primary">
                          <tr>
                            <th>{language.invoicenumber}</th>
                            <th>{language.voidnumber}</th>
                            <th>{language.amount}</th>
                            <th>{language.currency}</th>
                            <th>{language.status}</th>
                            <th>{language.createdat}</th>
                            <th>{language.dueto}</th>
                            {!!getFromStorage("tokenAdmin") && (
                              <th>{language.customername}</th>
                            )}
                            <th>{language.actions}</th>
                          </tr>
                        </thead>
                        <tbody>{template}</tbody>
                      </Table>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <ClipLoader
                        size={43}
                        color={"#F79434"}
                        loading={this.state.loading}
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal
          modalClassName="modal-cart"
          isOpen={this.state.modalPay}
          toggle={this.toggleModalPay}
        >
          <div className="modal-header">
            <h3 style={{ color: "#000" }}>
              {language.factura + this.state.invoice.number}{" "}
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalPay}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>

          <div className="modal-body">
            <Row>
              <Col>
                <Card>
                  <CardHeader></CardHeader>
                  <CardBody>
                    <Form>
                      <Table className="tablesorter">
                        <thead className="text-primary">
                          <tr>
                            <th>{language.product}</th>
                            {/* <th>Start date</th>
                                            <th>End date</th> */}
                            <th>{language.qty}</th>
                            <th>{language.amount}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products}
                          {this.state.taxable ? (
                            <tr>
                              <td></td>
                              <td>VAT – France</td>
                              <td>20%</td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {this.state.percent_off > 0 ? (
                            <tr>
                              <td></td>
                              <td>Discount</td>
                              <td>{this.state.percent_off}%</td>
                            </tr>
                          ) : (
                            <></>
                          )}

                          <tr>
                            <td>
                              {!this.state.blockCoupon ? (
                                <Button
                                  className="btn-sm"
                                  color="primary"
                                  onClick={this.togglemodalCupon}
                                >
                                  {language.cupon}
                                </Button>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>{language.totalbalance}</td>
                            <td>
                              {this.state.taxable
                                ? this.state.total +
                                  (this.state.total * 20) / 100
                                : this.state.total}{" "}
                              €
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Layout>
                  <CheckoutOpenInvoices
                    idInvoice={this.state.idInvoice}
                    notify={this.props.notify}
                  ></CheckoutOpenInvoices>
                </Layout>
              </Col>
            </Row>
          </div>

          <div></div>
        </Modal>
        <Modal
          modalClassName="modal-pass"
          isOpen={this.state.modalCupon}
          toggle={this.togglemodalCupon}
        >
          <div className="modal-header">
            <h3 style={{ color: "#000" }}>{language.cupon}</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.togglemodalCupon}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <div className="modal-body" style={{ color: "#000 !important" }}>
            <Row>
              <Col>
                <label>{language.cod}</label>
                <Input
                  name="couponCode"
                  style={{ color: "black" }}
                  onChange={this.handleChange}
                  invalid={this.state.fieldError}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{ color: "#000 !important" }}>
            <Button onClick={this.checkCoupon}>{language.adauga}</Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ListInvoices;
