import React, {Component} from 'react';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Row,
    Label,
    Col
  } from "reactstrap";
  import { getFromStorage } from '../utils/storage';
  import { apiLink } from '../utils/constants';

class SupportSubmit extends Component {
    constructor(props){
        super(props);
        this.state = {
            idCustomer: getFromStorage('idStripe'),
            idInvoice: null,
            reason: 'Refund',
            text: '',
            company: '',
            name: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    
    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value})
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let idInvoice = params.get('idInvoice');
        this.setState({idInvoice})
        try{
            fetch(apiLink + '/api/users/getCustomerInfo?idCustomer=' + getFromStorage('idStripe'), {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('token'),
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    this.setState({name: obj.data.name, company:obj.data.description});
                })
            .catch((error) => {
                this.props.onLogOut();
            });
        }catch(err){
            this.setState({loading: true})
        }
    }

    getTranslate = (value) =>{ 
        let language1 = null
     if(getFromStorage('language') !== null){
       language1 = require(`../utils/language_`+getFromStorage('language')); 
     }else{
       language1 = require(`../utils/language_gb`); 
     }
     let language = language1.language;
     return language[value];
   }

    submit() {
        fetch(apiLink + '/api/users/refund', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getFromStorage('token'),
            },
            body: JSON.stringify(this.state)
        }).then((res) => {
            return res.text();   
        }).then((responseJson) => {
            var obj = JSON.parse(responseJson);
            console.log(obj);
            if(obj.status === 200){
                this.props.notify("tc", this.getTranslate("cerereInregsitrata"));
                const timer = setTimeout(() => {
                    window.location.href = "/";
                }, 2500);
            }else{
                this.props.notify("tc", this.getTranslate("erroroccurred"));
            }
        }).catch((error) => {
            this.props.onLogOut();
        });
    }

    render() {
        let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
        return(
            <>
                <div className="content">
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
        <h5 className="title">{language.suport}</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <FormGroup>
        <Label for="exampleSelect">{language.motiv}</Label>
                                                <Input type="select" name="reason" id="exampleSelect" onChange={this.handleChange}>
        <option value = {language.refund} >{language.refund}</option>
        <option value = {language.cerereSuport} >{language.cerereSuport}</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
        <label>{language.descriere}</label>
                                            <Input
                                                cols="100"
                                                placeholder={language.textDescriere}
                                                rows="4"
                                                type="textarea"
                                                name="text"
                                                onChange={this.handleChange}
                                            />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
        <Button color="primary" onClick={this.submit}>{language.send}</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default SupportSubmit;