import React from "react";
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import ClipLoader from "react-spinners/ClipLoader";

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import AccountVerified from "./AccountVerified.js";

import { getFromStorage } from "../utils/storage";
import { apiLink, smsLink, language } from "../utils/constants";
import axios from "axios";
import { chartExample1 } from "variables/charts.js";

class SmsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      succesful: 0,
      total: 0,
      remaining: 0,
      data: null,
      lang: null,
      minDate: new Date().setDate(1),
      maxDate: Date.now(),
      fromDate: null,
      toDate: null,
      dayLabels: [],
      rawData: null,
      dailyData: null,
      dayDetails: null,
      loadingGraph: false,
      loadingDetails: false,
      consumptionDate: null,
      consumptionRate: null,
      customers: null,
      currentCustomer: null,
      month: null,
      year: null,
      day: null,
    };
  }

  getHistory() {
    return axios({
      method: "post",
      url: `${apiLink}/api/users/getHistory`,
      params: {
        client: this.state.currentCustomer,
        dateFrom: this.state.fromDate?.toISOString().split("T")[0],
        dateTo: this.state.toDate?.toISOString().split("T")[0],
      },
      headers: {
        Authorization: getFromStorage("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      timeout: 30000,
    }).then((res) => res.data.data);
    // .catch((err) => this.props.onLogOut());
  }

  getBalance() {
    return axios({
      method: "get",
      url: `${apiLink}/api/users/getBalance`,
      params: {
        idCustomer: this.state.currentCustomer,
      },
      headers: {
        Accept: "application/json",
        Authorization: getFromStorage("token"),
        "Content-Type": "application/json",
      },
      timeout: 3000,
    }).then((res) => res.data);
    // .catch((err) => this.props.onLogOut());
  }

  getCustomers() {
    return axios({
      method: "get",
      url: `${apiLink}/api/admin/getCustomers`,
      params: {
        page: 0,
      },
      headers: {
        Accept: "application/json",
        Authorization: getFromStorage("tokenAdmin"),
        "Content-Type": "application/json",
      },
      timeout: 3000,
    }).then((res) => res.data);
  }

  componentDidMount() {
    let language1 = null;
    if (getFromStorage("language") !== null) {
      language1 = require(`../utils/language_` + getFromStorage("language"));
    } else {
      language1 = require(`../utils/language_gb`);
    }
    this.setState({ lang: language1.language });

    let date = new Date();
    this.setState({ month: date.getMonth(), year: date.getFullYear() });

    if (!!getFromStorage("tokenAdmin"))
      this.getCustomers()
        .then((res) => this.setState({ customers: res.customers }))
        .catch((err) => this.setState({ customers: [] }));
    else this.setState({ currentCustomer: getFromStorage("idStripe")}, () => this.generateInterval());
  }

  parseData(data) {
    let from = this.state.fromDate;
    let to = this.state.toDate;
    let language = this.state.lang;

    let span = Math.ceil((to - from) / (24 * 60 * 60 * 1000)) + 1;
    let start = from.getDate();

    let labels = new Array(span).fill(null).map((_, index) => {
      let day = new Date(from);
      day.setDate(start + index);
      return day.toLocaleDateString(language.locale);
    });

    let validData = data.filter((item) => {
      let datetime = new Date(item.dateSubmitLocal);
      let date = datetime.toLocaleDateString(language.locale);

      if (!labels.includes(date)) return false;

      return true;
    });

    let totalRows = validData.length;
    let sent = validData.filter((row) => !!row.dateSubmitLocal);
    let sentRows = sent.filter((row) => row.status === "DELIVRD").length;

    let datasets = [];

    let splitRows = {};
    labels.forEach(
      (day) =>
        (splitRows[day] = {
          [language.deliveredCategory]: {
            count: 0,
            messages: [],
          },
          [language.expiredCategory]: {
            count: 0,
            messages: [],
          },
          [language.rejectedCategory]: {
            count: 0,
            messages: [],
          },
          [language.otherCategory]: {
            count: 0,
            messages: [],
          },
          [language.confirmedCategory]: {
            count: 0,
            messages: [],
          },
          [language.unconfirmedCategory]: {
            count: 0,
            messages: [],
          },
        })
    );

    sent.forEach((row) => {
      let datetime = new Date(row.dateSubmitLocal);
      let date = datetime.toLocaleDateString(language.locale);

      let category = "";
      switch (row.status) {
        case "DELIVRD":
          category = language.deliveredCategory;
          break;
        case "EXPIRED":
          category = language.expiredCategory;
          break;
        case "REJECTD":
          category = language.rejectedCategory;
          break;
        default:
          category = language.otherCategory;
      }

      splitRows[date][category].count++;
      splitRows[date][category].messages.push(row);
      if (category === language.deliveredCategory) {
        splitRows[date][language.confirmedCategory].count++;
      } else {
        splitRows[date][language.unconfirmedCategory].count++;
      }
    });

    let colors = {
      [language.deliveredCategory]: {
        backgroundColor: "rgb(194, 249, 187, 0.5)",
        borderColor: "rgb(194, 249, 187)"
      },
      [language.expiredCategory]: {
        backgroundColor: "rgb(176, 218, 241, 0.5)",
        borderColor: "rgb(176, 218, 241)",
      },
      [language.rejectedCategory]: {
        backgroundColor: "rgb(163, 0, 21, 0.5)",
        borderColor: "rgb(163, 0, 21)",
      },
      [language.otherCategory]: {
        backgroundColor: "rgb(237, 211, 198, 0.5)",
        borderColor: "rgb(237, 211, 198)",
      },
      [language.confirmedCategory]: {
        backgroundColor: "rgb(0, 152, 240, 0.5)",
        borderColor: "rgb(0, 152, 240)",
      },
      [language.unconfirmedCategory]: {
        backgroundColor: "rgb(255, 141, 114, 0.5)",
        borderColor: "rgb(255, 141, 114)",
      },
      [language.totalCategory]: {
        backgroundColor: "rgb(247, 148, 52, 0.5)",
        borderColor: "rgb(247, 148, 52)",
      },
    };
    [
      language.deliveredCategory,
      language.expiredCategory,
      language.rejectedCategory,
      language.otherCategory,
    ].forEach((category) => {
      let dataset = {
        label: category,
        data: Object.values(splitRows).map((row) => row[category].count),
        stack: "Sent",
        ...colors[category],
        hidden: true,
        fill: false,
      };
      datasets.push(dataset);
    });

    dataset = {
      label: language.totalCategory,
      data: Object.values(splitRows).map(
        (row) => row[language.unconfirmedCategory].count + row[language.confirmedCategory].count
      ),
      stack: "Total",
      fill: false,
      ...colors[language.totalCategory],
    };
    datasets.push(dataset);

    let dataset = {
      label: language.confirmedCategory,
      data: Object.values(splitRows).map(
        (row) => row[language.confirmedCategory].count
      ),
      stack: "Total",
      fill: false,
      ...colors[language.confirmedCategory],
    };
    datasets.push(dataset);

    // dataset = {
    //   label: language.unconfirmedCategory,
    //   data: Object.values(splitRows).map(
    //     (row) => row[language.unconfirmedCategory].count
    //   ),
    //   stack: "Total",
    //   fill: false,
    //   ...colors[language.unconfirmedCategory],
    // };
    // datasets.push(dataset);

    let dailyAverage = Math.ceil(totalRows / labels.length);
    let estimatedDate = null;
    if (dailyAverage > 0) {
      let daysLeft = Math.floor(this.state.remaining / dailyAverage);
      estimatedDate = new Date( 1*Date.now() + daysLeft * 24 * 60 * 60 * 1000 );
    }

    this.setState({
      total: totalRows,
      succesful: sentRows,
      data: {
        labels: labels.map( (item, index) => index + 1 ),
        datasets: datasets,
      },
      dayLabels: labels,
      rawData: splitRows,
      consumptionRate: dailyAverage > 0 ? dailyAverage : null,
      consumptionDate: !!estimatedDate
        ? estimatedDate.toLocaleDateString(language.locale)
        : null,
    });
  }

  selectDataDay = (evt) => {
    this.setState({ day: !!evt.target.value ? evt.target.value : null }, () => this.loadDataDay() );
  }

  loadDataDay = () => {
    this.setState({ loadingDetails: true });

    let day = this.state.day;

    if (!day) {
      this.setState({
        dailyData: null,
        dayDetails: null,
        loadingDetails: false,
      });
      return;
    }

    let data = this.state.rawData[day];

    let statuses = Object.keys(data);
    let formattedData = statuses
      .map((status) =>
        data[status].messages.map((message) => {
          let formattedMessage = {};

          formattedMessage.status = status;
          formattedMessage.phone = message.destinatar;
          formattedMessage.submit = new Date(
            message.dateSubmitLocal
          );
          formattedMessage.confirm = new Date(
            message.dateDoneLocal
          );

          return formattedMessage;
        })
      )
      .flat();

    this.setState({
      dailyData: formattedData.sort( (a,b) => a.submit < b.submit ? -1 : 1 ),
      dayDetails: day,
      loadingDetails: false,
    });
  }

  selectCustomer = (evt) => {
    this.setState({
      currentCustomer: evt.target.value,
      remaining: 0,
      succesful: 0,
      total: 0,
      consumptionDate: null,
    }, () => this.generateInterval() );
  };

  selectMonth = (evt) => {
    this.setState({ month: evt.target.value }, () => this.generateInterval());
  };

  selectYear = (evt) => {
    this.setState({ year: evt.target.value }, () => this.generateInterval());
  };

  loadData = () => {
    this.setState({ loadingGraph: true });
    this.getHistory()
      .then((res) => {
        this.parseData(res);
        this.loadDataDay();
        this.setState({ loadingGraph: false });
      })
      .catch((err) => {
        this.setState({ loadingGraph: false });
      });
    this.getBalance()
      .then((res) => this.setState({ remaining: res.balance }))
      .catch((err) => this.setState({ remaining: 0 }));
  };

  generateInterval = () => {
    let month = this.state.month;
    let year = this.state.year;

    let fromDate = new Date(year, 1 * month, 1);
    let toDate = new Date(year, 1 * month + 1, 1);
    toDate.setDate(0);

    let isCurrent = fromDate <= Date.now() && toDate >= Date.now();
    let loadedDay = null;
    if(isCurrent)
      loadedDay = new Date().toLocaleDateString( this.state.lang.locale );

    this.setState({ fromDate: fromDate, toDate: toDate, day: loadedDay }, () =>
      this.loadData()
    );
  };

  detailTable = () => {
    let language = this.state.lang;

    return (
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>{language.messageStatusHeader}</th>
            <th>{language.messageNumberHeader}</th>
            <th>{language.messageSubmitHeader}</th>
            <th>{language.messageConfirmHeader}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dailyData.map((row, index) => (
            <tr key={`row_${row.status}_${index}`}>
              <td>{index + 1}</td>
              <td>{row.status}</td>
              <td>{row.phone}</td>
              <td>{row.submit.toLocaleTimeString(language.locale)}</td>
              <td>{row.confirm.toLocaleTimeString(language.locale)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  render() {
    if (!this.state.lang)
      return <ClipLoader size={43} color={"#F79434"} loading={true} />;

    let language = this.state.lang;

    let canLoad =
      !this.state.loadingGraph &&
      !!this.state.month &&
      !!this.state.year &&
      !!this.state.currentCustomer;

    return (
      <>
        <div className="content">
          <AccountVerified notify={this.props.notify} />

          <Row>
            <Col md="4" xs="12">
              <Card className="card-chart" color="primary">
                <CardHeader>
                  <CardTitle tag="h3">
                    <b>{language.succesfulSmsTitle}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <b>
                        <h1>{this.state.succesful}</h1>
                      </b>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="12">
              <Card className="card-chart" color="warning">
                <CardHeader>
                  <CardTitle tag="h3">
                    <b>{language.totalSmsTitle}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col style={{ textAlign: "center" }}>
                      <b>
                        <h1>{this.state.total}</h1>
                      </b>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" xs="12">
              <Card className="card-chart" color="info">
                <CardHeader>
                  <CardTitle tag="h3">
                    <b>{language.remainingSmsTitle}</b>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col style={{ textAlign: "center" }} xs="4">
                      <b>
                        <h1>{this.state.remaining}</h1>
                      </b>
                    </Col>
                    {!!this.state.consumptionDate && (
                      <Col style={{ textAlign: "center" }} xs="8">
                        <h5>{language.consumptionInfo(this.state.consumptionRate, this.state.consumptionDate)}</h5>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row style={{ alignItems: "center" }}>
                    <Col xs="8">
                      <CardTitle tag="h3">
                        {language.historyChartTitle}
                      </CardTitle>
                    </Col>
                    <Col xs="4">
                      {!!getFromStorage("tokenAdmin") && (
                        <Input
                          type="select"
                          onChange={this.selectCustomer}
                          placeholder={language.selectCustomerText}
                          style={{ textTransform: "capitalize", width: "100%" }}
                        >
                          <option value={null} key="clearable">
                            {language.selectCustomerClear}
                          </option>
                          {this.state.customers?.map((customer, index) => (
                            <option
                              value={customer.id}
                              style={{ textTransform: "capitalize" }}
                              key={`option_customer_${index}`}
                            >
                              {customer.name}
                            </option>
                          ))}
                        </Input>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ alignItems: "center" }}>
                    <Col xs="6" md="3">
                      <Input
                        type="select"
                        style={{ textTransform: "capitalize", width: "100%" }}
                        onChange={this.selectMonth}
                        value={this.state.month}
                      >
                        {language.months.map((month, index) => (
                          <option
                            value={index}
                            style={{ textTransform: "capitalize" }}
                            key={`option_month_${index}`}
                          >
                            {month}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col xs="6" md="3">
                      <Input
                        type="select"
                        style={{ textTransform: "capitalize", width: "100%" }}
                        onChange={this.selectYear}
                        value={this.state.year}
                      >
                        {[2020, 2021, 2022, 2023, 2024].map((year, index) => (
                          <option value={year} key={`option_year_${index}`}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    {/* <Col xs="12" md="4">
                      <Button
                        tag="label"
                        className={classNames("btn-simple")}
                        style={{ width: "100%" }}
                        disabled={!canLoad}
                        active={canLoad}
                        color="primary"
                        id="0"
                        size="sm"
                        onClick={() => this.generateInterval()}
                      >
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ margin: "auto 5px" }}>
                            {language.loadHistoryButton}
                          </span>
                          <ClipLoader
                            size={10}
                            color={"#F79434"}
                            loading={this.state.loadingGraph}
                          />
                        </span>
                      </Button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody style={{ display: "flex", justifyContent: "center" }}>
                  {/* {this.state.loadingGraph ? (
                    <ClipLoader size={43} color={"#F79434"} loading={true} />
                  ) : ( */}
                  {!!this.state.data && (
                    <div className="chart-area">
                      <Line
                        options={{
                          ...chartExample1.options,
                          tooltips: {
                            intersect: false,
                            mode: "index",
                            axis: "x",
                          },
                          maintainAspectRatio: false,
                        }}
                        data={this.state.data}
                        onElementsClick={(elem) => {
                          if(!elem?.length)
                            return;

                          this.setState({day: this.state.dayLabels[ elem[0]._index ]}, () => this.loadDataDay());
                        }}
                      />
                    </div>
                  )}
                  {/*} )} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h3">
                    <Row>
                      <Col xs="3">{language.historyDetailsTitle}</Col>
                      <Col xs="3">
                        <Input
                          type="select"
                          style={{ textTransform: "capitalize", width: "100%" }}
                          onChange={this.selectDataDay}
                          value={this.state.day ?? ''}
                        >
                          <option
                            key={"option_day_default"}
                            value={null}
                          />
                          {this.state.dayLabels.map((day, index) => (
                            <option key={`option_day_${index}`}>{day}</option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  {this.state.loadingDetails ? (
                    <ClipLoader size={43} color={"#F79434"} loading={true} />
                  ) : (
                    !!this.state.dailyData && this.detailTable()
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default SmsDashboard;
