import React from "react";
import { Card, CardHeader, CardBody, Row, Col,Button, CardFooter, Modal, Input } from "reactstrap";
import { getFromStorage, setInStorage } from '../utils/storage';
import { apiLink, language } from '../utils/constants';

import ClipLoader from "react-spinners/ClipLoader";

class AccountVerified extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalPass: false,
            idAccount: getFromStorage('idAccount'),
            loading: true,
            pass: "",
            passConfirm: "",
            notCompleted: false
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value, fieldError: false })
      }

    toggleModalPass = () => {
        this.setState({
          modalPass: !this.state.modalPass
        });
    };
    
    componentDidMount(){
        try{
            fetch(apiLink + '/api/users/getCustomerInfo?idCustomer=' + getFromStorage("idStripe"), {
                method: 'GET',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getFromStorage("token")
                }
            }).then((res) => {
                return res.text();
            }).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                if(obj.status == 200){
                    if(obj.data.name != null && obj.data.address?.line1?.length > 1 &&  obj.data.address?.city?.length > 0){
                        
                    }else{
                        this.setState({notCompleted: true})
                    }
                }
            }).catch((error) => {
                this.props.onLogOut();
            });
        } catch(err){
        }
    }
    getTranslate = (value) =>{
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        return language[value];
      }

    onSavePass(){
        //console.log(this.props);
        if(this.state.pass === this.state.passConfirm && this.state.pass.trim() !==""){
            try{
                fetch(apiLink + '/api/users/savePassord', {
                    method: 'POST',
                    headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': getFromStorage("token")
                    },
                    body: JSON.stringify(this.state)
                }).then((res) => {
                    if(res.status == 200){
                        this.props.notify("tc", this.getTranslate("successPasswordUpdate"));
                        setInStorage('isNotSet', null);
                        this.toggleModalPass();
                        return res.text(); 
                    } else {
                        this.props.notify("tc", this.getTranslate("errorResponce"));
                    }  
                }).catch((error) => {
                        this.props.notify("tc", this.getTranslate("errorResponce"));
                        this.setState({loading: true});
                });
            } catch(err){
                this.props.notify("tc", this.getTranslate("errorResponce"));
                this.setState({loading: true});
            }
        }else{
            this.props.notify("tc", this.getTranslate("errorResponce"));
        }
    }

  render() {    
      let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    return (
        <>
        {(getFromStorage("isNotSet")) ? (
            <div className="content">
                <Row>
                <Col md="12">
                    <Card style={{backgroundColor: '#ebccd1'}}>
                        <CardBody>
                            <Row>
                            <Col>
                                <h4 style={{color: '#a94442'}}>{language.nopassword}</h4>
                            </Col>
                            <Col>
                                <Button className="float-right" size="sm" color="primary" type="submit" onClick={this.toggleModalPass}>{language.addpassword}</Button>
                            </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                </Row>

                <Modal
                    modalClassName="modal-pass"
                    isOpen={this.state.modalPass}
                    toggle={this.toggleModalPass}
                    
                    >
                    <div className="modal-header">
                        <h3 style={{color: "#000"}}>{language.addnewpassword}</h3>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalPass}
                        >
                        <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body" style={{color:"#000 !important"}}>
                        <Row>
                            <Col>
                                <label>{language.newpassword}</label>
                            <Input
                                name="pass"
                                type="password"
                                style={{color: "black"}}
                                onChange={this.handleChange}
                                invalid={this.state.fieldError}
                            />
                            </Col>
                            <Col>
                                <label>{language.confirmpassword}</label>
                            <Input
                                name="passConfirm"
                                type="password"
                                style={{color: "black"}}
                                onChange={this.handleChange}
                                invalid={this.state.fieldError}
                            />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer" style={{color:"#000 !important"}}>
                        <Button onClick={() => this.onSavePass()}>{language.save}</Button>
                    </div>
                </Modal>
            </div>):(<></>)}
            {
                (this.state.notCompleted) ? (<>
                    <div className="content">
                    <Row>
                        <Col md="12">
                            <Card style={{backgroundColor: '#fff3cd'}}>
                                <CardBody>
                                    <Row>
                                    <Col>
                <h4 style={{color: '#a94442'}}>{language.profileincomplete}</h4>
                                    </Col>
                                    <Col>
                <a href={`./profile`}><Button className="float-right" size="sm" color="primary" type="submit">{language.gotoprofile}</Button></a>
                                    </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        </Row>
                    </div>
                </>):(<></>) 
            }
        </>
    );
  
  }
}

export default AccountVerified;