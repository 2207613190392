export const language = {  
    newproduct: "Adauga produs",
    producttitle: "Titlul produsului",
    price: "Pret",
    producttype: "Tipul produsului",
    description: "Descriere",
    product: "Produs",
    subscription: "Abonament",
    title: "Titlu",
    yourdescription: "Aici poate fi descrierea ta",
    save: "Salveaza",
    products: "Produse",
    upload : "Incarcati",
    file: "Fisier",
    uploadfile: "Acesta este un text de ajutor la nivel de bloc pentru intrarea de mai sus. Este mai usor de incadrat o noua linie.",
    uploadbutton: "INCARCATI",
    mypaymentmethods: "Metodele mele de plata",
    paymentmethod: "METODE DE PLATA",
    cardtype: "TIPUL CARDULUI",
    number: "NUMARUL",
    expirydate: "DATA EXPIRARII",
    addpaymentmethod: "Adauga metoda de plata",
    newpaymentmethod: "Metoda de plata noua",
    country: "Tara",
    selectcountry: "Selectati tara",
    region: "Regiunea",
    address: "Adresa",
    phone: "Telefon",
    carddetails: "Detalii card",
    cardnumber: "Numar card",
    addcard: "Adauga card",
    username: "Utilizator",
    password: "Parola",
    login: "Logheaza-te",
    noaccount: "Nu am un cont",
    registerhere: "Inregistreaza-te aici",
    name: "Nume",
    email: "Email",
    confirmpassword: "Confirma parola",
    register: "Inregistreaza-te",
    back: "Inapoi la logare",
    updateprofile: "Actualizeaza profilul",
    nume: "Nume",
    prenume: "Prenume",
    taxid: "ID taxe",
    update: "Actualizare",
    mytaxids: "ID-urile mele fiscale",
    taxcountry: "TARA",
    value: "VALOARE",
    verificationstatus: "STATUS DE VERIFICARE",
    verificationaddress:"ADRESA DE VERIFICARE",
    verificationname: "NUME DE VERIFICARE",
    subscriptionid: "ABONAMENTE",
    subscriptionplan: "PLAN DE ABONAMENT",
    startingdate: "DATA DE INCEPERE",
    nextchargedate: "DATA SCADENTEI",
    priceid: "PRET",
    status: "STATUS",
    actions: "ACTIUNI",
    invoices: "Facturi",
    amount: "SUMA",
    currency: "MONEDA",
    invoicenumber: "NUMARUL FACTURII",
    createdat: "CREAT LA",
    dueto: "termen",
    creeazaabonament: "Creaza abonament",
    pricing: "Preturi",
    productsubscription: "PRODUS",
    qty: "CANTITATE",
    total: "TOTAL",
    acceptsubscription: "Accepta abonament",
    nopassword: "Contul dumneavoastra nu are parola...",
    addpassword: "Adaugati o parola",
    addnewpassword: "Adaugati o parola noua",
    newpassword: "Parola noua",
    addproduct: "Produs adaugat cu succes",
    problemconnection: "Probleme de conectare la server",
    shorttitle: "Titlu prea scurt",
    balance: "Sold",
    totalbalance: "Total",
    delete: "Sterge",
    amountcart: "PRET",
    gotocheckout: "Finalizare plata",
    changepassword: "Schimba parola",
    checkout: "Finalizare",
    selectpaymentmet: "Selecteaza o metoda de plata",
    newcard: "Card nou",
    totalabonamente: "Total Abonamente",
    totalfacturi: "Total Facturi",
    smsramase: "SMS-uri ramase",
    totalshipment: "Total Expedieri",
    paymentthismounth: "Plata in aceasta luna",
    payments: "Plati",
    productaddedtocart: "Produs adaugat in cos",
    addtocart: "Adauga in cos",
    summary: "Rezumat",
    billedto: "Platit la",
    invoiceno: "Factura nr",
    billingmethod: "Metoda de facturare",
    phonenumber: "Numar telefon",
    currencyinvoices: "Moneda",
    unitprice: "Pret unitar",
    subtotal: "Subtotal",
    vat: "TVA",
    downloadaspdf: "Descarca ca PDF",
    viewinvoice : "Vezi factura",
    plan: "Plan",
    subscribe: "Aboneaza-te",
    listaservicii : "Lista serviciilor",
    cancelplan: "Anuleaza planul",
    payment: "Plata",
    card: "Card",
    paymentsucces: "Plata cu succes",
    items: "Produse",
    companie: "Companie",
    addTax:"Adaugati ID fiscal",
    deleteTax: "Sterge",
    continue: "Continua",
    cupon:"Adauga cupon",
    successCustomerUpdate: "Informatiile clientului au fost salvate!",
    errorResponce: "O eroare a fost intalnita",
    successPasswordUpdate: "Parola a fost modificata!",
    successTaxIdAdd: "ID-ul de taxare a fost adaugat!",
    successPMAdd: "Metoda de plata a fost salvata!",
    forgot: "Ati uitat parola?",
    accountRecovery: "Recuperarea contului",
    sendEmail: "Recuperati-va contul, trimiteti-ne email-ul dumneavoastra.",
    sendEmailButton: "Trimite",
    successSendEmailMessage: "Verifica emailul pentru a recupera contul!",
    info: "Detalii",
    package: "Pachet SMS-uri",
    continut2: "Acest pachet contine 200 de mesaje",
    continut1: "Acest pachet contine 1000 de mesaje",
    continut5: "Acest pachet contine 5000 de mesaje",
    orderSMS: "Comanda",
    refund: "Restituire",
    company: "Companie",
    postal: "Cod postal",
    suport: "Suport",
    motiv:"Motiv",
    refund: "Restituire",
    cerereSuport: "Cerere suport",
    descriere: "Descriere",
    textDescriere: "Aici poate fi descrierea ta",
    send: "Trimite",
    setari: "Setari",
    subscriptionSetting: "Setari de abonament",
    ipaddress: "Adresa IP",
    timeSMS: "Ora trimitere SMS-uri",
    print: "Printeaza!",
    processing: "Prelucrare...",
    cerereInregsitrata: "Cerere suport inregistrata",
    erroroccurred: "A aparut o eroare",
    setarisalvate: "Setari salvate cu succes",
    emailused: "Email deja utilizat",
    productadded: "Produs adaugat in cos",
    tvanumbervalid: "Numar TVA Valid",

    dashboard: "Panou",
    myprofile: "Profilul meu",
    abonamentelemele: "Abonamentele mele",
    facturilemele: "Facturile mele",
    listaproduse: "Lista produse",
    listaabonamente: "Lista abonamente",
    tvanumbervalid: "Numar TVA valid",
    emailalreadyused: "Email deja utilizat",
    addedproduct: "Produs adaugat in cos",
    tvanumberinvalid: "Numar TVA invalid",
    payinvoice: "Platiti factura",
    viewcreditnote: "Vizualizati nota de credit",
    pay: "Plateste",
    cod: "Cod",
    adauga: "Adauga",
    moredetails: "Mai multe detalii",
    canceled: "Anulat(Activ pana la ",
    profileincomplete: "Profilul tau nu este completat",
    gotoprofile: "Mergi la profil",
    listinvoices: "Lista facturi",
    factura: "Factura ",
    profil: "Profil",
    logout: "Deconectati-va",
    passnomatch: "Parolele nu se potrivesc",
    balancesms: "Cumpara SMS-uri",
    historysms: "Istoric SMS",
    errorpass: "Parolele nu se potrivesc",
    validemail: "Foloseste un email valid",
    unpaidinvoice: "Factura neplatita Nr. ",
    welcome: "Bine ati venit la Still-Co",
    descrierestillco: "SUNTEM O COMPANIE DE DEZVOLTARE SOFTWARE.",
    getstarted: "INCEPE",
    verificationcode: "Introduceti codul de verificare",
    codenumber: "Introduceti codul pe care l-am trimis la (+40) XXX-XXX-",
    access: " pentru a va accesa contul.",
    resend: "Retrimite codul",
    createdbefore: "Creat inainte:",
    createdafter: "Creat dupa:",
    voidnumber: "Numar rambursare",
    chatsupport: "Chat",
    mymessages: "Mesajele mele",
    iddisputa: "Id Disputa",
    disputetype: "Tip disputa",
    openchat: "Deschide chat",
    chatmessage: "Tasteaza mesajul ... ",
    cart: "Cos",
    notification: "Notificari",
    messages: "Mesaje",
    disputa: "Disputa",
    getproduct: "Cumpara acum",
    viewcustomer: "Vizualizare client",
    cust: "Client",
    statusuri: "Status",
    closetopic: "Inchide subiect",
    with: "cu",
    from: "de la",
    customers: "Clienti",
    customername: "Nume client",
    actiuni: "Actiuni",
    idcustomer: "Id client",
    data: "Data",
    amount: "Suma",
    priceVAT: "cu TVA",
    year: "an",
    month: "luna",

    succesfulSmsTitle: "SMS trimise",
    totalSmsTitle: "SMS totale",
    remainingSmsTitle: "SMS rămase",
    loadHistoryButton: "Încarcă date",
    historyChartTitle: "Istoric SMS",
    historyDetailsTitle: "Detalii SMS",
    deliveredCategory: "livrat",
    expiredCategory: "expirat",
    rejectedCategory: "refuzat",
    otherCategory: "alte motive",
    confirmedCategory: "confirmate",
    unconfirmedCategory: "neconfirmate",
    totalCategory: "trimise",
    locale: 'ro-RO',
    messageStatusHeader: 'Stare',
    messageNumberHeader: 'Trimis către număr',
    messageSubmitHeader: 'Trimis la ora',
    messageConfirmHeader: 'Confirmat la ora',
    fromDateText: "De la data",
    toDateText: "Până la data",
    months: [ "ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octobrie", "noiembrie", "decembrie" ],
    dashboardLoadButton: "Încarcă date",

    withvat: "Preț cu TVA adăugat",
    consumptionInfo: (rate, date) => `La un consum de ${rate}/zi se recomandă reîncarcarea până la data de ${date}`,
    successAvatarUpdate: "Imaginea de profil a fost actualizată cu succes",
    changeProfile: "Schimbă imaginea de profil",
    saveProfile: "Salvează imaginea de profil",
    cancelProfile: "Anulează",

    invoiceData: "Facturat",
    paymentData: "Plătit",
    refundData: "Returnat",
}