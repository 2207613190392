import React from "react";
import { Row, Col, Button, Modal } from "reactstrap";
import { getFromStorage, setInStorage } from "../utils/storage";
import { apiLink } from "../utils/constants";

import ClipLoader from "react-spinners/ClipLoader";

class ChangeProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      idAccount: getFromStorage("idAccount"),
      loading: false,
      images: [],
      rowItems: 5,
      selectedIndex: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  getTranslate = (value) => {
    let language1 = null;
    if (getFromStorage("language") !== null) {
      language1 = require(`../utils/language_` + getFromStorage("language"));
    } else {
      language1 = require(`../utils/language_gb`);
    }
    let language = language1.language;
    return language[value];
  };

  getModal = (language) => {
    return (
      <Modal
        modalClassName="modal-pass"
        isOpen={this.state.open}
        toggle={this.toggleOpen}
      >
        <div className="modal-header">
          <h3 style={{ color: "#000" }}>{language.changeProfile}</h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={this.toggleOpen}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
        <div className="modal-body" style={{ color: "#000 !important" }}>
          {this.state.images.map((row, ri) => (
            <Row
              key={`profile_row_${ri}`}
              style={{ justifyContent: "center", margin: "2px" }}
            >
              {row.map((col, ci) => {
                let selected =
                  !!this.state.selectedIndex &&
                  this.state.selectedIndex.row === ri &&
                  this.state.selectedIndex.col === ci;

                return (
                  <Col
                    key={`profile_cell_${ri}_${ci}`}
                    xs="2"
                    style={{
                      padding: "6px",
                      margin: "4px",
                      borderRadius: "50%",
                      background: selected ? "#0098f0" : "#F79434",
                    }}
                  >
                    <img
                      alt={`Profile ${ri * this.state.rowItems + ci}`}
                      src={col.src}
                      onClick={() =>
                        this.setState({ selectedIndex: { row: ri, col: ci } })
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          ))}
        </div>
        <div
          className="modal-footer"
          style={{ color: "#000 !important", justifyContent: "center" }}
        >
          <Row>
            <Col>
              {!this.state.loading ? (
                <>
                  <Button
                    color="info"
                    style={{ marginRight: "10px" }}
                    disabled={!this.state.selectedIndex || this.state.loading}
                    onClick={() => this.updateAvatar()}
                  >
                    {language.saveProfile}
                  </Button>
                  <Button color="danger" onClick={this.toggleOpen}>
                    {language.cancelProfile}
                  </Button>
                </>
              ) : (
                <ClipLoader
                  size={43}
                  color={"#F79434"}
                  loading={this.state.loading}
                />
              )}
            </Col>
          </Row>
        </div>
      </Modal>
    );
  };

  componentDidMount() {
    let imagePaths = [];

    for (let i = 0; i < 20; i++) {
      try {
        require(`assets/img/${i}_avatar.png`);
        imagePaths.push(i);
      } catch (err) {}
    }

    let images = imagePaths.reduce((mat, current, index) => {
      let ri = Math.floor(index / this.state.rowItems),
        ci = index % this.state.rowItems;
      if (ci === 0) mat.push([]);

      mat[ri].push({
        src: require(`assets/img/${current}_avatar.png`),
        ref: `${current}_avatar`,
      });

      return mat;
    }, []);

    this.setState({ images: images });
  }

  updateAvatar() {
    this.setState({ loading: true });

    try {
      let index = this.state.selectedIndex;
      let avatar = this.state.images[index.row][index.col].ref;

      fetch(apiLink + "/api/users/updateAvatar", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getFromStorage("token"),
        },
        body: JSON.stringify({ avatar: avatar }),
      })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.props.notify("tc", this.getTranslate("successAvatarUpdate"));
            setInStorage("customerAvatar", avatar);

            //TODO: fix with root dispatch function
            window.location.reload();
            this.toggleOpen();
            return res.json();
          } else {
            this.props.notify("tc", this.getTranslate("errorResponce"));
          }
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.props.notify("tc", this.getTranslate("errorResponce"));
          this.setState({ loading: false });
        });
    } catch (err) {
      this.props.notify("tc", this.getTranslate("errorResponce"));
      this.setState({ loading: false });
    }
  }

  render() {
    let language1 = null;
    if (getFromStorage("language") !== null) {
      language1 = require(`../utils/language_` + getFromStorage("language"));
    } else {
      language1 = require(`../utils/language_gb`);
    }
    let language = language1.language;
    return (
      <>
        <Col>
          <Button color="info" type="submit" onClick={this.toggleOpen}>
            {language.changeProfile}
          </Button>
        </Col>

        {this.getModal(language)}
      </>
    );
  }
}

export default ChangeProfile;
