import React from "react";
import { useState } from "react";
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import axios from "axios";

import { apiLink } from './constants';

import { getFromStorage, setInStorage, resetStorage } from './storage';
import {Row, Button, Col} from 'reactstrap';

const CheckoutButttonPlan = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

      setProcessingTo(true);
      
      try {
        let cop = "";
        if(props.cupon !== null && props.cupon !== undefined){
            cop = "&cop="+props.cupon;
        }
            const { data: idInvoice } = await axios({
                method: 'post',
                url: apiLink+'/api/users/createSubscriptionCart',
                data: {
                    idCustomer:getFromStorage('idStripe'),
                    payment:props.payment,
                    idAccount:getFromStorage('idAccount'),
                    idPrice:props.idPrice,
                    cop: props.cupon
                },
                headers:{
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                }
              });
            //console.log(idInvoice.latest_invoice);
            const { data: clientSecret } = await axios.post(apiLink + '/api/users/paymentIntentCheckoutSubs?idInvoice=' + idInvoice.latest_invoice + cop, {
            });
            //console.log(clientSecret);
            
            if(clientSecret !== "Completed"){
                const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: props.payment
                });
                //console.log(error);
                if (error) {
                    setCheckoutError(error.message);
                    setProcessingTo(false);
                    //console.log(error.message);
                    props.notify("tc", error.message)
                    return;
                }
            }
            window.location.href = "/admin/successpayment";
                
      } catch (err) {
        //console.log(err);
      }
    
  };
  let language1 = null
  if(getFromStorage('language') !== null){
    language1 = require(`../utils/language_`+getFromStorage('language')); 
  }else{
    language1 = require(`../utils/language_gb`); 
  }
  let language = language1.language;
  
    return(
      
        <form onSubmit={handleFormSubmit}>
            <Button disabled={isProcessing || !stripe || props.disabled} className="btn-fill" color="primary" type="submit">
                {isProcessing ? language.processing : language.pay}
            </Button>
        </form>
    )
}
export default CheckoutButttonPlan;
