import React , { Component } from 'react';
//import boot from 'bootstrap/dist/css/bootstrap.min.css'
import { Row, Col, Container, Nav, NavItem, NavLink,Button } from 'reactstrap';
import logo from "assets/img/logoDoctorPlan.png";
import pms from "assets/img/pms.png";
import pms_pro from "assets/img/pms_pro.png";
class DoctorPlan extends React.Component {
    componentDidMount() {
        document.body.style.backgroundColor = "white";
    }
    render(){
        return(
            <>
              <header class="header_area">
                <div class="main_menu">
                    <nav class="navbar navbar-expand-lg navbar-light" style={{textAlign: "center"}}>
                        <div class="container box_1620"  style={{textAlign: "center",color: "#fff"}}>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                            <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
                                <ul class="nav navbar-nav menu_nav justify-content-end">
                                    <li class="nav-item active"><a class="nav-link" href="index.html" style={{textAlign: "center",color: "#fff"}}>Home</a></li> 
                                    <li class="nav-item"><a class="nav-link" href="feature.html" style={{textAlign: "center",color: "#fff"}}>Features</a></li> 
                                    <li class="nav-item"><a class="nav-link" href="service.html" style={{textAlign: "center",color: "#fff"}}>Service</a></li>
                                    <li class="nav-item"><a class="nav-link" href="pricing.html" style={{textAlign: "center",color: "#fff"}}>Pricing</a></li>
                                </ul>

                                <div class="nav-right text-center text-lg-right py-4 py-lg-0">
                                    <a class="button button-link mr-4" href="#"><span class="align-middle"><i class="ti-comments"></i></span> Live Chat</a>
                                    <a class="button button-outline button-small" href="#">Login</a>
                                </div>
                            </div> 
                        </div> 
                    </nav>
                </div>
            </header>
            <section class="hero-banner text-center">
                <div class="container">
                    <p class="text-uppercase">Smart Company With perfect space</p>
                    <h1>Powerful web Hosting</h1>
                    <p class="hero-subtitle">Form male saying she'd so every fifth winged after spirit male land moving won't seasons fish In shall given fifth edition</p>
                    <a class="button button-outline" href="#">Get Started</a>
                </div>
            </section>
            <Container style={{textAlign: "center", color: "#000"}}>
                <Row>
                    <Col>
                        <div style={{textAlign: "center", marginTop:"5vh"}}>
                            <img src={logo}></img>
                            <div></div>
                            <p style={{textAlign: "center",color: "#000", fontWeight: 500, fontSize: '18px'}}>Pacient Manager este un software construit intr-o tehnologie web-based, in sensul ca baza de date este mentinuta pe servere in internet iar accesul securizat se face de pe orice calculator personal care are la dispozitie un browser web.</p>
                            <Button color="primary">Aboneaza-te</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{textAlign: "center", marginTop:"5vh"}}>
                            <h1 style={{color: "#000", fontWeight: 500}}>Despre Produs</h1>
                            <div></div>
                            <p style={{textAlign: "center",color: "#000", fontWeight: 500, fontSize: '18px'}}>Pacient Manager este un software construit intr-o tehnologie web-based, in sensul ca baza de date este mentinuta pe servere in internet iar accesul securizat se face de pe orice calculator personal care are la dispozitie un browser web.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{textAlign: "center", marginTop:"5vh"}}>
                            <h1 style={{color: "#000", fontWeight: 500}}>Functii</h1>
                            <div></div>
                            <p style={{textAlign: "center",color: "#000", fontWeight: 500, fontSize: '18px'}}>Pacient Manager este un software construit intr-o tehnologie web-based, in sensul ca baza de date este mentinuta pe servere in internet iar accesul securizat se face de pe orice calculator personal care are la dispozitie un browser web.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{textAlign: "center", marginTop:"5vh"}}>
                            <h1 style={{color: "#000", fontWeight: 500}}>Contract</h1>
                            <div></div>
                            <p style={{textAlign: "center",color: "#000", fontWeight: 500, fontSize: '18px'}}>Pacient Manager este un software construit intr-o tehnologie web-based, in sensul ca baza de date este mentinuta pe servere in internet iar accesul securizat se face de pe orice calculator personal care are la dispozitie un browser web.</p>
                        </div>
                    </Col>
                </Row>
            </Container></>
        )
    }

}

export default DoctorPlan;