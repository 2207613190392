import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Payment from "views/Payment.js"
import AddProduct from "views/AddProducts";
import ListProducts from "views/ListProducts";
import Profile from "views/Profile";
import CreateAbonament from "views/CreateAbonament";
import InvoiceTemplate from 'views/InvoiceTemplate';
import CheckOutPage from "views/CheckOutPage";
import SuccessPayment from "views/SuccessPayment";
import GetProducts from "views/GetProducts";
import Balance from "views/Balance";
import ListSubscriptions from "views/ListSubscriptions";
import MySubscriptions from "views/Mysubscriptions";
import MyInvoices from 'views/MyInvoices';
import SubscriptionSetting from 'views/SubscriptionSetting';
import CheckoutOpenInvoices from 'utils/CheckoutOpenInvoices';
import SupportSubmit from "views/SupportSubmit";
import CreditNoteTemplate from "views/CreditNoteTemplate";
import MyMessages from "views/MyMessages";
import Chat from "views/Chat";
import SmsDashboard from "views/SmsDashboard";

var routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/history",
    name: "historysms",
    icon: "tim-icons icon-paper",
    component: SmsDashboard,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "myprofile",
    icon: "tim-icons icon-badge",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/addproduct",
    name: "Add Product",
    icon: "tim-icons icon-simple-add",
    component: AddProduct,
    layout: "/admin",
    redirect: true
  },
  
  {
    path: "/abonamente",
    name: "abonamentelemele",
    icon: "tim-icons icon-calendar-60",
    component: MySubscriptions,
    layout: "/admin"
  },
  {
    path: "/invoices",
    name: "facturilemele",
    icon: "tim-icons icon-credit-card",
    component: MyInvoices,
    layout: "/admin"
  },
  {
    path: "/listproducts",
    name: "listaproduse",
    icon: "tim-icons icon-bag-16",
    component: GetProducts,
    layout: "/admin"
  },
  {
    path: "/listabonamente",
    name: "listaabonamente",
    icon: "tim-icons icon-tag",
    component: ListProducts,
    layout: "/admin"
  },
  {
    path: "/payment",
    name: "Payment",
    icon: "tim-icons icon-money-coins",
    component: Payment,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/newsubs",
    name: "Create Subscription",
    icon: "tim-icons icon-money-coins",
    component: CreateAbonament,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/invoice",
    name: "Invoice",
    icon: "tim-icons icon-money-coins",
    component: InvoiceTemplate,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/creditnote",
    name: "CreditNote",
    icon: "tim-icons icon-money-coins",
    component: CreditNoteTemplate,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/checkoutpage",
    name: "Checkout",
    icon: "tim-icons icon-money-coins",
    component: CheckOutPage,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/support",
    name: "Support",
    component: SupportSubmit,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/balance",
    name: "balancesms",
    icon: "tim-icons icon-money-coins",
    component: Balance,
    layout: "/admin"
  },
  {
    path: "/successpayment",
    name: "Success",
    icon: "tim-icons icon-money-coins",
    component: SuccessPayment,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/subscriptionSet",
    name: "Subscription Settings",
    component: SubscriptionSetting,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/checkoutopen",
    name: "Checkout Open Invoices",
    component: CheckoutOpenInvoices,
    layout: "/admin",
    redirect: true
  },
    {
    path: "/mymessages",
    name: "chatsupport",
    component: MyMessages,
    icon: "tim-icons icon-chat-33",
    layout: "/admin",
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    layout: "/admin",
    redirect: true
  }
];
export default routes;
