import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  FormText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Table
} from "reactstrap";
import NewPaymentMethod from "./NewPaymentMethod";
import Layout from '../utils/Layout';
import CheckoutButton from '../utils/CheckoutButtonPlan';
import FA from 'react-fontawesome';
class CreateAbonament extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        product: {
            nickname: "",
            interval: "",
            amount:"",
            newCard: null,
            plan: ""
        },
        taxable: false,
        newCard: null,
        paymentMethods: [],
        showPaymentMethods: false,
        cupon: null,
        couponCode: '',
        type_coupon: '',
        modalPass: false,
        percent_off: 0,
        blockCoupon: false
    }

    this.handleChange = this.handleChange.bind(this);
  }


  toggleModalPass = () => {
    this.setState({
      modalPass: !this.state.modalPass
    });
};

  handleChange(event) {
    this.setState({ [event.target.name] : event.target.value, fieldError: false })
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let idProduct = params.get('idPlan');
    //console.log(idProduct)
      try{
        fetch(apiLink + '/api/produse/getSubsById?idProduct=' + idProduct, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                //console.log(obj.data);
                this.setState({product: obj.data, plan: idProduct})
            })
        .catch((error) => {
          this.props.onLogOut();
        });
    }catch(err){
        //
    }

    try{
      fetch(apiLink + '/api/users/gettaxable', {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                      idCustomer: getFromStorage('idStripe'),
                  })
              }).then((res) => {
                 return res.text();   
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              this.state.taxable = obj.taxable;
              //console.log(obj);
          })
      .catch((error) => {
        this.props.onLogOut();
      });
  }catch(err){
      //
  }

  }
  onSubmitPayment = () =>{
     try{
         fetch(apiLink + '/api/users/createsubscription', {
                     method: 'POST',
                     headers: {
                         'Accept': 'application/json',
                         'Authorization': getFromStorage('token'),
                         'Content-Type': 'application/json',
                     },
                     body: JSON.stringify({
                         idCustomer: getFromStorage('idStripe'),
                         idAccount: getFromStorage('idAccount'),
                         idPayment: this.state.newCard,
                         idPlan: this.state.plan
                     })
                 }).then((res) => {
                    return res.text();   
                 }
             ).then((responseJson) => {
                 var obj = JSON.parse(responseJson);

                 //console.log(obj);
             })
         .catch((error) => {
          this.props.onLogOut();
         });
     }catch(err){
         //
     }
  }
  onSubmit = () => {
    try{
      fetch(apiLink + '/api/users/getPaymentOptions?idCustomer=' + getFromStorage('idStripe'), {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                //console.log(obj);
                this.setState({paymentMethods:obj.data.data, showPaymentMethods: true, newCard: null});
            })
        .catch((error) => {
          this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }

  checkCoupon = () => {
    console.log("ASDASDAD")
    try{
      fetch(apiLink + '/api/users/checkCoupon', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      cop: this.state.couponCode
                    })
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                console.log(obj);
                if(obj.status === 200){
                  this.setState({type_coupon: obj.type, percent_off: obj.coupon, modalPass: !this.state.modalPass, blockCoupon: true})
                }
            })
        .catch((error) => {
          this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }


  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    console.log(this.state.type_coupon);
    let toatlCharge = this.state.product.amount/100;
    if(this.state.percent_off > 0){
      if(this.state.type_coupon === "percent")
        toatlCharge = toatlCharge - ((toatlCharge)*this.state.percent_off)/100;
      else if(this.state.type_coupon === "amount")
        toatlCharge = toatlCharge - this.state.percent_off/100;
    }
    if(this.state.taxable)
      toatlCharge = toatlCharge + ((toatlCharge)*20)/100;
    let paymentOptions = [];
    if(this.state.showPaymentMethods) {
      paymentOptions = this.state.paymentMethods.map(function(item, i){
        return(
        <FormGroup check key={i}>
          <Label check>
            <Input type="radio" name="newCard" value={item.id} onChange={this.handleChange} />{' '}
            { (item.card.brand === "visa") ? (<FA
        name="cc-visa"
        size="2x"
      />):(<></>)}{ (item.card.brand === "amex") ? (<FA
        name="cc-amex"
        size="2x"
      />):(<></>)}{ (item.card.brand === "discover") ? (<FA
        name="cc-discover"
        size="2x"
      />):(<></>)}{ (item.card.brand === "diners") ? (<FA
        name="cc-diners-club"
        size="2x"
      />):(<></>)}{ (item.card.brand === "mastercard") ? (<FA
        name="cc-mastercard"
        size="2x"
      />):(<></>)}  {item.card.brand.charAt(0).toUpperCase() + item.card.brand.slice(1)}-{item.card.last4}
          </Label>
        </FormGroup>
      )
      }.bind(this))
    }
    console.log(this.state.product);
    return (
      <>
        <div className="content">
          
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h3 className="title">{language.creeazaabonament}</h3>
                </CardHeader>
                <CardBody>
                  <Form>
                  <h4 className="title">{language.pricing}</h4>
                  <Table className="tablesorter" >
                      <thead className="text-primary">
                        <tr>
                          <th>{language.productsubscription}</th>
                          <th>{language.qty}</th>
                          <th>{language.total}</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td>{this.state.product.nickname}</td>
                            <td>1</td>
                            <td>{this.state.product.amount/100} €/{this.state.product.interval}</td>
                          </tr>
                          { (this.state.taxable) ? (<tr><td></td><td>VAT – France</td><td>20%</td></tr>) : <></>}
                          { (this.state.percent_off > 0) ? (<tr><td></td><td>Discount</td><td>{(this.state.type_coupon === "percent") ? (this.state.percent_off + "%"):(this.state.percent_off/100 + "€")}</td></tr>) : <></>}
                          <tr><td></td><td>{language.totalbalance}</td><td>{toatlCharge} €/{this.state.product.interval}</td></tr>
                      </tbody>
                  </Table>
                  </Form>
                  <Row><Col>
                  {
                    (this.state.showPaymentMethods)? 
                    (<>
                    <label><b>{language.selectpaymentmet}</b></label>
                    
                      <FormGroup tag="fieldset">
                        {paymentOptions}
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="newCard" value="101" onChange={this.handleChange} />{' '}
                              {language.newcard}
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      { (this.state.newCard === "101") ? (<NewPaymentMethod success={this.onSubmit} notify={this.props.notify}></NewPaymentMethod>):(<div></div>)}
                      { (this.state.cupon === "101") ? (<Input type="text" name = "cuponcode" value="fsd234dsfsdf3423erhfg" onChange={this.handleChange}/>):(<div></div>)}
                      <Layout>
                            <CheckoutButton payment={this.state.newCard} idPrice={this.state.plan} disabled={(this.state.newCard === null || this.state.newCard === undefined || this.state.newCard === "101")? true:false} notify={this.props.notify} cupon={this.state.couponCode.length > 0? this.state.couponCode:null}></CheckoutButton>
                        </Layout>
                    </>):(<Button className="btn-fill" color="primary" type="submit" onClick={this.onSubmit}>
                    {language.acceptsubscription}
                          </Button>)
                  }
                  {
                    (!this.state.blockCoupon) ? (<Button className="btn-fill" color="primary" onClick={this.toggleModalPass}>
                    {language.cupon}
                          </Button>):(<></>)
                  }
                  
                  </Col></Row>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
                    modalClassName="modal-pass"
                    isOpen={this.state.modalPass}
                    toggle={this.toggleModalPass}
                    
                    >
                    <div className="modal-header">
                <h3 style={{color: "#000"}}>{language.cupon}</h3>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalPass}
                        >
                        <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body" style={{color:"#000 !important"}}>
                        <Row>
                            <Col>
                <label>{language.cod}</label>
                              <Input
                                  name="couponCode"
                                  style={{color: "black"}}
                                  onChange={this.handleChange}
                                  invalid={this.state.fieldError}
                              />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer" style={{color:"#000 !important"}}>
                <Button onClick={this.checkCoupon}>{language.adauga}</Button>
                    </div>
                </Modal>
      </>
    );
  }
}

export default CreateAbonament;
