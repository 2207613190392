import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import {countries} from 'country-data';
import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  FormText,
  Label,
  Col,
  Table,
  Container
} from "reactstrap";

import ReactToPrint from 'react-to-print';
import logo from "../assets/img/logo.png";
import FA from 'react-fontawesome';
import {isInEU} from '../utils/country';

class CreditNoteTemplate extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            invoice: {
                customer_tax_ids: [],
                discount: {}
            },
            invoiceLines: [],
            paymentMethods: [],
            showPaymentMethods: false,
            customerInfo: {}
        }
    
        this.componentRef = React.createRef();
        // this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let idInvoice = params.get('idCredit');
        //console.log(idInvoice)
          try{
            fetch(apiLink + '/api/users/getCreditNote?idInvoice=' + idInvoice, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('token'),
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    if(getFromStorage("tokenAdmin") != null){
                        var obj = JSON.parse(responseJson);
                        console.log(obj.data);
                        this.setState({invoice: obj.data})
                            fetch(apiLink + '/api/admin/getCustomerInfo?idCustomer=' + obj.data.customer, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Authorization': getFromStorage('tokenAdmin'),
                                    'Content-Type': 'application/json',
                                }
                            }).then((res) => {
                                return res.text();   
                            }
                        ).then((responseJson) => {
                            var obj = JSON.parse(responseJson);
                            console.log(obj.data)
                            this.setState({customerInfo: obj.data});
                        }).catch((error) => {
                            this.props.onLogOut();
                    });
                    }else{
                        var obj = JSON.parse(responseJson);
                                console.log(obj.data);
                                this.setState({invoice: obj.data})
                                    fetch(apiLink + '/api/users/getCustomerInfo?idCustomer=' + obj.data.customer, {
                                        method: 'GET',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Authorization': getFromStorage('token'),
                                            'Content-Type': 'application/json',
                                        }
                                    }).then((res) => {
                                        return res.text();   
                                    }
                                ).then((responseJson) => {
                                    var obj = JSON.parse(responseJson);
                                    console.log(obj.data)
                                    this.setState({customerInfo: obj.data});
                                }).catch((error) => {
                                    this.props.onLogOut();
                            });
                    }
            
            })
            .catch((error) => {
                this.props.onLogOut();
            });
        }catch(err){
            //
        }
    }

    convertDate = (unix_timestamp) =>{
          let date = new Date(unix_timestamp * 1000);
          // Hours part from the timestamp
          let day = date.getDate();
          // Minutes part from the timestamp
          let month = date.getMonth()+1;
          // Seconds part from the timestamp
          let year = date.getFullYear();
  
          // Will display time in 10:30:23 format
          let formattedTime = day+ "/"+month+"/"+year;
          return formattedTime;
    }
  render() {
    //console.log(this.state.invoice);
    let template = [];

    if(this.state.invoice.lines?.data.length > 0){
        template = this.state.invoice.lines?.data.map(function(item, i){
            return(
                <tr key={i} style={{color: "#000", border: "solid", borderColor: "#2857A4"}}>
                   <td style={{color: "#000"}}>
                       {item.description}
                   </td>
                    <td style={{color: "#000"}}>
                        {this.convertDate(this.state.invoice.created)}
                    </td>
                    <td style={{color: "#000"}}>
                        -{item.quantity}
                    </td>
                    <td style={{color: "#000"}}>
                        {item.unit_amount/100} {this.state.invoice.currency}
                    </td>
                    <td style={{color: "#000"}}>
                        - {item.amount/100} {this.state.invoice.currency}
                    </td>
                </tr>
            )
        }.bind(this))
   }
    return (
      <>
        <div className="content" >
            <div  ref={this.componentRef}>
                <Container>
                    <Row>
                        <Col>
                            <Card style={{background:"#fff", color:"#000"}}>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <Row><Col><img src={logo} style={{minWidth: "350px"}}/></Col></Row>
                                            <Row><Col style={{color: "#2857A4", marginLeft:"25px", fontSize: "18px", fontWeight:"700"}}>STILL-CO SARL</Col></Row>
                                            <Row><Col style={{color: "#000", marginLeft:"25px", fontSize: "18px"}}>R.C.S : 818 273 401 Paris</Col></Row>
                                            <Row><Col style={{color: "#000", marginLeft:"25px", fontSize: "18px"}}>N.I.I : FR87818273401</Col></Row>
                                            <Row><Col style={{color: "#000", marginLeft:"25px", fontSize: "18px"}}>Adresse du siège : 18-26 Rue Goubet 75019 Paris</Col></Row>
                                            <Row><Col style={{color: "#000", marginLeft:"25px", fontSize: "18px"}}>France</Col></Row>
                                        </Col>
                                        <Col>
                                            <Row style={{marginTop: "50px"}}>
                                                <Col style={{textAlign: "right", color: "#2857A4", fontSize: "24px", fontWeight:"700"}}>
                                                    Société de service informatique
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader><CardBody> 
                                    <Row>
                                        <Col>
                                        <div style={{borderStyle: "solid", width: "143mm", marginLeft:'132mm', height: '55mm', borderColor:"#2857A4", borderRadius:"15px"}}>
                                                <div style={{textAlign: "center", fontSize: "21px"}}>
                                                    
                                                    {this.state.customerInfo?.description} <div></div>
                                                    {this.state.customerInfo?.address ? this.state.customerInfo?.address.line1: ""}<div></div>
                                                    {this.state.customerInfo?.address ? this.state.customerInfo?.address.line2: ""}<div></div>
                                                    {this.state.customerInfo?.address ? this.state.customerInfo?.address.city: ""}  {this.state.customerInfo?.address ? this.state.customerInfo?.address.postal_code: ""} / {this.state.customerInfo?.address ? countries[this.state.customerInfo?.address.country].name: ""}<div></div>
                                                    {(this.state.customerInfo?.metadata?.cui) ? (<>Cod fiscal: {this.state.customerInfo?.metadata?.cui}</>):(<></>)}<div></div>
                                                    
                                                    {this.state.customerInfo?.name}<div></div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row><Row style={{marginTop: "75px"}}>
                                        <Col style={{textAlign: "center"}}>
                                            <h2 style={{color: "#2857A4", fontSize: "24px", fontWeight:"700"}}>FACTURE</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Row><Col style={{color: "#2857A4", marginLeft:"25px", fontSize: "18px", fontWeight:"700"}}>PARIS le {this.convertDate(this.state.invoice.created)}</Col></Row>
                                            <Row><Col style={{color: "#2857A4", marginLeft:"25px", fontSize: "18px", fontWeight:"700"}}>Facture : {this.state.invoice.number}</Col></Row>
                                            <Row><Col style={{color: "#2857A4", marginLeft:"25px", fontSize: "18px", fontWeight:"700"}}>Votre N° TVA Intracomm. : { (this.state.customerInfo?.tax_ids && this.state.customerInfo?.tax_ids.length > 0) ? this.state.customerInfo?.tax_ids[0].value:"-"}</Col></Row>
                                            <Row><Col style={{color: "#2857A4", marginLeft:"25px", fontSize: "18px", fontWeight:"700"}}>Notre N° TVA Intracomm. : FR87818273401</Col></Row>
                                        </Col>
                                    </Row><Row>
                                        <Col>
                                            <Table style={{background:"#fff", color:"#000"}}>
                                                <thead style={{background:"#fff", color:"#000"}}>
                                                    <tr style={{color: "#000", border: "solid", borderColor: "#2857A4"}}>
                                                        <th style={{color: "#000"}}>Désignation</th>
                                                        <th style={{color: "#000"}}>Date</th>
                                                        <th style={{color: "#000"}}>Qté</th>
                                                        <th style={{color: "#000"}}>Prix unitaire</th>
                                                        <th style={{color: "#000"}}>Montant</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{color: "#000"}}>
                                                    {template}
                                                    <tr style={{color: "#000"}}>
                                                        <td></td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{color: "#000"}}>Subtotal</td>
                                                        <td style={{color: "#000"}}>- {this.state.invoice.subtotal/100} {this.state.invoice.currency}</td>
                                                    </tr>
                                                    {
                                                        (this.state.invoice.discount_amount) ?
                                                        (<tr>
                                                            <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{color: "#000"}}>Discount</td>
                                                        <td style={{color: "#000"}}>{this.state.invoice.discount_amount/100} {this.state.invoice.currency}</td></tr>):(<></>)
                                                    }
                                                    <tr style={{color: "#000"}}>
                                                        <td style={{color: "#000"}}>En votre aimable par prelevent le 20 du mois suivant</td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{color: "#000"}}>TVA</td>
                                                <td style={{color: "#000"}}>{(this.state.invoice.tax_amounts?.length > 0) ? (<>-{this.state.invoice.tax_amounts[0].amount/100}</>):(<>0</>)} {this.state.invoice.currency}</td>
                                                    </tr>
                                                    <tr style={{color: "#000"}}>
                                                        <td style={{color: "#000"}}>Date d'echeance : {this.convertDate(this.state.invoice.created)}</td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{visibility: 'hidden'}}></td>
                                                        <td style={{color: "#000"}}>Total</td>
                                                        <td style={{color: "#000"}}>-{this.state.invoice.total/100} {this.state.invoice.currency}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>    
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Row><Col style={{color: "#2857A4", fontSize: "18px", fontWeight:"700"}}>IBAN:  FR76 3000 3034 3000 0207 1148 565  BIC: SOGEFRPP</Col></Row>
                                            <Row><Col style={{color: "#000", fontSize: "14px"}}>Pas de pénalités de retard - Pas d'escompte en cas de paiement anticipé</Col></Row>
                                            <Row><Col style={{color: "#000", fontSize: "14px"}}>Indemnité forfaitaire pour frais de recouvrement en cas de retard de paiement : 40.00 Euro</Col></Row>
                                            <Row style={{marginTop: "50px", textAlign:"center"}}><Col style={{color: "#000", fontSize: "18px"}}><FA name="phone" size="2x" /> +33170613111</Col><Col style={{color: "#000", fontSize: "14px"}}><FA name="home" size="2x" /> 18-26 Rue Goubet 75019 Paris France</Col></Row>
                                            <Row style={{textAlign:"center"}}><Col style={{color: "#000", fontSize: "18px"}}><FA name="envelope-o" size="2x" /> office@stillco.fr</Col><Col style={{color: "#000", fontSize: "14px"}}><FA name="search" size="2x" /> https://www.stillco.fr/</Col></Row>                                            
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
          <ReactToPrint
            trigger={() => <Button color="primary">Print this out!</Button>}
            content={() => this.componentRef.current}
            />

            
        </div>
      </>
    );
  }
}

export default CreditNoteTemplate;
