import React from "react";
import { useState } from "react";
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import axios from "axios";

import { apiLink } from './constants';

import { getFromStorage, setInStorage, resetStorage } from './storage';
import {Row, Button, Col} from 'reactstrap';

const CheckoutButtton = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

      setProcessingTo(true);
      
      try {
        console.log("DSADSADASDSADASD")
            const { data: paymentIntent } = await axios.post(apiLink + '/new/createInvoice?idCustomer=' +getFromStorage('idStripe')+"&idAccount="+getFromStorage("idAccount"), {
            });
            console.log(paymentIntent)
             const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
               payment_method: props.payment
            });
            //console.log(error);
            if (error) {
              setCheckoutError(error.message);
              setProcessingTo(false);
              //console.log(error.message);
              props.notify("tc", error.message)
              return;
            }
            try{
                fetch(apiLink + '/new/checkIntent/', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': getFromStorage('token'),
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({paymentId: paymentIntent.id})
                        }).then((res) => {
                            return res.text();   
                        }
                    ).then((responseJson) => {
                        var obj = JSON.parse(responseJson);
                        //console.log(obj)
                        if(obj.status === 200){
                            try{
                                fetch(apiLink + '/api/cart/deletecart/?idCustomer='+getFromStorage('idAccount'), {
                                            method: 'GET',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Authorization': getFromStorage('token'),
                                                'Content-Type': 'application/json',
                                            }
                                        }).then((res) => {
                                            return res.text();   
                                        }
                                    ).then((responseJson) => {
                                        var obj = JSON.parse(responseJson);
                                        //console.log(obj)
                                        if(obj.status === 200){
                                            //console.log("EHEEEEEEE");
                                        }
                                    })
                                    .catch((error) => {
                                        this.props.onLogOut();
                                    });
                                }catch(err){
                                    //
                                }finally{
                                    window.location.href = "/admin/successpayment";
                                }
                            

                        }else{
                            console.log("ASDASDASDASDASDADS ERROR")
                        }
                    })
                    .catch((error) => {
                        //
                    });
                }catch(err){
                    //
                }finally {
                    
                }
      } catch (err) {
        console.log(err);
      }
    
  };
  let language1 = null
  if(getFromStorage('language') !== null){
    language1 = require(`../utils/language_`+getFromStorage('language')); 
  }else{
    language1 = require(`../utils/language_gb`); 
  }
  let language = language1.language;
  
    return(
      
        <form onSubmit={handleFormSubmit}>
            <Button disabled={isProcessing || !stripe || props.disabled} className="btn-fill" color="primary" type="submit">
                {isProcessing ? language.processing : language.pay}
            </Button>
        </form>
    )
}
export default CheckoutButtton;
