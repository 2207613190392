import React , { Component } from 'react';
import {
    Hero, CallToAction, ScrollDownIndicator,Checklist,Section,PricingTier
  } from 'react-landing-page'
  
import { Row, Col, Container, Nav, NavItem, NavLink,Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import logo from "assets/img/logoDoctorPlan.png";
import back from "assets/img/doctor-software-FB.png";
import pms from "assets/img/pms.png";
import pms_pro from "assets/img/pms_pro.png";
import blue from "assets/img/blueBack.jpg";

class Template extends React.Component {
    scrollToRef = (ref) => {//console.log(ref);
     }  
    constructor(props){
        super(props);  
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.scrollToContent = this.scrollToContent.bind(this);
    }
    componentDidMount() {
        document.body.style.backgroundColor = "white";
        document.body.style.color = "black";
    }
    scrollToContent(content) {
        //console.log(content);
        switch(content) {
          case 1:
            window.scrollTo({
                top: this.section1.current.offsetTop,
                behavior: 'smooth'
            });
            break;
            case 2:
              window.scrollTo({
                  top: this.section2.current.offsetTop,
                  behavior: 'smooth'
              });
              break;
              case 3:
                window.scrollTo({
                    top: this.section3.current.offsetTop,
                    behavior: 'smooth'
                });
                break;
        }
      }
    render() {
        return(<>
        <div style={{background: `url(${back}) no-repeat center center fixed`, backgroundSize:"cover"}}>
            <Hero style={{backgroundColor:"rgba(255, 255, 255, 0.9)"}}>
                <img src={logo} style={{width: "auto"}}></img>
                <p style={{color: "#000", textAlign: "center", maxWidth:"450px"}}>Pacient Manager este un software construit intr-o tehnologie web-based, in sensul ca baza de date este mentinuta pe servere in internet iar accesul securizat se face de pe orice calculator personal care are la dispozitie un browser web.</p>
                <CallToAction href="#" mt={3}>Despre Aplicatie</CallToAction>
                <ScrollDownIndicator  onClick={() => this.scrollToContent(1)}/>
                
            </Hero>
            <div ref={this.section1}>
            <Hero  style={{backgroundColor:"rgba(255, 255, 255, 0.9)"}}
                color="black">
                <h1 style={{color:"#000"}}>Despre Aplicatie</h1>
                <Container>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={6} >
                            <img src={pms} style={{width: "auto", textAlign: "center"}}></img>
                        </Col>
                        <Col md={6}>
                            <p style={{color: "#000", textAlign: "center"}}>Pacient Manager este un software construit intr-o tehnologie web-based, in sensul ca baza de date este mentinuta pe servere in internet iar accesul securizat se face de pe orice calculator personal care are la dispozitie un browser web.</p>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={6} >
                            <img src={pms} style={{width: "auto", textAlign: "center"}}></img>
                        </Col>
                        <Col md={6}>
                            <p style={{color: "#000", textAlign: "center"}}>Pacient Manager este un software construit intr-o tehnologie web-based, in sensul ca baza de date este mentinuta pe servere in internet iar accesul securizat se face de pe orice calculator personal care are la dispozitie un browser web.</p>
                        </Col>
                    </Row>
                </Container>
                <ScrollDownIndicator  onClick={() => this.scrollToContent(2)}/>
            </Hero>
            </div>
            <div ref={this.section2}>
            <Hero style={{backgroundColor:"rgba(255, 255, 255, 0.9)"}}
                color="black">
                <h1 style={{color:"#000"}}>Pricing</h1>
                <Container>
                    <Row style={{marginTop: "15px"}}>
                        <Col md={4} >
                            <Card style={{color:"#000", background:"#68BBE3"}}>
                                <CardHeader style={{color:"#000", textAlign:"center"}}><h1 style={{color:"#fff"}}>Basic Plan</h1></CardHeader>
                                <CardBody style={{color:"#000"}}>
                                    <Checklist children={[
                                        'Open Source',
                                        'React best practices',
                                        'Practical API'
                                    ]}
                                    color="black"/>
                                </CardBody>
                                <CardFooter style={{textAlign:"center"}}>
                                    <CallToAction href="/checkoutplan" mt={3} style={{background: "#fff", color:"#000"}}>100 €/month</CallToAction>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col md={4} >
                            <Card style={{color:"#000", background:"#68BBE3"}}>
                                <CardHeader style={{color:"#000", textAlign:"center"}}><h1 style={{color:"#fff"}}>Premium Plan</h1></CardHeader>
                                <CardBody style={{color:"#000"}}>
                                    <Checklist children={[
                                        'Open Source',
                                        'React best practices',
                                        'Practical API'
                                    ]}
                                    color="black"/>
                                </CardBody>
                                <CardFooter style={{textAlign:"center"}}>
                                    <CallToAction href="/checkoutplan" mt={3} style={{background: "#fff", color:"#000"}}>100 €/month</CallToAction>
                                </CardFooter>
                            </Card>
                        </Col><Col md={4} >
                            <Card style={{color:"#000", background:"#68BBE3"}}>
                                <CardHeader style={{color:"#000", textAlign:"center"}}><h1 style={{color:"#fff"}}>SMS Plan</h1></CardHeader>
                                <CardBody style={{color:"#000"}}>
                                    <Checklist children={[
                                        'Open Source',
                                        'React best practices',
                                        'Practical API'
                                    ]}
                                    color="black"/>
                                </CardBody>
                                <CardFooter style={{textAlign:"center"}}>
                                    <CallToAction href="/checkoutplan" mt={3} style={{background: "#fff", color:"#000"}}>100 €/month</CallToAction>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ScrollDownIndicator  onClick={() => this.scrollToContent(3)}/>
            </Hero>
            </div>
            <div ref={this.section3}>
            <Hero
              color="black"
              style={{backgroundColor:"rgba(255, 255, 255, 0.9)"}}
            >
                <Container style={{textAlign:"center"}}>
                    <h1 style={{color:"#000"}}>FEEL FREE TO CONTACT US. WE'RE HERE TO FIND THE BEST SOLUTIONS FOR YOUR PROBLEMS.</h1>
                    <Row>
                        <Col>
                        <i className="tim-icons icon-square-pin" style={{fontSize: "16px", marginRight:"5px"}} /> ADDRESS: Str. Aleea Albatrosuluin 1 Galati, Galati<div></div>
                        <i className="tim-icons icon-chat-33" style={{fontSize: "16px", marginRight:"5px"}} />PHONE: 0336 390 000<div></div>
                        <i className="tim-icons icon-pencil" style={{fontSize: "16px", marginRight:"5px"}} />EMAIL: office@stillco.ro<div></div>
                        <i className="tim-icons icon-time-alarm" style={{fontSize: "16px", marginRight:"5px"}} />HOURS: <b>Monday - Friday</b>: 08:00 - 17:00<div></div>
                        </Col>
                    </Row>
                </Container>
                
            </Hero>
            </div>
            </div>
            </>
        )
    }
}

export default Template;