import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import CreditCardInput from 'react-credit-card-input';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2';
import DatePicker from "react-datepicker";

import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  FormText,
  Label,
  Col,
  Modal
} from "reactstrap";

import Layout from '../utils/Layout';
import CheckoutOpenInvoices from "utils/CheckoutOpenInvoices";
import "react-datepicker/dist/react-datepicker.css";

class ListMessages extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loading: true,
            messages: [],
        }
    }

    componentDidMount() {
      if(getFromStorage("tokenAdmin") != null){
        try{
          fetch(apiLink + '/api/users/allChatsAdmin',{
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('tokenAdmin'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    return res.text();
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                console.log(obj);
                this.setState({messages: obj.messages});
                this.setState({loading: false});
            })
            .catch((error) => {this.props.onLogOut();
            });
        }catch(err){
        }
      }else{
      try{
        fetch(apiLink + '/api/users/allChats?idCustomer=' + getFromStorage('idStripe'), {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              console.log(obj);
              this.setState({messages: obj.messages});
              this.setState({loading: false});
          })
          .catch((error) => {
            this.props.onLogOut();
          });
      }catch(err){
      console.log(err);
      }
    }
    }
    
    closeTopic = (idDispute) => {
      fetch(apiLink + '/api/users/closeTopicAdmin?idDispute=' + idDispute, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getFromStorage('tokenAdmin'),
        },
        body: JSON.stringify(this.state)
    }).then((res) => {
        return res.text();   
    }).then((responseJson) => {
        var obj = JSON.parse(responseJson);
        this.componentDidMount();
    }).catch((error) => {

    });
    }


    render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    let template = [];
    if(this.state.messages && this.state.messages.length > 0){
        template = this.state.messages.map(function (item, i) {
            return (
                     <>
                     <tr>
                        <td>{item.idDispute}</td>
                        <td>{item.reason}</td>
                        <td>{item.name}</td>
                        <td>{item.company}</td>
                        <td>{(item.status == false) ? ("open"):("closed")}</td>
                        {getFromStorage("tokenAdmin") != null ? (
                          //{'/admin/chat?idDispute='+ item.idDispute
                        <>
                        <td><Button className="btn-sm" style={{color: 'red'}} onClick={() => this.closeTopic(item.idDispute)}>{language.closetopic}</Button></td>
                        </>) 
                        : (<></>)}
                        
                        <td>
                        <a href={'/admin/chat?idDispute='+ item.idDispute}><Button className="btn-sm" color="info" type="submit">
                            {language.openchat}
                            </Button></a>
                        </td>
                     </tr>
                     </>
                 )
        }.bind(this))
    }



    return(
        
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
    <h3 className="title">{language.mymessages}</h3>
                </CardHeader>
                <CardBody>
                    
                    <br></br>
                {
                    (!this.state.loading) ?
                      (                                    
                        <Row>
                        <Col>  
                            <Table className="tablesorter" >
                                <thead className="text-primary">
                                    <tr>
                                      <th>{language.iddisputa}</th>
                                      <th>{language.disputetype}</th>
                                      <th>{language.cust}</th>
                                      <th>{language.companie}</th>
                      <th>{language.statusuri}</th>
                                      {getFromStorage("tokenAdmin") != null ? (<><th>{language.actions}</th></>) : (<></>)}
                                        <th>{language.actions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {template}
                                </tbody>
                            </Table> 
                        </Col>
                    </Row>
                      ):(
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Row>
                      )
                  }
                    
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
        )
    }

}

export default ListMessages;