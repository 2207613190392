import React from "react";
import { Card, CardHeader, CardBody, Row, Col,Button, CardFooter, Modal, Input } from "reactstrap";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';

import ClipLoader from "react-spinners/ClipLoader";

class ForgotPassword extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            modalPass: false,
            loading: false,
            fieldError: false,
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value})
      }

    toggleModalPass = () => {
        this.setState({
          modalPass: !this.state.modalPass
        });
    };

    getTranslate = (value) =>{
      let language1 = null
      if(getFromStorage('language') !== null){
        language1 = require(`../utils/language_`+getFromStorage('language')); 
      }else{
        language1 = require(`../utils/language_gb`); 
      }
      let language = language1.language;
      return language[value];
    }
    
    componentDidMount(){
    }

    sendEmail(){
      //console.log(this.state);
        try{
            fetch(apiLink + '/api/login/forgotPass', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({email: this.state.email})
              }).then((res) => {
                if(res.status == 200){
                  this.props.notify("tc", this.getTranslate("successSendEmailMessage"));
                  this.toggleModalPass();
                  return res.text(); 
                } else {
                  this.props.notify("tc", this.getTranslate("errorResponce"));
                }  
              }).catch((error) => {
                    this.props.notify("tc", this.getTranslate("errorResponce"));
                    this.setState({loading: true});
            });
          } catch(err){
            this.props.notify("tc", this.getTranslate("errorResponce"));
            this.setState({loading: true});
          }
        }

  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    return (
        <>
            <div className="buttonText" style={{display: "inline", float: "center", marginTop: "15px", marginLeft: "15px"}} onClick={() => {this.toggleModalPass()}}>{language.forgot}</div>
            <Modal
                modalClassName="modal-pass"
                isOpen={this.state.modalPass}
                toggle={this.toggleModalPass}
                
                >
                <div className="modal-header">
                        <h3 style={{color: "#000"}}>{language.accountRecovery}</h3>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={this.toggleModalPass}
                    >
                    <i className="tim-icons icon-simple-remove" />
                    </button>
                </div>
                <div className="modal-body" style={{color:"#000 !important"}}>
                    <Row>
                        <Col>
                            
                            <label>{language.sendEmail}</label>
                        <Input
                            name="email"
                            type="email"
                            placeholder="Email"
                            style={{color: "black"}}
                            onChange={this.handleChange}
                        />
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer" style={{color:"#000 !important"}}>
                    { !this.state.loading ? 
                            <Row><Col><Button onClick={() => this.sendEmail()}>{language.sendEmailButton}</Button></Col></Row> : 
                            <Row><Col><ClipLoader size={43} color={"#F79434"} loading={this.state.loading}/></Col></Row>
                    }
                </div>
            </Modal>
        </>
    );
  
  }
}

export default ForgotPassword;