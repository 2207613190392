import React from "react";
import { Card, CardHeader, CardBody, Row, Col,Button,CardText, CardFooter, Modal } from "reactstrap";
import { getFromStorage } from '../utils/storage';
import { apiLink, language } from '../utils/constants';
import AccountVerified from "./AccountVerified.js";
import ClipLoader from "react-spinners/ClipLoader";
import FA from 'react-fontawesome';

class ListProducts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            products: [],
            plans: [],
            modalPass: false,
            loading: true,
            meta: ""
        }
    }

    toggleModalPass = (inde) => {
      this.setState({
        modalPass: !this.state.modalPass,
        meta: inde ? this.state.plans[inde].metadata.descriere: ""
      });
    };

    componentDidMount(){
      try{
          fetch(apiLink + '/api/produse/getPlans', {
                      method: 'GET',
                      headers: {
                          'Accept': 'application/json',
                          'Authorization': getFromStorage('token'),
                          'Content-Type': 'application/json',
                      }
                  }).then((res) => {
                      return res.text();   
                  }
              ).then((responseJson) => {
                  var obj = JSON.parse(responseJson);
                  if(obj.status === 200){
                      //console.log(obj.data);
                      this.setState({plans: obj.data.data, loading: false});
                  }
              })
          .catch((error) => {
            this.props.onLogOut();
          });
      }catch(err){
          //
      }
    }
  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;

    // let template = []
    // if(this.state.products)
    //   template = this.state.products.map(function(item, i) {
    //      return(
    //          <Col
    //              className="font-icon-list col-xs-6 col-xs-6"
    //              lg="2"
    //              md="3"
    //              sm="4"
    //          >
    //              <div className="font-icon-detail">
    //                  <h4>{item.name}</h4>
    //                  <p>{item.description}</p>
    //                  <a href={"/admin/newsubs?idProduct="+item.id} ><Button color="primary">Citeste</Button></a>
    //                </div>
    //          </Col>
    //      )
    //  })
     let templatePlans = []
     console.log(this.state.plans)
        for(let i = 0;i<this.state.plans.length;i++){
          // let item = this.state.plans[i];
          // if(this.state.products){
          //   let prod = this.state.products.filter(function(pro) {
          //     //console.log(pro.id===item.product)
          //     if(pro.id === item.product)
          //     return pro;
          //   })
          //   templatePlans.push(
          //       <Col
          //           className="font-icon-list col-xs-6 col-xs-6"
          //           lg="2"
          //           md="3"
          //           sm="4"
          //           key={i}
          //       >
          //           <div className="font-icon-detail">
          //               <h4>{language.plan} {item.nickname}</h4>
          //               <p>{prod.description}</p>
          //               <h4>{item.amount/100} €/{item.interval}</h4>
          //               <a href={"/admin/newsubs?idPlan="+item.id} ><Button color="primary">{language.subscribe}</Button></a>
          //             </div>
          //       </Col>
          //   )
          // }
          if(this.state.plans[i].plans.data.length > 0){
            let prod = this.state.plans[i].plans.data;
            console.log(this.state.plans[i].plans.data)
            let priceTemplate = []
            for(let j = 0;j<prod.length;j++){
              priceTemplate.push(
                <a href={"/admin/newsubs?idPlan="+prod[j].id} ><Button color="primary">{prod[j].nickname} {prod[j].amount/100} €/{prod[j].interval === 'year' ? language.year : prod[j].interval === 'month' ? language.month : ''  }<p style={{fontSize: "12px"}}>{prod[j].amount/100 + (prod[j].amount/100)*20/100}€/{prod[j].interval === 'year' ? language.year : prod[j].interval === 'month' ? language.month : ''  } {language.priceVAT}</p></Button></a>)
            }
            templatePlans.push(<Col md="4" sm="12" xs="12"><Card className="card-user">
            <CardBody>
              <CardText />
              <div className="author">
                <div className="block block-one" />
                <div className="block block-two" />
                <div className="block block-three" />
                <div className="block block-four" />
                  <img
                    alt="..."
                    className="avatar"
                    src={this.state.plans[i].images[0]}
                  />
                  <h3 className="title">{this.state.plans[i].name}</h3>
              </div>
              <div className="card-description" style={{textAlign: "center", fontSize: "16px"}}>
                {this.state.plans[i].description?.substring(0, 125)} 
              </div>
          <div className="card-description" style={{textAlign: "center"}}><div onClick={() => this.toggleModalPass(i)}><FA name="info-circle" size="1x" /> {language.moredetails}</div></div>
            </CardBody>
            <CardFooter>
                <div className="button-container">
              {priceTemplate}</div>
            </CardFooter>
          </Card></Col>)
          }
      }
    //let template = [(<div></div>)];
    return (
      <>
        <div className="content">
          <AccountVerified  notify={this.props.notify}/>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h3 className="title">{language.listaservicii}</h3>
                </CardHeader>
                <CardBody className="all-icons">
                  <Row>
                    <Col>
                   {
                       (!this.state.loading) ?
                        (<Row>{templatePlans}</Row>
                            ):(
                                <Col style={{textAlign: 'center'}}>
                                <ClipLoader
                                size={100}
                                color={"#F79434"}
                                loading={this.state.loading}
                                />
                            </Col>
                        )
                    }</Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
                    modalClassName="modal-pass"
                    isOpen={this.state.modalPass}
                    toggle={() => this.toggleModalPass(null)}
                    
                    >
                    <div className="modal-header">
                        <h3 style={{color: "#000"}}>{language.info}</h3>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModalPass(null)}
                        >
                        <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body" style={{color:"#000 !important"}}>
                        <Row>
                            <Col>
                              <p>
                                {this.state.meta}
                              </p>
                            </Col>
                        </Row>
                    </div>
                </Modal>
        </div>
      </>
    );
  }
}

export default ListProducts;
