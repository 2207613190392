export const language = { 
    newproduct: "Ajouter le produit",
    producttitle: "Titre du produit",
    price: "Prix",
    producttype: "Type de produit",
    description: "Description",
    product: "Produit",
    subscription: "Abonnement",
    title: "Titre",
    yourdescription: "Voici votre description",
    save: "Sauvegarder",
    products: "Produits",
    upload : "Télécharger",
    file: "Fichier",
    uploadfile: "Il s’agit d’un texte d’aide au niveau de bloc place pour l’entrée ci-dessus. C’est un peu plus léger et s’enroule facilement vers une nouvelle ligne.",
    uploadbutton: "TÉLÉCHARGER",
    mypaymentmethods: "Mes méthodes de paiement",
    paymentmethod: "MÉTHODES DE PAIEMENT",
    cardtype: "TYPE DE CARTE",
    number: "NOMBRE",
    expirydate: "DATE D’EXPIRATION",
    addpaymentmethod: "Ajouter le mode de paiement",
    newpaymentmethod: "Nouveau mode de paiement",
    country: "Pays",
    selectcountry: "Pays choisi",
    region: "Région",
    address: "Adresse",
    phone: "Téléphone",
    carddetails: "Détails de la carte",
    dashboard: "Bord",
    myprofile: "Mon profil",
    abonamentelemele: "Mes abonnements",
    facturilemele: "Mes factures",
    listaproduse: "Liste de produits",
    listaabonamente: "Liste d’abonnements",
    tvanumbervalid: "Numéro TVA valide",
    emailalreadyused: "E-mail déjà utilisé",
    addedproduct: "Produit ajouté au panier",
    tvanumberinvalid: "Numéro de TVA non valide",
    cardnumber: "Numéro de carte",
    addcard: "Ajouter la carte",
    username: "Nom d'utilisateur",
    password: "Mot de passe",
    login: "Connectez",
    noaccount: "Je n’ai pas de compte",
    registerhere: "Inscrivez-vous ici",
    name: "Nom",
    email: "Email",
    confirmpassword: "Confirmer le mot de passe",
    register: "Registre",
    back: "Retour à la connexion",
    updateprofile: "Profil de mise à jour",
    nume: "Nom",
    prenume: "Prénume",
    taxid: "ID Fiscal",
    update: "Mettre à jour",
    mytaxids: "mon id de taxe",
    taxcountry: "PAYS",
    value: "VALEUR",
    verificationstatus: "STATUT DE VÉRIFICATION",
    verificationaddress:"ADRESSE DE VÉRIFICATION",
    verificationname: "NOM DE VÉRIFICATION",
    subscriptionid: "Abonnements",
    subscriptionplan: "PLAN D’ABONNEMENT",
    startingdate: "DATE DE DÉBUT",
    nextchargedate: "PROCHAINE DATE DE CHARGE",
    priceid: "PRIX",
    status: "STATUT",
    actions: "ACTIONS",
    invoices: "Factures",
    amount: "MONTANT",
    currency: "MONNAIE",
    invoicenumber: "NUMÉRO DE FACTURE",
    createdat: "CRÉÉ À",
    dueto: "EN RAISON DE",
    creeazaabonament: "Créer un abonnement",
    pricing: "Prix",
    productsubscription: "PRODUIT",
    qty: "Quantité",
    total: "TOTAL",
    acceptsubscription: "Accepter l’abonnement",
    nopassword: "Votre compte n’a pas de mot de passe...",
    addpassword: "Ajouter un mot de passe",
    addnewpassword: "Ajouter un nouveau mot de passe",
    newpassword: "Nouveau mot de passe",
    addproduct: "Produit ajouté avec succès",
    problemconnection: "Problèmes de connexion du serveur",
    shorttitle: "Titre trop court",
    balance: "Ballance",
    totalbalance: "Total",
    delete: "Supprimer",
    amountcart: "Montant",
    gotocheckout: "Aller à la caisse",
    changepassword: "Modifier le mot de passe",
    checkout: "Caisse",
    selectpaymentmet: "Sélectionner la méthode de paiement",
    newcard: "Nouvelle carte",
    totalabonamente: "Abonnement total",
    totalfacturi: "Total des factures",
    smsramase: "SMS à gauche",
    totalshipment: "Total des expéditions",
    paymentthismounth: "Paiements ce mois-ci",
    payments: "Paiements",
    productaddedtocart: "Produit ajouté au panier",
    addtocart: "Ajouter au panier",
    summary: "Résumé",
    billedto: "Facturé à",
    invoiceno: "Numéro de facture",
    billingmethod: "Méthode de facturation",
    phonenumber: "Numéro de téléphone",
    currencyinvoices: "Monnaie",
    unitprice: "Prix unitaire",
    subtotal: "Sous-total",
    vat: "Tva",
    downloadaspdf: "Télécharger en PDF",
    viewinvoice : "Afficher la facture",
    plan: "Plan",
    subscribe: "S'inscrire",
    listaservicii : "Liste des services",
    cancelplan: "Annuler le plan",
    payment: "Paiement",
    card: "Carte",
    paymentsucces: "Paiement réussi",
    items: "Articles",
    companie: "L' entreprise",
    addTax:"Insérer ID fiscale",
    deleteTax:"Supprimer",
    continue: "Continue",
    cupon:"Ajouter un coupon",
    successCustomerUpdate: "Les informations client ont été enregistrées!",
    errorResponce: "Une erreur s'est produite!",
    successPasswordUpdate: "Le mot de passe a été mis à jour!",
    successTaxIdAdd: "Ajout d’un ID fiscal au client",
    successPMAdd: "Le mode de paiement est ajouté!",
    forgot: "Passe oubliées?",
    accountRecovery: "Récupération de compte",
    sendEmail: "Récupérez votre compte, envoyez-nous votre e-mail.",
    sendEmailButton: "Envoyer",
    successSendEmailMessage: "Vérifiez votre e-mail pour récupérer votre compte!",
    info: "Détails",
    package: "Paquet SMS",
    continut2: "Ce forfait comprend 200 massages texte",
    continut1: "Ce forfait comprend 1000 massages texte",
    continut5: "Ce forfait comprend 5000 massages texte",
    orderSMS: "Commande",
    refund: "Remboursement",
    company: "Société",
    postal: "Code postal",
    suport: "Soutien",
    motiv:"Raison",
    refund: "Remboursement",
    cerereSuport: "Demander de l’aide",
    descriere: "Description",
    textDescriere: "Voici peut-être votre description",
    send: "Envoyer",
    setari: "Paramètres",
    subscriptionSetting: "Paramètres d’abonnement",
    ipaddress: "Adresse IP",
    timeSMS: "Sms Temps d’envoi",
    print: "Imprimez-le!",
    processing: "Traitement...",
    cerereInregsitrata: "Demande de support enregistré",
    erroroccurred: "Une erreur s’est produite",
    setarisalvate: "Paramètres enregistrés avec succès",
    emailused: "E-mail déjà utilisé",
    productadded: "Produit ajouté au panier",
    tvanumbervalid: "Numéro de TVA valide",

    dashboard: "Bord",
    myprofile: "Mon profil",
    abonamentelemele: "Mes abonnements",
    facturilemele: "Mes factures",
    listaproduse: "Liste de produits",
    listaabonamente: "Liste d’abonnements",
    tvanumbervalid: "Numéro TVA valide",
    emailalreadyused: "E-mail déjà utilisé",
    addedproduct: "Produit ajouté au panier",
    tvanumberinvalid: "Numéro de TVA non valide",
    payinvoice: "Payer la facture",
    viewcreditnote: "Afficher la note de crédit",
    pay: "Payer",
    cod: "Code",
    adauga: "Ajouter",
    moredetails: "Plus de détails",
    canceled: "Annulé(Actif jusqu’à ",
    profileincomplete: "Votre profil n’est pas terminé",
    gotoprofile: "Aller au profil",
    listinvoices: "Liste des factures",
    factura: "Facture ",
    profil: "Profil",
    logout: "Déconnectez-vous",
    passnomatch: "Les mots de passe ne correspondent pas",
    balancesms: "Acheter SMS",
    historysms: "Historique des SMS",
    errorpass: "L’erreur ne correspond pas",
    validemail: "Utiliser un e-mail valide",
    unpaidinvoice: "Facture impayée N° ",
    welcome: "Bienvenue à Still-Co",
    descrierestillco: "NOUS SOMMES UNE SOCIÉTÉ DE DÉVELOPPEMENT DE LOGICIELS.",
    getstarted: "COMMENCER",
    verificationcode: "Entrez votre code de vérification",
    codenumber: "Entrez le code que nous avons envoyé à (+40) XXX-XXX-",
    access: " pour accéder à votre compte.",
    resend: "Renvoyer le codecode",
    createdbefore: "Créé avant:",
    createdafter: "Créé après:",
    voidnumber: "Numéro de remboursement",
    chatsupport: "Bavarder",
    mymessages: "Mes messages",
    iddisputa: "Litige d’identification",
    disputetype: "Type de différend",
    openchat: "Ouvrir le chat",
    chatmessage: "Tapez votre message ... ",
    cart: "Panier",
    notification: "Notifications",
    messages: "Messages",
    disputa: "Différend",
    getproduct: "Acheter maintenant",
    viewcustomer: "Afficher le client",
    cust: "Client",
    statusuri: "Statut",
    closetopic: "Fermer le sujet",
    with: "avec",
    from: "de",
    customers: "Clients",
    customername: "Nom du client",
    actiuni: "Actions",
    idcustomer: "Id client",
    data: "Date",
    amount: "Montant",
    priceVAT: "TTC",
    year: "an",
    month: "mois",

    succesfulSmsTitle: "SMS envoyé avec succès",
    totalSmsTitle: "SMS demandé",
    remainingSmsTitle: "SMS restants",
    loadHistoryButton: "Charger des données",
    historyChartTitle: "Historique des SMS",
    historyDetailsTitle: "Détails du SMS",
    deliveredCategory: "livré",
    expiredCategory: "expiré",
    rejectedCategory: "décliner",
    otherCategory: "autre",
    confirmedCategory: "confirmé",
    unconfirmedCategory: "non confirmé",
    totalCategory: "envoyé",
    locale: 'fr-FR',
    messageStatusHeader: 'Condition',
    messageNumberHeader: 'Envoyé au',
    messageSubmitHeader: 'Envoyé à l\'heure',
    messageConfirmHeader: 'confirmé à l\'heure',
    fromDateText: "Partir de la date",
    toDateText: "Jusqu'à ce date",
    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
    dashboardLoadButton: "Charger les données",

    withvat: "Prix avec TVA en sus",
    consumptionInfo: (rate, date) => `Avec un rythme moyen de ${rate}/jour vous devriez envisager de recharger par ${date}`,
    successAvatarUpdate: "La photo de profil actualisé avec succès",
    changeProfile: "Replacer la photo de profil",
    saveProfile: "Actualiser la photo de profil",
    cancelProfile: "Annuler les modifications",

    invoiceData: "Facturé",
    paymentData: "Payé",
    refundData: "Remboursé",
}