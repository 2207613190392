import React from "react";
import { useState } from "react";
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import axios from "axios";

import { apiLink } from './constants';

import { getFromStorage, setInStorage, resetStorage } from './storage';
import {Row, Button, Col} from 'reactstrap';

const CheckoutPlan = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const getTranslate = (value) =>{
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    return language[value];
  } 

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    if(props.customer.nameValid && props.customer.emailValid && props.customer.email.length > 5){

      setProcessingTo(true);
      
      const cardElement = elements.getElement("card");
      //console.log("ASDDSSAS")

      const getTranslate = (value) =>{
        let language1 = require(`./language_`+getFromStorage('language')); 
        let language = language1.language;
        return language[value];
      }
      
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
    });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      // const { error } = await stripe.confirmCardPayment(clientSecret, {
      //   payment_method: paymentMethodReq.paymentMethod.id
      // });

      // if (error) {
      //   setCheckoutError(error.message);
      //   setProcessingTo(false);
      //   //console.log(error.message);
      //   return;
      // }
    //console.log(paymentMethodReq);
      try {

            await fetch(apiLink + '/api/users/addCustomerPage', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({...props.customer, payment: paymentMethodReq.paymentMethod.id})
            }).then((res) => {
                  return res.text();   
              }
            ).then( async (responseJson) => {
              var obj = JSON.parse(responseJson);
              //console.log(obj);
              if(obj.status === 200){
                let config = {
                  headers: {
                    'Authorization': obj.token,
                  }
                }
                let client_secret_final = null
                if(props.recurring){
                  let { data: idInvoice } = await axios.post(apiLink + '/api/users/createSubscriptionCart2',{idCustomer: obj.data.id, payment: paymentMethodReq.paymentMethod.id, idPrice: props.plan, cop: props.cop, idAccount: obj.idAccount}, config);
                  //console.log(idInvoice);
                  let { data: clientSecret } = await axios.post(apiLink + '/api/users/paymentIntentCheckoutFinal?idInvoice=' + idInvoice.latest_invoice, {});
                  client_secret_final = clientSecret;
                }else{
                  let { data: idInvoice } = await axios.post(apiLink + '/api/users/createSubscriptionCart3',{idCustomer: obj.data.id, payment: paymentMethodReq.paymentMethod.id, idPrice: props.plan, cop: props.cop, idAccount: obj.idAccount}, config);
                  console.log(idInvoice);
                  let { data: clientSecret } = await axios.post(apiLink + '/api/users/paymentIntentCheckout?idInvoice=' + idInvoice.id, {});
                  client_secret_final = clientSecret;
                }
                 //console.log(clientSecret)
                // //const { data: clientSecret } = await axios.post(apiLink + '/api/users/paymentIntentCheckout?idInvoice=' + idInvoice, { });
                 const { error } = await stripe.confirmCardPayment(client_secret_final, {
                   payment_method: paymentMethodReq.paymentMethod.id
                 });

                if (error) {
                  setCheckoutError(error.message);
                  setProcessingTo(false);
                  //console.log(error);
                  //return;
                }
                ////console.log(obj);
                resetStorage();
                setInStorage('token', obj.token);
                setInStorage('idAccount', obj.idAccount);
                setInStorage('idStripe', obj.idStripe);
                setInStorage('isNotSet', !obj.isSet);
                window.location.href = "/";
              }else{
                setProcessingTo(false);
                props.notify("tc", getTranslate("emailused"));
              }
            })
          .catch((error) => {
            this.props.onLogOut();
          });

        // 

        
      } catch (err) {
        //console.log(err);
      }finally {
        
      }
    }else{
      props.notify("tc");
      //console.log("nope");
    }
  };
  
    return(
      
      <form onSubmit={handleFormSubmit}>
            <CardElement
              className="form-control"
              hidePostalCode={true}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#000',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
              onChange={handleCardDetailsChange}
            /><Button disabled={isProcessing || !stripe} className="btn-fill" color="primary" type="submit">
                    {isProcessing ? "Procesare..." : `Plateste`}
                  </Button>
        </form>
    )
  
}
export default CheckoutPlan;
