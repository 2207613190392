import React, {Component} from 'react';
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';

import {Button} from 'reactstrap';
import { getFromStorage} from '../utils/storage';


class _CardForm extends Component {
  state = {
    errorMessage: '',
  };

  handleChange = ({error}) => {
    if (error) {
      this.setState({errorMessage: error.message});
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {
      this.props.stripe.createToken().then(this.props.handleResult);
    } else {
      //console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    return (
      <div className="CardDemo">
        <form onSubmit={this.handleSubmit.bind(this)}>
            <CardElement
              className="form-control"
              hidePostalCode
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#fff',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
              onChange={this.handleChange}
            />
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div><Button className="btn-fill" color="primary" type="submit">
                    {language.pay}
                  </Button>
        </form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class CardDemo extends Component {
  render() {
    return (
      <StripeProvider apiKey={this.props.stripePublicKey}>
        <Elements>
          <CardForm  handleResult={this.props.handleResult}/>
        </Elements>
      </StripeProvider>
    );
  }
}