import React from "react";
import { Card, CardHeader, CardBody, Row, Col,Button, CardImg, CardFooter } from "reactstrap";
import { getFromStorage } from '../utils/storage';
import { apiLink, language } from '../utils/constants';
import AccountVerified from "./AccountVerified.js";
import ClipLoader from "react-spinners/ClipLoader";
import NotificationAlert from "react-notification-alert";

class GetProducts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            products: [],
            loading: true,
            price: {},
            thumbnails: {},
        };
        this.stateAddToCart = {
          id_product: '',
          id_customer: '',
          loading: true
        }
    }
    notify = place => {
      var color = Math.floor(Math.random() * 5 + 1);
      var type;
      switch (color) {
        case 1:
          type = "primary";
          break;
        case 2:
          type = "success";
          break;
        case 3:
          type = "danger";
          break;
        case 4:
          type = "warning";
          break;
        case 5:
          type = "info";
          break;
        default:
          break;
      }
      var options = {};
      options = {
        place: place,
        message: (
          <div>
            <div>
        <b>{language.productaddedtocart}</b>
            </div>
          </div>
        ),
        type: type,
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7
      };
      this.refs.notificationAlert.notificationAlert(options);
    };

   

    componentDidMount(){
      try{
                fetch(apiLink + '/api/produse/getProduseFaraStripeId', {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();   
              }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    if(obj.status === 200){
                        this.setState({productsFromDb: obj.data, loading: false}, () => this.setThumbnails());
                        console.log(this.state.productsFromDb);
                    }
                })
              .catch((error) => {
                this.props.onLogOut();
              });

          fetch(apiLink + '/api/produse/getProduse', {
                      method: 'GET',
                      headers: {
                          'Accept': 'application/json',
                          'Authorization': getFromStorage('token'),
                          'Content-Type': 'application/json',
                      }
                  }).then((res) => {
                      return res.text();   
                  }
              ).then((responseJson) => {
                  var obj = JSON.parse(responseJson);
                  //console.log(obj.data);
                  if(obj.status === 200){
                      this.setState({products: obj.data.data, loading: false} );
                      console.log(this.state.products);
                  }
              })
          .catch((error) => {
              this.props.onLogOut();
          });

        fetch(apiLink + '/api/produse/getPrices', {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();   
              }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                console.log(obj);
                if(obj.status === 200){
                    this.setState({price: obj.prices.data, loading: false});
                }
            })
        .catch((error) => {
          this.props.onLogOut();
        });
    }catch(err){
        //
    }
    
    }

    getTranslate = (value) =>{
      let language1 = null
      if(getFromStorage('language') !== null){
        language1 = require(`../utils/language_`+getFromStorage('language')); 
      }else{
        language1 = require(`../utils/language_gb`); 
      }
      let language = language1.language;
      return language[value];
    }

    
    syncProducts = () =>{
      console.log('called');
      try{
        fetch(apiLink + '/api/produse/addProductsFromStripeToDatabase', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getFromStorage("tokenAdmin")
          },
          body: JSON.stringify({"prods" : this.state.products})
        }).then((res) => { 
          console.log(res);

          }).catch((error) => {
            
          });
      }catch(err){

      }
    }


    addToCart(val){

      this.props.addToCart();   
      this.stateAddToCart.id_product = val;
      this.stateAddToCart.id_customer = getFromStorage('idAccount');
      //console.log(this.stateAddToCart);
    
      // this.state.id_customer = getFromStorage('idCustomer');
      try{
        fetch(apiLink + '/api/cart/addProdusToCart', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getFromStorage("token")
          },
          body: JSON.stringify(this.stateAddToCart)
        }).then((res) => { 
          this.props.notify("tc", this.getTranslate("productadded"))
          return res.text();

          }).catch((error) => {
            this.setState({notificationError: true, loading: false})
          });
      }catch(err){

      }
    }

  setThumbnails = () => {
    let tmb = {};
    this.state.productsFromDb.forEach( (prod) => {
      try{
        if( !prod.idStripe )
          tmb[ prod.idProduct ] = require(`assets/img/${prod.img}`);
      }catch(err){
        console.log(`Unable to load ${prod.idProduct}: ${prod.img}`);
      }
    } );

    this.setState({thumbnails: tmb});
  }

  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    let defaultThumbnail = require(`assets/img/apple-icon.png`);

     let template = []
        for(let i = 0;i<this.state.products.length;i++){
            let item = this.state.products[i];
            let priceTemplate = [];
            let priceLayout=[]
            if(this.state.price.length > 0) {
              for(let j = 0; j< this.state.price.length; j++){
                let price = this.state.price[j];
                if(this.state.products[i].id === price.product){
                  // console.log(price.unit_amount);
                  this.state.products[i].price = price.unit_amount;

                  priceTemplate.push(
                  <Button color="primary" size="sm" onClick ={() => this.addToCart(price.id)}>{language.getproduct}</Button>)
                  // priceLayout.push(<Card style={{backgroundColor: "#F79434", color: "white", width: "fit-content", fontWeight: "bold", textAlign: "center", fontSize: "16px", marginLeft: "10px"}}>{price.unit_amount/100} € <p style={{fontSize: "12px", color: "#6c757d",  fontWeight: "bold"}}>{price.unit_amount/100 + (price.unit_amount/100)*20/100}€ + TVA</p></Card>)
                  priceLayout.push(<div  style={{color: "red", fontWeight: "bold", fontSize: "16px"}}>{price.unit_amount/100} € <p style={{fontSize: "12px", color: "#6c757d",  fontWeight: "bold"}}>{price.unit_amount/100 + (price.unit_amount/100)*20/100}€ {language.priceVAT}</p></div>)
                } 
              }
            }
            if(priceTemplate.length > 0)
            template.push(
                  <Col xs="3" style={{paddingLeft: "0px", paddingRight: "0px"}}>
              <Card style={{ width: '18rem',textAlign: "center", marginLeft: "10px", background:"#132040", border: "solid", borderColor: "#fff"}}>
                <CardHeader style={{minHeight: "7em"}}>
                  <div className="card-title" style={{fontSize: "20px", fontWeight: "bold"}}>{item.name}</div>
                </CardHeader>
                <CardBody>
                  <div className="image-layout" style={{backgroundImage: `url(${item.images[0]})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center", textAlign: "left", width: "260px", height: "300px"}}></div>
                  
                </CardBody>                
                
                <CardFooter style={{backgroundColor: "white", textAlign: "left", minHeight: "10em"}}>
                  <Row>
                    <Col> 
                    <div className="card-text">{item.description}</div>
                    <Row>
                      <Col>
                        {priceTemplate}
                      </Col>
                      <Col>
                        {priceLayout}
                      </Col>
                    </Row>
                    </Col>
                  </Row>
                </CardFooter>
                
              </Card>
              </Col>
            )
        }
      let template2 = [];
      for(let j =0; j< this.state.productsFromDb?.length; j++){
        let prod = this.state.productsFromDb[j];
        if(prod.idStripe == null){
          console.log(prod);
          template2.push(
              <Col xs="3" style={{paddingLeft: "0px", paddingRight: "0px"}}>
          <Card style={{ width: '18rem',textAlign: "center", marginLeft: "10px", background:"#132040", border: "solid", borderColor: "#fff"}}>
            <CardHeader style={{minHeight: "7em"}}>
              <div className="card-title" style={{fontSize: "20px", fontWeight: "bold"}}>{prod.titlu}</div>
            </CardHeader>
            <CardBody>
              <div className="image-layout" style={{backgroundImage: `url(${ this.state.thumbnails[prod.idProduct] ?? defaultThumbnail })`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center", textAlign: "left", width: "260px", height: "300px"}}></div>
            </CardBody>

            <CardFooter style={{backgroundColor: "white", textAlign: "left", minHeight: "10em"}}>
              <Row>
                <Col> 
                <div className="card-text">{prod.description}</div>
                <Row>
                  <Col>
                    <Button color="primary" size="sm" disabled="true">Buy now</Button>
                  </Col>
                  <Col>
                  <div  style={{color: "red", fontWeight: "bold", fontSize: "16px"}}>{prod.pret/100} € <p style={{fontSize: "12px", color: "#6c757d",  fontWeight: "bold"}}>{prod.pret/100 + (prod.pret/100)*20/100}€ {language.priceVAT}</p></div>
                  </Col>
                </Row>
                </Col>
              </Row>
            </CardFooter>
            
          </Card>
          </Col>
        )
        }
      }
    //let template = [(<div></div>)];
    return (
      <>
        <div className="content">
          <AccountVerified  notify={this.props.notify}/>
          <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <div>{getFromStorage("tokenAdmin") ? (<Button color="primary" onClick = {() => {this.syncProducts()}}>Sync products</Button>) : (<></>)}</div>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
    <h3 className="title">{language.products}</h3>
                </CardHeader>
                <CardBody className="all-icons">
                  <Row>
                   {
                       (!this.state.loading) ?
                        (<>{template}{template2}</>
                            ):(
                                <Col style={{textAlign: 'center'}}>
                                <ClipLoader
                                size={100}
                                color={"#F79434"}
                                loading={this.state.loading}
                                />
                            </Col>
                        )
                    }
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default GetProducts;
