import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";

import 'react-phone-input-2/lib/bootstrap.css';

import Main from 'Main';
import DoctorPlan from "produse/DoctorPlan";
import Template from "produse/Template";
import CheckoutTemplate from "produse/CheckoutTemplate";
import SuccessTemplate from "produse/SuccessTemplate";
import ResetPassword from "./layouts/ResetPassword";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      
      <Route exact strict path="/produse" render={props => <Template {...props} />} />
      <Route exact strict path="/checkoutplan" render={props => <CheckoutTemplate {...props} />} />
      <Route exact strict path="/success" render={props => <SuccessTemplate {...props} />} />
      <Route exact strict path="/resetPassword" render={props => <ResetPassword {...props} />} />
      <Route path="/" render={props => <Main {...props} />} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
