import React from "react";
import {Container, Row, Col, FormGroup, Input, FormFeedback} from 'reactstrap';
import 'assets/css/login_style.css';
import logo from "assets/img/logo.png";
import ClipLoader from "react-spinners/ClipLoader";
import PhoneInput from 'react-phone-input-2';
import ReactFlagsSelect from 'react-flags-select';
import { setInStorage, getFromStorage,resetStorage } from 'utils/storage';

import { apiLink } from 'utils/constants';
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    
    this.state ={
        mail:"",
        password: "",
        confirmPass: "",
        showErr1: false,
        showErr2: false,
        errMsg1: '',
        c: '',
        loading: true
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name] : event.target.value, showErr2: false, showErr1: false})
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let c = params.get('c');
      fetch(apiLink + '/api/users/getresetCode?code=' + c, {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();   
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              //console.log(obj.data);
              this.setState({mail: obj.data[0].mail, c: c, loading:false})
          })
        .catch((error) => {
            //
        });
  }

  onSubmit = () => {
    this.setState({loading:true})
    if(this.state.password.length < 7){
      this.setState({errMsg1: "Password is too short (minimum 7 characters)", showErr1: true, loading:false})
    }
    if(this.state.password != this.state.confirmPass){
      this.setState({errMsg1: "Passwords are different", showErr2: true, loading:false})
    }
      fetch(apiLink + '/api/users/resetPassword', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': getFromStorage('token'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: this.state.password,
          passConfirm: this.state.confirmPass,
          code: this.state.c
        })
          }).then((res) => {
            if(res.status === 200){
              this.setState({loading:false});
              window.location.href = "/admin";
            }
          }
        ).then((responseJson) => {
          var obj = JSON.parse(responseJson);
        })
        .catch((error) => {
          this.props.onLogOut();
        }); 
  }

  render() {
    let language1 = require(`../utils/language_gb`); 
    let language = language1.language;
    return (
        <div className="wrapper" style={{backgroundImage: 'linear-gradient(to right,#ff5722, #ff9800)'}}>
            <Container>
                <Row>
                    <Col md={{ size: 10, offset: 1 }} style={{marginTop: '20vh'}}>
                        <Row>
                            <Col md="5" className="login_left">
                                <img src={logo} ></img>
                                <h3>Welcome To Still-Co</h3>
                                <p>WE ARE A SOFTWARE DEVELOPMENT COMPANY.</p>
                                <a href="http://www.stillco.ro/#service">
                                  <button className="btn btn-primary">
                                      GET STARTED
                                  </button>
                                </a> 
                                <Row>
                                <Col md="4"></Col>
                                <Col md="2">
                                </Col>
                                <Col md="5"></Col>
                                </Row>
                            </Col>
                            <Col md="7" className="login_right">
                            <h2>Reset Password</h2> 
                                <div className="login-form">
                                  <p style={{textAlign:"center", color:"#000"}}>
                                    Reset Password for account {this.state.mail}
                                  </p>
                                  <form>
                                    <FormGroup>
                                      <Input type="password" placeholder="Password" name="password" onChange={this.handleChange} style={{color: "#000"}} invalid={this.state.showErr1}></Input>
                                      <FormFeedback>{this.state.errMsg1}</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                      <Input type="password" placeholder="Confirm Password" name="confirmPass" onChange={this.handleChange} style={{color: "#000"}} invalid={this.state.showErr2}></Input>
                                      <FormFeedback>{this.state.errMsg1}</FormFeedback>
                                    </FormGroup>
                                  </form>
                                    
                                    {
                                    (!this.state.loading) ?
                                      (
                                        <button className="btn btn-primary" onClick={this.onSubmit}>Reset</button>
                                      ):(
                                        <ClipLoader
                                          size={43}
                                          color={"#F79434"}
                                          loading={this.state.loading}
                                        />
                                      )
                                  }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
}

const styles = {
  exampleStyle: {
    backgroundColor: 'red',
  }
};

export default ResetPassword;
