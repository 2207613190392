import React from "react";
import { useState } from "react";
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import axios from "axios";

import { apiLink } from './constants';

import { getFromStorage } from './storage';
import {Row, Button, Col} from 'reactstrap';
import { language } from "./language_gb";

const BalanceCheckout = (props) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    setProcessingTo(true);

    try {

      const { data: paymentIntent } = await axios.post(apiLink + '/new/balanceCheckout?idCustomer=' + getFromStorage('idStripe')+"&idAccount="+getFromStorage("idAccount") + "&smsnumber=" + props.smsnumber, {
      });

      const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: props.payment
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        console.log(error.message);
        props.notify("tc", error.message)
        return;
      }else{
        await axios.post(apiLink + '/new/setbalance', {
          paymentId: paymentIntent.id,
          idCustomer: getFromStorage('idStripe'),
          smsnumber: props.smsnumber
        }).then(() => {
          window.location.href = "/admin/successpayment";
        });
      }
    } catch (err) {
      console.log(err);
    }finally {
    }
  };
  let language1 = null
  if(getFromStorage('language') !== null){
    language1 = require(`../utils/language_`+getFromStorage('language')); 
  }else{
    language1 = require(`../utils/language_gb`); 
  }
  let language = language1.language;
  //console.log(props);
    return(
      <form onSubmit={handleFormSubmit}>
        <Button disabled={isProcessing || !stripe || props.disabled} className="btn-fill" color="primary" type="submit">
            {isProcessing ? language.processing : language.pay}
        </Button>
      </form>
    )
  
}
export default BalanceCheckout;
