import React from 'react';
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';

import {
    Button,
    Card,
    UncontrolledAlert,
    FormFeedback,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    FormText,
    Label,
    Col,
    Modal
  } from "reactstrap";
  import ClipLoader from "react-spinners/ClipLoader";

class ListItems extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            filter: ''
        }
    }

    setFilter = (e) => {
        this.setState({filter: e.target.value})
    }

    componentDidMount() {
        fetch(apiLink + '/api/admin/getInvoiceLines', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': getFromStorage('tokenAdmin'),
                'Content-Type': 'application/json',
            }
            }).then((res) => {
                return res.text();   
            }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                console.log(obj);
                this.setState({items: obj.items, loading: false});
            })
        .catch((error) => {
            this.props.onLogOut();
        });
    }

    convertDate = (unix_timestamp) =>{
        let date = new Date(unix_timestamp * 1000);
        // Hours part from the timestamp
        let day = date.getDate();
        // Minutes part from the timestamp
        let month = date.getMonth()+1;
        // Seconds part from the timestamp
        let year = date.getFullYear();

        // Will display time in 10:30:23 format
        let formattedTime = day+ "/"+month+"/"+year;
        return formattedTime;
  }

    render() {
        let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
        
        let template = [];
         if(this.state.items && this.state.items.length > 0){
             template = this.state.items.map(function(item, i){
                return (
                    <>
                        <tr>
                            <td>{item.description}</td>
                            <td>
                            <a href={`./customerInfo?idCustomer=` + item.id}><Button className="btn-sm" color="info" type="submit">
                                {language.viewcustomer}
                                </Button></a></td>
                            <td>{this.convertDate(item.date)}</td>
                            <td>{item.price?.type}</td>
                            <td>
                                {item.amount/100} {item.currency}
                            </td>
                        </tr>
                    </>
                )
            }.bind(this))
        }
        return(<>
        <div className="content">
        
            <Row>
            <Col md="12">
            <Card>
                <CardHeader>
        <h5 className="title">{language.customers}</h5>
                </CardHeader>
                
                <CardBody>{
                    (!this.state.loading) ?
                      (                                    
                        <Row>
                        <Col>  
                            <Table className="tablesorter" >
                                <thead className="text-primary">
                                    <tr>
                      <th>{language.description}</th>
                      <th>{language.idcustomer}</th>
                      <th>{language.data}</th>
                      <th>{language.producttype}</th>
                      <th>{language.amount}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {template}
                                </tbody>
                            </Table> 
                        </Col>
                    </Row>
                      ):(
                        <Row>
                            <Col style={{textAlign: "center"}}>
                                <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                />
                            </Col>
                        </Row>
                      )
                  }
                    
                </CardBody>
                <CardFooter>
                </CardFooter>
              </Card>
            </Col>
          </Row>
                    </div>
        </>)
    }
}

export default ListItems;