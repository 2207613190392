import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import routesAdmin from "routesAdmin.js";
import {getFromStorage} from '../../utils/storage';
import { apiLink } from '../../utils/constants';

import logo from "assets/img/logo.png";

import NotificationAlert from "react-notification-alert";
var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "primary",
      countCart: 0,
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    try{
      fetch(apiLink + '/api/users/getCartCount?idAccount=' + getFromStorage('idAccount'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': getFromStorage('token'),
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                return res.text();   
            }
        ).then((responseJson) => {
            var obj = JSON.parse(responseJson);
            //console.log(obj.cartCount);
            this.setState({countCart:obj.cartCount});
        })
        .catch((error) => {
          this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  addToCart = () => {
    this.setState({ countCart: parseInt(this.state.countCart)+1})
  }
  removeFromCart = () => {
    this.setState({ countCart: parseInt(this.state.countCart)-1})
  }
  
  notify = (place,text) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route 
            path={prop.layout + prop.path}
            component={() => <prop.component addToCart={this.addToCart} removeFromCart={this.removeFromCart} onLogOut={this.props.onLogOut} notify={this.notify}/>}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getRoutesAdmin = routesAdmin => {
    if(getFromStorage("tokenAdmin") != null){
      return routesAdmin.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route 
              path={prop.layout + prop.path}
              component={() => <prop.component addToCart={this.addToCart} removeFromCart={this.removeFromCart} onLogOut={this.props.onLogOut} notify={this.notify}/>}
              key={key}
            />
          );
        } else {
          return null;
        }
      });
    }
  }
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    //console.log(this.props);
    return (
      <>
        <div className="wrapper">
          
        <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
          <Sidebar
            {...this.props}
            routes={routes}
            routesAdmin={routesAdmin}
            bgColor={this.state.backgroundColor}
            logo={{
              outterLink: "https://www.creative-tim.com/",
              text: "1",
              imgSrc: logo
            }}
            toggleSidebar={this.toggleSidebar}
          />
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <AdminNavbar
              {...this.props}
              addToCart={this.addToCart}
              removeFromCart={this.removeFromCart}
              countCart={this.state.countCart}
              updateToken={this.props.updateToken}
              updateLanguage = {this.props.updateLanguage}
              brandText={this.getBrandText(this.props.location.pathname)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
            />
            
            <Switch>
              {this.getRoutes(routes)}
              {this.getRoutesAdmin(routesAdmin)}
              <Redirect from="*" to="/admin/dashboard"/>
            </Switch>
            
            {// we don't want the Footer to be rendered on map page
            this.props.location.pathname.indexOf("maps") !== -1 ? null : (
              <Footer fluid />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Admin;
