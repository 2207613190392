import ListCustomers from "views/ListCustomers";
import ProfileInfoAdmin from "views/ProfileInfoAdmin";
import ListItems from "views/ListItems";

var routesAdmin = [
  {
    path: "/customers",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: ListCustomers,
    layout: "/admin"
  },
  {
    path: "/customerInfo",
    name: "Customers",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: ProfileInfoAdmin,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/listItems",
    name: "List Items",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: ListItems,
    layout: "/admin"
  },
];
export default routesAdmin;
