import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import GetProducts from "./GetProducts";
import NewPaymentMethod from "./NewPaymentMethod";
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";

import FA from 'react-fontawesome';

import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  FormText,
  Label,
  Col,
  Table
} from "reactstrap";
import Layout from '../utils/Layout';
import CheckoutButton from '../utils/CheckoutButton';

class CheckOutPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            items: [],
            newCard: null,
            paymentMethods: [],
            processingTo: false,
            checkoutError: '',
            showPaymentMethods: false,
            taxable: false,
            cupon: null,
            cuponcode: '',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value, fieldError: false })
    }

    componentDidMount(){        
        try{
            fetch(apiLink + '/api/cart/getCartItems/?idCustomer='+getFromStorage('idAccount'), {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('token'),
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    //console.log(obj)
                    if(obj.status === 200){
                        //console.log(obj.data);
                        this.setState({items: obj.data})
                    }
                })
                .catch((error) => {
                  this.props.onLogOut();
                });
        }catch(err){
                //
        }

        try{
            fetch(apiLink + '/api/users/gettaxable', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('token'),
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            idCustomer: getFromStorage('idStripe'),
                        })
                    }).then((res) => {
                      return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    this.state.taxable = obj.taxable;
                    //console.log(obj.taxable);
                })
            .catch((error) => {
              this.props.onLogOut();
            });
        }catch(err){
            //
        }
    }

    onSubmit = () =>{

        try{
            fetch(apiLink + '/api/users/getPaymentOptions?idCustomer=' + getFromStorage('idStripe'), {
                          method: 'GET',
                          headers: {
                              'Accept': 'application/json',
                              'Authorization': getFromStorage('token'),
                              'Content-Type': 'application/json',
                          }
                      }).then((res) => {
                          return res.text();   
                      }
                  ).then((responseJson) => {
                      var obj = JSON.parse(responseJson);
                      //console.log(obj);
                      this.setState({paymentMethods:obj.data.data, showPaymentMethods: true, newCard: null});
                      //console.log(this.state.showPaymentMethods);
                  })
              .catch((error) => {
                this.props.onLogOut();
              });
          }catch(err){
              //
          }
    }

  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    let products = [];
    //console.log(this.state.taxable);

    if(this.state.items.length > 0){
        products=this.state.items.map(function(item, i) {
            return(
                <tr key={i}>
                    <td>{item.name}</td>
                    <td>1</td>
                    <td>{item.unit_amount/100}</td>
                </tr>
            )
        })
    }
    let total=0;
    for(let i=0;i<this.state.items.length;i++){
        total +=this.state.items[i].unit_amount;
    }
    total=total/100;
    let paymentOptions = [];
    if(this.state.showPaymentMethods) {
      paymentOptions = this.state.paymentMethods.map(function(item, i){
        return(
          <FormGroup check key={i}>
            <Label check>
              <Input type="radio" name="newCard" value={item.id} onChange={this.handleChange} />{' '}
              { (item.card.brand === "visa") ? (<FA
                name="cc-visa"
                size="2x"
              />):(<></>)}{ (item.card.brand === "amex") ? (<FA
                name="cc-amex"
                size="2x"
              />):(<></>)}{ (item.card.brand === "discover") ? (<FA
                name="cc-discover"
                size="2x"
              />):(<></>)}{ (item.card.brand === "diners") ? (<FA
                name="cc-diners-club"
                size="2x"
              />):(<></>)}{ (item.card.brand === "mastercard") ? (<FA
                name="cc-mastercard"
                size="2x"
              />):(<></>)}  {item.card.brand.charAt(0).toUpperCase() + item.card.brand.slice(1)}-{item.card.last4}
            </Label>
          </FormGroup>
      )
      }.bind(this))
    }
    return (
      <>
       <div className="content">
       <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="title">{language.checkout}</h4>
                </CardHeader>
                <CardBody>
                    <Form>
                        <h4 className="title">{language.pricing}</h4>
                        <Table className="tablesorter" >
                            <thead className="text-primary">
                                <tr>
                                  <th>{language.product}</th>
                                  <th>{language.qty}</th>
                                  <th>{language.totalbalance}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products}
                                { (this.state.taxable) ? (<tr><td></td><td>VAT – France</td><td>20%</td></tr>) : <></>}
                                <tr><td></td><td>{language.totalbalance}</td><td>{(this.state.taxable) ? (total + (total*20/100)) : (total)} €</td></tr>
                            </tbody>
                        </Table>
                    </Form>
                    <Row><Col>
                  {
                    (this.state.showPaymentMethods) ? 
                    (<>
                    <label><b>{language.selectpaymentmet}</b></label>
                    
                      <FormGroup tag="fieldset">
                        {paymentOptions}
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="newCard" value="101" onChange={this.handleChange} />{' '}
                              {language.newcard}
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      { (this.state.newCard === "101") ? (<NewPaymentMethod success={this.onSubmit}></NewPaymentMethod>):(<div></div>)}
                      
                        <Layout>
                            <CheckoutButton payment={this.state.newCard} disabled={(this.state.newCard === null || this.state.newCard === undefined || this.state.newCard === "101")? true:false} notify={this.props.notify}></CheckoutButton>
                        </Layout>
                    </>):(
                    <Button className="btn-fill" color="primary" type="submit" onClick={this.onSubmit} disabled={(this.state.items.length > 0) ? false : true}>
                        {language.continue}
                    </Button>)
                  }
                  </Col></Row>
                    
                    {/* <Layout>
                      <Checkout></Checkout>
                    </Layout> */}
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
       </div>
      </>
    );
  }
}

export default CheckOutPage;
