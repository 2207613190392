import React from "react";
import { useState } from "react";
import { CardElement, useStripe, useElements,injectStripe } from "@stripe/react-stripe-js";
import axios from "axios";

import { apiLink } from './constants';

import { getFromStorage } from './storage';
import {Row, Button, Col} from 'reactstrap';

const Checkout = () => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();
  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    setProcessingTo(true);

    const cardElement = elements.getElement("card");
    //console.log("ASDDSSAS")
    try {

      const { data: idInvoice } = await axios.post(apiLink + '/api/users/createInvoiceCart?idCustomer=' +getFromStorage('idStripe')+"&idAccount="+getFromStorage("idAccount"), {
      });

      const { data: clientSecret } = await axios.post(apiLink + '/api/users/paymentIntentCheckout?idInvoice=' + idInvoice, {
      });

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id
      });

      if (error) {
        setCheckoutError(error.message);
        setProcessingTo(false);
        //console.log(error.message);
        return;
      }
      try{
        fetch(apiLink + '/api/cart/deletecart/?idCustomer='+getFromStorage('idAccount'), {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    }
                }).then((res) => {
                    return res.text();   
                }
            ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                //console.log(obj)
                if(obj.status === 200){
                    //console.log("EHEEEEEEE");
                }
            })
            .catch((error) => {
              this.props.onLogOut();
            });
        }catch(err){
            //
        }finally {
          window.location.href = "/admin/successpayment";
        }
    } catch (err) {
      //console.log(err);
    }
  };

  let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
  
    return(
      
      <form onSubmit={handleFormSubmit}>
            <CardElement
              className="form-control"
              hidePostalCode={true}
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#fff',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
              onChange={handleCardDetailsChange}
            /><Button disabled={isProcessing || !stripe} className="btn-fill" color="primary" type="submit">
                    {isProcessing ? language.processing : language.pay}
                  </Button>
        </form>
    )
  
}
export default Checkout;
