import React from 'react';

import { apiLink } from '../utils/constants';
import {
    Hero, ScrollDownIndicator
  } from 'react-landing-page'
  
import { Row, Col, Container, FormGroup, Input, FormFeedback,Button, Card, CardHeader, CardBody, Table,Collapse, Modal } from 'reactstrap';
import back from "assets/img/doctor-software-FB.png";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Layout from '../utils/Layout';
import Checkout from '../utils/CheckoutPlan';
import NotificationAlert from "react-notification-alert";
import ClipLoader from "react-spinners/ClipLoader";
import { getFromStorage, setInStorage } from 'utils/storage';
import {isInEU} from '../utils/country';

class CheckoutTemplate extends React.Component {
    constructor(props) {
        super(props);
        
        this.state ={
            phone: null,
            borderColor: "#000",
            country: "FR",
            country2: "FR", 
            vat: '',
            region: null,
            name: null,
            email: '',
            adress: null,
            nameValid: true,
            emailValid: true,
            checkForm: false,
            toggle:false,
            plan: {},
            blockCoupon: false,
            modalPass: false,
            percent_off: 0,
            couponCode: "",
            loading: false,
            vatValidation: false,
            postcode: ""
        }
    
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeVat = this.handleChangeVat.bind(this);
    }
    toggleModalPass = () => {
      this.setState({
        modalPass: !this.state.modalPass
      });
  };
    
  notify = (place,text) => {
    var color = Math.floor(Math.random() * 5 + 1);
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <b>{text}</b>
          </div>
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

    handleChange(event) {
        if(event.target.name === "name"){
            if(event.target.value.length < 4)
                this.setState({ [event.target.name] : event.target.value, nameValid: false })
            else
                this.setState({ [event.target.name] : event.target.value, nameValid: true })
        }else if(event.target.name === "email"){
            if(!this.ValidateEmail(event.target.value))
                this.setState({ [event.target.name] : event.target.value, emailValid: false })
            else
                this.setState({ [event.target.name] : event.target.value, emailValid: true })
        }
        this.setState({ [event.target.name] : event.target.value })
    }
    handleChangeVat(event) {
        this.setState({ vat : event.target.value})
    }
    getTranslate = (value) =>{
        let language1 = require(`../utils/language_`+getFromStorage('language')); 
        let language = language1.language;
        return language[value];
      }
    
    checkVat=()=>{
        try{
            fetch(apiLink + '/vat', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({vat:this.state.vat, country: this.state.country2})
              }).then((res) => {
                  //console.log(res);
                    return res.text();   
                }
              ).then((responseJson) => {
                var obj = JSON.parse(responseJson);
                if(obj.status === 200){
                    //console.log(obj);
                    if(obj.valid === "true"){
                        this.setState({vatValidation: true});
                        this.notify("tc", this.getTranslate("tvanumbervalid"));
                    }else{
                        this.notify("tc", "TVA number taxabil");
                    }
                }else{
                    this.notify("tc", "A aparut o eroare");
                }
            })
            .catch((error) => {
                  this.setState({showError: true,errorMessage: "Connection Problem", loading: true});
            });
        }catch(err){
        this.setState({showError: true,errorMessage: "Connection Problem", loading: true});
        }
    }

    getTranslate = (value) =>{ 
        let language1 = null
     if(getFromStorage('language') !== null){
       language1 = require(`../utils/language_`+getFromStorage('language')); 
     }else{
       language1 = require(`../utils/language_gb`); 
     }
     let language = language1.language;
     return language[value];
   }

    componentDidMount() {
        
        document.body.style.backgroundColor = "white";
        document.body.style.color = "black";
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let idPlan = params.get('idPlan');
        //console.log(idPlan)
          try{
            fetch(apiLink + '/api/produse/getPlanById?idProduct=' + idPlan, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    console.log(obj.data);
                    this.setState({plan: obj.data})
                })
            .catch((error) => {
                //
            });
        }catch(err){
            //
        }
    }
    checkCoupon = () => {
        try{
          fetch(apiLink + '/api/users/checkCoupon', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          cop: this.state.couponCode
                        })
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    //console.log(obj);
                    if(obj.status === 200){
                      this.setState({percent_off: obj.coupon, modalPass: !this.state.modalPass, blockCoupon: true})
                    }
                })
            .catch((error) => {
                //
            });
        }catch(err){
            //
        }
      }
    ValidateEmail = (mail) =>
    {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
        {
            return (true)
        }
            return (false)
    }
    
    
    selectCountry (val) {
        this.setState({ country: val });
    }
    selectCountry2 (val) {
        this.setState({ country2: val });
    }
    
    selectRegion (val) {
        this.setState({ region: val });
    }

    onFocusPhone = () => {
        this.setState({borderColor: "#F79434"})
    }
    onBlur = () => {
        this.setState({
            borderColor: '#000'
        })
    }


    
    notify = (place,text) => {
        var type = "danger";
        var options = {};
        options = {
          place: place,
          message: (
            <div>
              <div>
          <b>{text}</b>
              </div>
            </div>
          ),
          type: type,
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
      };

      toggle = () => {
          this.setState({toggle: !this.state.toggle})
      }

    render() {
        let toatlCharge = this.state.plan.unit_amount/100;
        if(this.state.percent_off > 0)
            toatlCharge = toatlCharge - ((toatlCharge)*this.state.percent_off)/100;
        if((isInEU(this.state.country))){
            if(!this.state.vatValidation){
                toatlCharge = toatlCharge + ((toatlCharge)*20)/100;
            }
        }
        const { country, region, country2 } = this.state;
        return(<>
        <div className="react-notification-alert-container">
            <NotificationAlert ref="notificationAlert" />
          </div>
        <div style={{background: `url(${back}) no-repeat center center fixed`, backgroundSize:"cover"}}>
            <Hero style={{backgroundColor:"rgba(255, 255, 255, 0.5)"}}>
                <Container>
                    <Row>
                        <Col>
                            <Card className="rounded">
                                <CardHeader style={{color: "#000", backgroundColor:"#fff", textAlign:"center"}}>
                                    <h1 style={{color:"#000", marginTop: "20px"}}>Comanda Abonament</h1>
                                </CardHeader>
                                <CardBody style={{color: "#000", backgroundColor:"#fff", textAlign:"center"}}>
                                    <Row>
                                        <Col md={5}>
                                            <p style={{color: "#000"}}>
                                                Acest produs este vandut pe baza de abonament(SaaS) de catre STILL-CO SARL FRANCE. 
                                                Dupa efectuarea platii veti fi redirectionat in <a href="https://portal.stillco.fr/">portalul</a> nostru unde
                                                ne veti putea contacta si da datele necesare pentru instalarea produsului.<div></div>
                                                Obs. Suportul este asigurat in limba romana.
                                            </p>
                                        <Container><Card style={{background:"#fff"}}>
                                                <CardHeader>
                                                    <h4 style={{color:"#000"}}>Informatii Obligatorii</h4>
                                                </CardHeader>
                                            <CardBody>
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Email</label>
                                            <Input type="text" placeholder="Email" name="email"  onChange={this.handleChange} style={{color: "#000"}} invalid={!this.state.emailValid}></Input>
                                            <FormFeedback invalid>Email invalid</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Tara</label>
                                            <CountryDropdown classes="form-control"
                                                value={country}
                                                valueType="short"
                                                onChange={(val) => this.selectCountry(val)} style={{color: "#000"}}
                                            />
                                        </FormGroup>
                                        </CardBody></Card>
                                        </Container>
                                        <Container>
                                            <Card style={{background:"#fff"}}>
                                                <CardHeader onClick={this.toggle}>
                                                    <h4 style={{color:"#000"}}>Informatii Optionale</h4> <div></div>{(!this.state.toggle)?(<ScrollDownIndicator style={{marginBottom: "-17px", color:"#000"}}/>):(<></>)}
                                                </CardHeader>
                                            <CardBody>
                                        <Collapse isOpen={this.state.toggle}>
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Nume</label>
                                            <Input type="text" placeholder="Nume" name="name"  onChange={this.handleChange} style={{color: "#000"}} invalid={!this.state.nameValid}></Input>
                                            <FormFeedback invalid>Nume prea scurt</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Regiune</label>
                                            <RegionDropdown classes="form-control"
                                                country={country}
                                                countryValueType="short"
                                                value={region}
                                                onChange={(val) => this.selectRegion(val)} style={{color: "#000"}}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Adresa</label>
                                            <Input type="text" placeholder="Adresa" name="adress" style={{color: "#000"}} onChange={this.handleChange}></Input>
                                            <FormFeedback></FormFeedback>
                                        </FormGroup>
                                        
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Cod postal</label>
                                            <Input type="text" placeholder="Cod postal" name="postcode" style={{color: "#000"}} onChange={this.handleChange}></Input>
                                            <FormFeedback></FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Companie</label>
                                            <Input type="text" placeholder="Companie" name="company" style={{color: "#000"}} onChange={this.handleChange}></Input>
                                            <FormFeedback></FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <label style={{color:"#000"}}>Numar TVA</label>
                                        { !this.state.loading ? 
                                        <>{(!this.state.vatValidation) ?<><Row><Col md="4">
                                        <CountryDropdown classes="form-control"
                                                value={country2}
                                                valueType="short"
                                                labelType="short"
                                                onChange={(val) => this.selectCountry2(val)} style={{color: "#000"}}
                                            /></Col><Col>
                                        <Input type="text" disabled={this.state.vatValidation} placeholder= "Cod TVA" 
                                        name="vat" style={{color: "#000"}} onChange={this.handleChangeVat}></Input></Col>
                                        </Row><Row><Col><Button disabled={this.state.vatValidation} onClick={this.checkVat}>Verifica</Button></Col></Row></>:<Row style={{textAlign: "center"}}><Col>{this.state.country2}{this.state.vat}</Col></Row>}</> 
                                        : <ClipLoader size={43} color={"#F79434"} loading={this.state.loading}/>}                                            <FormFeedback></FormFeedback>
                                        </FormGroup>
                                        
                                        </Collapse></CardBody></Card></Container>
                                        
                                        </Col>
                                        <Col md={7}>
                                            <Table style={{color:"#000"}}>
                                                <thead className="text-primary" style={{color:"#000"}}>
                                                    <tr style={{color:"#000"}}>
                                                    <th style={{color:"#000"}}>Abonament</th>
                                                    <th style={{color:"#000"}}>Cantitate</th>
                                                    <th style={{color:"#000"}}>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{color:"#000"}}>
                                                    <tr style={{color:"#000"}}>
                                                        <td style={{color:"#000"}}>{this.state.plan.nickname}</td>
                                                        <td style={{color:"#000"}}>1</td>
                                                        <td style={{color:"#000"}}>{this.state.plan.unit_amount/100} €{this.state.plan.recurring ? "/"+this.state.plan.recurring.interval:""}</td>
                                                    </tr>
                                                    { (this.state.percent_off > 0) ? (<tr style={{color:"#000"}}><td></td><td style={{color:"#000"}}>Discount</td><td style={{color:"#000"}}>{this.state.percent_off}%</td></tr>) : <></>}
                                                    { (isInEU(this.state.country)) ? (<>{ (!this.state.vatValidation)  ? <tr style={{color:"#000"}}><td></td><td style={{color:"#000"}}>TVA</td><td style={{color:"#000"}}>20%</td></tr>:<></>}</>):(<></>) }
                                                    <tr style={{color:"#000"}}><td></td><td style={{color:"#000"}}>Total</td><td style={{color:"#000"}}>{toatlCharge}€{this.state.plan.recurring ? "/"+this.state.plan.recurring.interval:""}</td></tr>
                                                </tbody>
                                            </Table><div>

                                            </div>
                                            {
                    (!this.state.blockCoupon) ? (<Button className="btn-fill" color="primary" onClick={this.toggleModalPass}>
                    Adauga cupon
                          </Button>):(<></>)
                  }
                                            <Container><Card style={{background:"#fff", marginTop: "15px"}}>
                                            <CardHeader>
                                                <h4 style={{color:"#000"}}>Metoda de plata</h4>
                                            </CardHeader>
                                        <CardBody>
                                        
                                    <FormGroup>
                                        <Layout>
                                            <Checkout customer={this.state} notify={this.notify} plan={this.state.plan.id} cop={this.state.couponCode} recurring={this.state.plan.type === "recurring" ? true:false}></Checkout>
                                        </Layout>
                                    </FormGroup>
                                    </CardBody></Card>
                                    </Container>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Hero>
            <Modal
                    modalClassName="modal-pass"
                    isOpen={this.state.modalPass}
                    toggle={this.toggleModalPass}
                    
                    >
                    <div className="modal-header">
    <h3 style={{color: "#000"}}>Adauga cupon</h3>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={this.toggleModalPass}
                        >
                        <i className="tim-icons icon-simple-remove" />
                        </button>
                    </div>
                    <div className="modal-body" style={{color:"#000 !important"}}>
                        <Row>
                            <Col>
                              <label>Cod</label>
                              <Input
                                  name="couponCode"
                                  style={{color: "black"}}
                                  onChange={this.handleChange}
                                  invalid={this.state.fieldError}
                              />
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer" style={{color:"#000 !important"}}>
                    <Button onClick={this.checkCoupon}>Adauga</Button>
                    </div>
                </Modal>
        </div>
            </>
        )
    }
}

export default CheckoutTemplate;