import React from "react";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import ClipLoader from "react-spinners/ClipLoader";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
import ChangePassword from './ChangePassword.js';

import {
    Button,
    Card,
    UncontrolledAlert,
    FormFeedback,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    FormText,
    Label,
    Col,
    ButtonGroup
  } from "reactstrap";


  class ProfileInfoAdmin extends  React.Component {

    constructor(props){
        super(props);
        console.log(props);
        this.state = {
            loading: false,
            showButton: false,
            nume: null,
            country: null, 
            region: null,
            address1: null,
            borderColor: "#2b3553",
            taxIDs:[],
            disableInput: false,
            postal_code: null,
            companie: null,
            cui: null,
            idCustomer: '',
            subscriptions: [],
            invoices: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeHide = this.handleChangeHide.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        this.selectRegion = this.selectRegion.bind(this);
        }

    selectCountry (val) {
    this.setState({ country: val });
    }

    selectRegion (val) {
    this.setState({ region: val });
    }
    handleChange(event) {
    this.setState({ [event.target.name] : event.target.value})
    }

    handleChangeHide(event) {
        this.setState({ [event.target.name] : event.target.value, showButton: true })
    }


    convertDate = (unix_timestamp) =>{
        let date = new Date(unix_timestamp * 1000);
        // Hours part from the timestamp
        let day = date.getDate();
        // Minutes part from the timestamp
        let month = date.getMonth()+1;
        // Seconds part from the timestamp
        let year = date.getFullYear();

        // Will display time in 10:30:23 format
        let formattedTime = day+ "/"+month+"/"+year;
        return formattedTime;
  }

    getTranslate = (value) =>{
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        return language[value];
    }


    componentDidMount() {
        //console.log(this.state);
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let idCustomer = params.get('idCustomer');
        this.setState({idCustomer:idCustomer})
        try{
            fetch(apiLink + '/api/admin/getCustomerInfo?idCustomer=' + idCustomer, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('tokenAdmin'),
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    console.log(obj);
                    this.setState({nume:obj.data?.name, country: obj.data.address?.country, region: obj.data.address?.city, address1: obj.data.address?.line1, postal_code: obj.data.address?.postal_code, companie: obj.data.description, cui: obj.data.metadata?.cui, subscriptions: obj.data.subscriptions?.data});
                })
            .catch((error) => {
                this.props.onLogOut();
            });
        }catch(err){
            this.setState({notificationError: true, loading: true})
        }
        try{
            fetch(apiLink + '/api/admin/getCustomersInvoices?idCustomer=' + idCustomer, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': getFromStorage('tokenAdmin'),
                            'Content-Type': 'application/json',
                        }
                    }).then((res) => {
                        return res.text();   
                    }
                ).then((responseJson) => {
                    var obj = JSON.parse(responseJson);
                    console.log(obj);
                    this.setState({invoices: obj.invoices});
                })
            .catch((error) => {
                this.props.onLogOut();
            });
        }catch(err){
            this.setState({notificationError: true, loading: true})
        }
    }

    onSubmit = () => {
        this.setState({loading: true})
        // //console.log(this.state);
        try{
            fetch(apiLink + '/api/admin/updateCustomer', {
                method: 'POST',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getFromStorage("tokenAdmin")
                },
                body: JSON.stringify({...this.state} )
            }).then((res) => {
                //console.log(res);
                    this.setState({loading: false});
                    if(res.status == 200){
                        this.props.notify("tc", this.getTranslate("successCustomerUpdate"));
                        //
                    } else {
                        this.props.notify("tc", this.getTranslate("errorResponce"));
                    }this.setState({loading: false});
                    return res.text();   
                }
            )
            .catch((error) => {
            this.setState({notificationError: true, loading: true})
            })
        }catch(err){
            this.props.notify("tc", this.getTranslate("errorResponce"));
            this.setState({notificationError: true, loading: true})
        }
    }

    addTax = () => {
        this.setState({loading: true})
        //console.log(this.state);
        try{
            fetch(apiLink + '/api/admin/addTax', {
                method: 'POST',
                headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': getFromStorage("tokenAdmin")
                },
                body: JSON.stringify({...this.state} )
            }).then((res) => {
                this.setState({loading: false})
                if(res.status == 200){
                    this.props.notify("tc", this.getTranslate("successTaxIdAdd"));
                    this.props.updatePage();
                } else {
                    this.props.notify("tc", this.getTranslate("errorResponce"));
                }
                this.componentDidMount();
                return res.text();   
            })
            .catch((error) => {
            this.setState({notificationError: true, loading: true})
            });
        }catch(err){
            this.setState({notificationError: true, loading: true})
        }
    }

    render() {
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;

        console.log(this.state.subscriptions);
        const { country, region } = this.state;
        let template = []
        if(this.state.subscriptions?.length > 0){
            template = this.state.subscriptions.map(function(item, i){
                return(
                    <tr key={i}>
                        <td>
                            {item.plan.nickname}
                        </td>
                        <td>
                            {this.convertDate(item.start_date)}
                        </td>
                        <td>
                        {(item.cancel_at_period_end)? "-":this.convertDate(item.current_period_end)}{}
                        </td>
                        <td>
                            {item.plan.amount/100} {item.plan.currency}/{item.plan.interval}
                        </td>
                        <td>
                            {(item.cancel_at_period_end)? language.canceled + this.convertDate(item.current_period_end)+")":item.status}
                        </td>
                    </tr>
                )
            }.bind(this))
        }

        let templateInvoices = [];
     if(this.state.invoices && this.state.invoices.length > 0){
        templateInvoices = this.state.invoices.map(function(item, i){
            if(item.status!="draft")
                return(
                    <>
                    <tr>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.number}
                        </td>
                        <td></td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.total/100}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.currency.toUpperCase()}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {item.status}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {this.convertDate(item.created)}
                        </td>
                        <td style={{color: item.status != 'paid' ? 'red':'white'}}>
                            {this.convertDate(item.period_end)}
                        </td>
                        <td>
                        <a href={`./invoice?idInvoice=` + item.id}><Button className="btn-sm" color="info" type="submit">
                            {language.viewinvoice}
                            </Button>
                        </a>
                        </td>
                    </tr>
                    </>
                )
                else
                return(<></>)
         }.bind(this))
    }
        return(<div className="content">
            
            <Row>
            <Col md="12">
            <Card>
                <CardHeader>
                  <h5 className="title">{language.updateprofile}</h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <Label>{language.nume}</Label>
                            <Input
                                placeholder= {language.nume}
                                name="nume"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.nume}
                                // invalid={this.state.fieldError}
                            />
                        </Col>
                        <Col>
                            <Label>{language.companie}</Label>
                            <Input
                                placeholder= {language.companie}
                                name="companie"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.companie}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" style={{color:'#fff'}}>   
                            <Label>{language.country}</Label>    
                            <CountryDropdown classes="form-control"
                                value={country}
                                valueType="short"
                                onChange={(val) => this.selectCountry(val)} 
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>{language.region}</Label>
                            <RegionDropdown classes="form-control"
                                country={country}
                                value={region}
                                countryValueType="short"
                                onChange={(val) => this.selectRegion(val)} 
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>{language.address}</Label>
                            <Input
                                placeholder= {language.address}
                                name="address1"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.address1}
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
        <Label>{language.postal}</Label>
                            <Input
                                placeholder= {language.postal}
                                name="postal_code"
                                type="text"
                                onChange={this.handleChange}
                                value={this.state.postal_code}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>N° TVA (FR0000000) or Siren N°, NIF</Label>
                            <Input
                                placeholder= "CUI"
                                name="cui"
                                type="text"
                                disabled = {(this.state.disableInput)? "disabled" : ""}
                                onChange={this.handleChange}
                                value={this.state.cui}
                            />
                        </Col>
                        <Col md="3" style={{color:'#fff'}}>
                            <Label>{language.taxid}</Label>
                            <Input
                                placeholder={language.taxid}
                                id="tax"
                                name="tax_id"
                                type="text"
                                disabled = {(this.state.disableInput)? "disabled" : ""}
                                onChange={this.handleChangeHide}
                            />                             
                            {
                                (this.state.showButton && !this.state.disableInput) ?
                                (   
                                    <ButtonGroup
                                        className="btn-group-toggle float-right"
                                        data-toggle="buttons"
                                    >   
                                        <Button size="sm"  className="btn-fill" color="primary" type="submit" onClick={this.addTax}>
                                            {language.addTax}
                                        </Button>
                                    </ButtonGroup>
                                ):(
                                    <></>
                                )
                            }
                        </Col>
                    </Row>
                        {
                                (!this.state.loading) ?
                                ( <Row style={{marginTop: "15px"}}>
                                        <Col>
                                            <Button className="btn-fill" color="primary" type="submit" onClick={this.onSubmit}>
                                                {language.update}
                                            </Button>
                                        </Col>
                                        
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                        >
                                            <ChangePassword notify={this.props.notify}/>
                                        </ButtonGroup>
                                        
                                    </Row>                                 
                                ):(<Row>
                                    <Col>
                                    <ClipLoader
                                    size={43}
                                    color={"#F79434"}
                                    loading={this.state.loading}
                                    />
                                    </Col>
                                    </Row>
                                )
                            }
                </CardBody>
                <CardFooter>
                  
                </CardFooter>

            </Card>
            </Col>
            </Row><Row><Col> 
                <Card><CardBody>
                <Row><Col> 
                            <Table>
                                <thead className="text-primary">
                                    <tr>
                                        <th>
                                        {language.subscriptionplan}
                                        </th>
                                        <th>{language.startingdate}</th>
                                        <th>{language.nextchargedate}</th>
                                        <th>{language.priceid}</th>
                                        <th>{language.status}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {template}
                                </tbody>
                            </Table> 
                            </Col></Row></CardBody>
                            </Card>
                        </Col>
                    </Row><Row><Col> 
                <Card><CardBody>
                <Row><Col> 
                            <Table>
                            <thead className="text-primary">
                                    <tr>
                                        <th>{language.invoicenumber}
                                        
                                        </th>
                      <th>{language.voidnumber}</th>
                                        <th>{language.amount}</th>
                                        <th>{language.currency}</th>
                                        <th>{language.status}</th>
                                        <th>{language.createdat}</th>
                                        <th>{language.dueto}</th>
                                        <th>{language.actions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   {templateInvoices}
                                </tbody>
                            </Table> 
                            </Col></Row></CardBody>
                            </Card>
                        </Col>
                    </Row></div>
        )
    }
  }
  export default ProfileInfoAdmin;