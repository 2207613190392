import React from "react";
import { getFromStorage } from '../utils/storage';
import ListMessages from "./ListMessages";
import AccountVerified from "./AccountVerified.js";

class MyMessages extends React.Component {

  render() {
    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    //console.log(this.props); 
    return (
      <>
        <div className="content">
          <AccountVerified  notify={this.props.notify}/>
          <ListMessages  notify={this.props.notify}/>
        </div>
      </>
    );
  }
}

export default MyMessages;
