import React from "react";
import {CardDemo} from '../components/CheckoutForm';
import { getFromStorage } from '../utils/storage';
import { apiLink, language } from '../utils/constants';
import ClipLoader from "react-spinners/ClipLoader";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  UncontrolledAlert,
  Col
} from "reactstrap";
//const stripePromise = loadStripe('pk_test_3RPwCZtAbPepwXvZHWzVKmxT00LECzsW8o');
import Layout from '../utils/Layout';
import Checkout from '../utils/Checkout';

class Payment extends React.Component {
   render() {
    return (
      <>
        <div className="content">
          
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{language.payment}</h5>
                </CardHeader>
                <CardBody>
                    <Layout>
                      <Checkout></Checkout>
                    </Layout>
                </CardBody>
                <CardFooter>
                  
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Payment;
