import React , { Component } from 'react';

import { apiLink } from '../utils/constants';
import { getFromStorage } from '../utils/storage';
import {
    Hero, CallToAction, ScrollDownIndicator,Checklist,Section,PricingTier
  } from 'react-landing-page'
  
import { Row, Col, Container, FormGroup, Input, FormFeedback,Button, Card, CardHeader, CardBody, Table } from 'reactstrap';
import logo from "assets/img/logoDoctorPlan.png";
import back from "assets/img/doctor-software-FB.png";
import pms from "assets/img/pms.png";
import pms_pro from "assets/img/pms_pro.png";
import blue from "assets/img/blueBack.jpg";
import PhoneInput from 'react-phone-input-2';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Layout from '../utils/Layout';
import Checkout from '../utils/CheckoutPlan';
import { language } from 'utils/language_gb';

class SuccessTemplate extends React.Component {
    
    componentDidMount() {
        document.body.style.backgroundColor = "white";
        document.body.style.color = "black";
    }
    render(){
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        return(<>
            <div style={{background: `url(${back}) no-repeat center center fixed`, backgroundSize:"cover"}}>
                <Hero style={{backgroundColor:"rgba(255, 255, 255, 0.8)"}}>
                    <Container>
                        <div style={{textAlign:'center'}}>
                            <div class="svg-box">
                                <svg class="circular green-stroke">
                                    <circle class="path" cx="75" cy="75" r="50" fill="none" stroke-width="5" stroke-miterlimit="10"/>
                                </svg>
                                <svg class="checkmark green-stroke">
                                    <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                                        <path class="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53"/>
                                    </g>
                                </svg>
                            </div>
        <h3 style={{color: "#000"}}>{language.paymentsucces}</h3>
                        </div>
                    </Container>
                </Hero>
            </div>
        </>
        )    
    }
}
export default SuccessTemplate;