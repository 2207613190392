/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {setInStorage} from '../../utils/storage';
import { apiLink, language } from '../../utils/constants';
import ReactFlagsSelect from 'react-flags-select';
import {getFromStorage} from '../../utils/storage';
//import css module
import 'react-flags-select/css/react-flags-select.css';

//OR import sass module
import 'react-flags-select/scss/react-flags-select.scss';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  Col,
  Row,
  NavItem
} from "reactstrap";
import CartItemsModal from "views/CartItemsModal";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      modalCart: false,
      balance: 0,
      cartCount: 0,
      invoices: [],
      messages: [],
      color: "navbar-transparent",
    };
    this.onLogOut = this.onLogOut.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
  }

  // selectRegion (val) {
  //   setInStorage('language', val);
  // }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    if(getFromStorage("tokenAdmin") != null){
      try{
        fetch(apiLink + '/api/users/getUnreadCommentsAdmin?idCustomer=' + getFromStorage('idStripe'), {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('tokenAdmin'),
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              if(obj.status === 200){
                this.setState({messages: obj.messages});
                if(this.state.messages.length > 0){
                  this.state.messages.map(function(item, i){
                    if(item.citit === 0){
                      this.state.necitite += 1;
                  }
                  }.bind(this))
                }
            }
          })
          .catch((error) => {
            this.props.onLogOut();
          });
      }catch(err){
          //
      }
    }else{
      try{
        fetch(apiLink + '/api/users/getUnreadComments?idCustomer=' + getFromStorage('idStripe'), {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              if(obj.status === 200){
                this.setState({messages: obj.messages});
                if(this.state.messages.length > 0){
                  this.state.messages.map(function(item, i){
                    if(item.citit === 0){
                      this.state.necitite += 1;
                  }
                  }.bind(this))
                }
            }
          })
          .catch((error) => {
            this.props.onLogOut();
          });
      }catch(err){
          //
      }
    }
    try{
      fetch(apiLink + '/api/users/getBalance?idCustomer=' + getFromStorage('idStripe'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': getFromStorage('token'),
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                return res.text();   
            }
        ).then((responseJson) => {
            var obj = JSON.parse(responseJson);
            this.setState({balance:obj.balance});
        })
        .catch((error) => {
          this.props.onLogOut();
        });
    }catch(err){
        //
    }
    try{
      fetch(apiLink + '/api/users/getUnpaidInvoices?idCustomer=' + getFromStorage('idStripe'), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': getFromStorage('token'),
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                return res.text();   
            }
        ).then((responseJson) => {
            var obj = JSON.parse(responseJson);
            console.log(obj);
            if(obj.status == 200)
              this.setState({invoices: obj.data})
        })
        .catch((error) => {
          this.props.onLogOut();
        });
    }catch(err){
        //
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  
   onLogOut() {
     
    ////console.log(this.props);
     setInStorage("token", null);
     setInStorage("idAccount", null);
     setInStorage("customerName", null);
     setInStorage("customerAvatar", null);
     this.props.updateToken(null);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };

  selectLanguage(val) {
    //console.log(val);
    this.props.updateLanguage(val.toLowerCase());
  }

  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  toggleModalCart = () => {
    this.setState({
      modalCart: !this.state.modalCart
    });
  };
  render() {

    let language1 = null
    if(getFromStorage('language') !== null){
      language1 = require(`../../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../../utils/language_gb`); 
    }
    let language = language1.language;
    //console.log(this.props);
    let template = this.state.invoices.map((item) => {
      return (
        <NavLink tag="li">
           <a href={"/admin/invoices"}><DropdownItem className="nav-item" >
            {language.unpaidinvoice} {item.number}
            </DropdownItem></a>
        </NavLink>
      )
    })

    let avatar = getFromStorage('customerAvatar') ?? "avatar_default";

    let template2 = this.state.messages.map(function(item, i){
      return (
        <NavLink tag="li">
          <a href={"/admin/chat?idDispute=" + item.idDispute}><DropdownItem className="nav-item" >
            {language.disputa} #{item.idDispute} : {item.message}
          </DropdownItem></a>
        </NavLink>
      )
      }.bind(this))
    return (
      <>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                {language[this.props.brandText]}
              </NavbarBrand>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <NavItem>
                <a href={"/admin/balance"}><Button
                      color="link"
                      id="cart-button"
                      style={{height: 50, width: 60, paddingTop: 13, fontSize: 20, paddingRight: 80, color: 'white'}}
                    >
                    <i className="tim-icons icon-send" title={this.state.balance}/>
                    {!!this.state.balance ? " " + this.state.balance : "0"}
                  </Button></a>
                </NavItem>
                <ReactFlagsSelect 
                    countries={["GB", "RO", "FR"]}
                    placeholder=""
                    className="classInput2"
                    defaultCountry={getFromStorage('language') ? getFromStorage('language').toUpperCase():"GB"}
                    showSelectedLabel={false} 
                    showOptionLabel={false}
                    selectedSize={16} 
                    optionsSize={14}
                    onSelect={this.selectLanguage}
                  />
                <InputGroup className="search-bar">
                  <Button
                    color="link"
                    data-target="#cartModal"
                    data-toggle="modal"
                    id="cart-button"
                    onClick={this.toggleModalCart}
                  >
                    <div className="notification" style={{marginTop: '-10px', marginRight: '-5px', borderRadius: "50%", color: '#fff'}}>{this.props.countCart}</div>
                    <i className="tim-icons icon-cart" />
                    <span className="d-lg-none d-md-block">{language.cart}</span>
                  </Button>
                </InputGroup>
                <UncontrolledDropdown nav disabled={!template.length}>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className="notification" style={{marginTop: '-10px', marginRight: '-5px', borderRadius: "50%", color: '#ffcf2f'}}>{this.state.invoices.length}</div>
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-sound-wave" style={{color: '#ffcf2f'}} />
              <p className="d-lg-none">{language.notification}</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    {template}
                  </DropdownMenu>
                </UncontrolledDropdown>


                <UncontrolledDropdown nav  disabled={!template2.length}>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                  >
                    <div className="notification" style={{marginTop: '-10px', marginRight: '-5px', borderRadius: "50%", color: '#2857a4'}}>{this.state.messages.length}</div>
                    <div className="notification d-none d-lg-block d-xl-block" />
                    <i className="tim-icons icon-chat-33" style={{color: '#2857a4'}} />
              <p className="d-lg-none">{language.messages}</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    {template2}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <div className="photo">
                      <img alt="..." src={require(`assets/img/${ avatar }.png`)} />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
              <p className="d-lg-none">{language.logout}</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <Col><p style={{ color: "#9A9A9A" }}>{ getFromStorage('customerName') }</p></Col>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
              <a href={`./profile/`}><DropdownItem className="nav-item">{language.profil}</DropdownItem></a>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
              <DropdownItem className="nav-item" onClick={this.onLogOut}>{language.logout}</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
        <Modal
          modalClassName="modal-cart"
          isOpen={this.state.modalCart}
          toggle={this.toggleModalCart}
          
        >
          <div className="modal-header">
            <h3 style={{color: "#000"}}>{language.items}</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalCart}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
          <div className="modal-body" style={{color:"#000 !important"}}>
            <CartItemsModal removeFromCart={this.props.removeFromCart} addToCart={this.props.addToCart}></CartItemsModal>
          </div>
        </Modal>
      </>
    );
  }
}

export default AdminNavbar;
