import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';

import {
    Button,
    Card,
    UncontrolledAlert,
    FormFeedback,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Table,
    Row,
    FormText,
    Label,
    Col
  } from "reactstrap";

  class GetTaxes extends  React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            tax_id: '',
            borderColor: "#2b3553",
            taxIDs: this.props.taxIDs
        }

        this.handleChange = this.handleChange.bind(this);
        this.selectCountry = this.selectCountry.bind(this);
        }

    selectCountry (val) {
    this.setState({ country: val });
    }

    handleChange(event) {
    this.setState({ [event.target.name] : event.target.value })
    }

    componentDidMount() {
    }

    deleteID(idTax){
        //console.log(idTax);
        try{
            fetch(apiLink + '/api/users/deletetaxid', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': getFromStorage('token'),
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({taxID: idTax, customer: getFromStorage("idStripe")} )
                }).then((res) => {
                    this.componentDidMount();
                    this.props.updateTax();
                    return res.text();   
                }
            ).catch((error) => {
                this.setState({notificationError: true, loading: true})
                
            });
        }catch(err){
            //
        }
      }

    render(){
        let language1 = null
        if(getFromStorage('language') !== null){
          language1 = require(`../utils/language_`+getFromStorage('language')); 
        }else{
          language1 = require(`../utils/language_gb`); 
        }
        let language = language1.language;
        let template = []
        console.log(this.props);
        if(this.props.taxIDs)
            template = this.props.taxIDs.map(function(item, i) {
                return(
                    <tr key={i}>
                        <td>{item.country}</td>
                        <td>{item.value}</td>
                        <td>{item.verification.status}</td>
                        <td>{item.verification.verified_address}</td>
                        <td>{item.verification.verified_name}</td>
                        <td>
                            <Button className="btn-sm" color="danger" type="submit" onClick={() => this.deleteID(item.id)}>{language.deleteTax}</Button>
                        </td>

                    </tr>
                )
            }.bind(this))
        return(
            <Row>
                <Col md="12">
                <Card>
                    <CardHeader>
                    <h5 className="title">{language.mytaxids}</h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                            <Table>
                                <thead className="text-primary">
                                    <tr>
                                        <th>{language.taxcountry}</th>
                                        <th>{language.value}</th>
                                        <th>{language.verificationstatus}</th>
                                        <th>{language.verificationaddress}</th>
                                        <th>{language.verificationname}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {template}
                                </tbody>
                            </Table>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Col>
            </Row>
        );
    }
}
export default GetTaxes;