import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import GetProducts from "./GetProducts";
import NewPaymentMethod from "./NewPaymentMethod";
import AccountVerified from "./AccountVerified";
import FA from 'react-fontawesome';
import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  FormText,
  Label,
  Col,
  CardText,
  Table
} from "reactstrap";
import Layout from '../utils/Layout';
import BalanceCheckout from '../utils/BalanceCheckout';

class Balance extends React.Component {

    constructor(props){
        super(props);
        this.state = {
          amount: 0,
          cupon: "",
          cuponcode: '',
          package:null,
          qty: [200,1000,5000],
          price: [9,40,230],
          taxable: false,
          paymentMethods: [],
          showPaymentMethods: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCupon = this.handleChangeCupon.bind(this);
        this.handleChangeCuponCode = this.handleChangeCuponCode.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name] : event.target.value});
        this.setState({ amount: event.target.value * 0.05})
    }

    setPackage = (id) => {
      console.log(id)
      this.setState({package: id})
    }

    handleChangeCupon() {
      if(this.state.cupon == ""){
        this.setState({cupon: "101"});
      } else {
        this.setState({cupon: ""});
      }
    }
    handleChangeCuponCode(event) {
        this.setState({ [event.target.name] : event.target.value});
    }

    componentDidMount(){      
      
    try{
      fetch(apiLink + '/api/users/gettaxable', {
                  method: 'POST',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                      idCustomer: getFromStorage('idStripe'),
                  })
              }).then((res) => {
                 return res.text();   
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              this.state.taxable = obj.taxable;
              //console.log(obj);
          })
      .catch((error) => {
        this.props.onLogOut();
      });
  }catch(err){
      //
  }  
    }
    onSubmit = () =>{

        try{
            fetch(apiLink + '/api/users/getPaymentOptions?idCustomer=' + getFromStorage('idStripe'), {
                          method: 'GET',
                          headers: {
                              'Accept': 'application/json',
                              'Authorization': getFromStorage('token'),
                              'Content-Type': 'application/json',
                          }
                      }).then((res) => {
                          return res.text();   
                      }
                  ).then((responseJson) => {
                      var obj = JSON.parse(responseJson);
                      console.log(obj);
                      this.setState({paymentMethods:obj.data.data, showPaymentMethods: true, newCard: null});
                      console.log(this.state.showPaymentMethods);
                  })
              .catch((error) => {
                this.props.onLogOut();
              });
          }catch(err){
              //
          }
    }

  render() {
    let paymentOptions = [];
    if(this.state.showPaymentMethods) {
      paymentOptions = this.state.paymentMethods.map(function(item, i){
        return(
          <FormGroup check key={i}>
            <Label check>
              <Input type="radio" name="newCard" value={item.id} onChange={this.handleChange} />{' '}
              { (item.card.brand === "visa") ? (<FA
                name="cc-visa"
                size="2x"
              />):(<></>)}{ (item.card.brand === "amex") ? (<FA
                name="cc-amex"
                size="2x"
              />):(<></>)}{ (item.card.brand === "discover") ? (<FA
                name="cc-discover"
                size="2x"
              />):(<></>)}{ (item.card.brand === "diners") ? (<FA
                name="cc-diners-club"
                size="2x"
              />):(<></>)}{ (item.card.brand === "mastercard") ? (<FA
                name="cc-mastercard"
                size="2x"
              />):(<></>)}  {item.card.brand.charAt(0).toUpperCase() + item.card.brand.slice(1)}-{item.card.last4}
            </Label>
          </FormGroup>
      )
      }.bind(this))
    }
    let language1 = require(`../utils/language_` + getFromStorage('language')); 
    let language = language1.language;
    let toatlCharge = 0;
    if(this.state.package >= 0){
      console.log(this.state.price[this.state.package]);
      toatlCharge = this.state.price[this.state.package];
      if(this.state.taxable)
        toatlCharge = toatlCharge + ((toatlCharge)*20)/100;
    }
    return (
      <>
       <div className="content">
       <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h3 className="title">{language.balance}</h3>
                </CardHeader>
                <CardBody>                   
            
                          
                        <Row>
                          
                        <Col md="4">
                            <Card className="card-user">
                              <CardBody>
                                <CardText />
                                <div className="author">
                                  <div className="block block-one" />
                                  <div className="block block-two" />
                                  <div className="block block-three" />
                                  <div className="block block-four" />
                                    <img
                                      alt="..."
                                      className="avatar"
                                      src={require("assets/img/SMS_tr.png")}
                                    />
                                    <h3 className="title">{language.package}</h3>
                                </div>
                                <div className="card-description" style={{textAlign: "center"}}>
                                  {language.continut2}
                                </div>
                                
                                <div className="card-description" style={{textAlign: "center"}}>
                                  <h4>{language.price}</h4>
                                   <h4 style={{textDecoration: "line-through"}}>10€</h4><h3>9€  <p style={{fontSize: "12px"}}>(10.8€ {language.priceVAT})</p></h3>
                                </div>
                              </CardBody>
                              <CardFooter>
                                <div className="button-container">
                                  <Button color="primary" onClick={()=>this.setPackage(0)}>{language.orderSMS}</Button>
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                          <Col md="4">
                            <Card className="card-user">
                              <CardBody>
                                <CardText />
                                <div className="author">
                                  <div className="block block-one" />
                                  <div className="block block-two" />
                                  <div className="block block-three" />
                                  <div className="block block-four" />
                                    <img
                                      alt="..."
                                      className="avatar"
                                      src={require("assets/img/SMS_tr.png")}
                                    />
                                    <h3 className="title">{language.package}</h3>
                                </div>
                                <div className="card-description" style={{textAlign: "center"}}>
                                {language.continut1}
                                </div>
                                <div className="card-description" style={{textAlign: "center"}}>
                                  <h4>{language.price}</h4>
                                   <h4 style={{textDecoration: "line-through"}}>50€</h4><h3>40€ <p style={{fontSize: "12px"}}>(48€ {language.priceVAT})</p></h3>
                                </div>
                              </CardBody>
                              <CardFooter>
                                <div className="button-container">
                                  <Button color="primary" onClick={()=>this.setPackage(1)}>{language.orderSMS}</Button>
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                          <Col md="4">
                            <Card className="card-user">
                              <CardBody>
                                <CardText />
                                <div className="author">
                                  <div className="block block-one" />
                                  <div className="block block-two" />
                                  <div className="block block-three" />
                                  <div className="block block-four" />
                                    <img
                                      alt="..."
                                      className="avatar"
                                      src={require("assets/img/SMS_tr.png")}
                                    />
                                    <h3 className="title">{language.package}</h3>
                                </div>
                                <div className="card-description" style={{textAlign: "center"}}>
                                {language.continut5}
                                </div>
                                <div className="card-description" style={{textAlign: "center"}}>
                                  <h4>{language.price}</h4>
                                   <h4 style={{textDecoration: "line-through"}}>250€</h4><h3>230€ <p style={{fontSize: "12px"}}>(276€ {language.priceVAT})</p></h3>
                                </div>
                              </CardBody>
                              <CardFooter>
                                <div className="button-container">
                                  <Button color="primary" onClick={()=>this.setPackage(2)}>{language.orderSMS}</Button>
                                </div>
                              </CardFooter>
                            </Card>
                          </Col>
                        </Row>
                            {(this.state.package!== null)? (<><Row><Col><Table className="tablesorter" >
                      <thead className="text-primary">
                        <tr>
                          <th>{language.productsubscription}</th>
                          <th>{language.qty}</th>
                          <th>{language.total}</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <td>{language.package}</td>
                            <td>{this.state.qty[this.state.package]}</td>
                            <td>{this.state.price[this.state.package]}€</td>
                          </tr>
                          { (this.state.taxable) ? (<tr><td></td><td>VAT – France</td><td>20%</td></tr>) : <></>}
                          <tr><td></td><td>{language.total}</td><td>{toatlCharge}€</td></tr>
                      </tbody>
                  </Table></Col></Row><Row><Col>
                  {
                    (this.state.showPaymentMethods) ? 
                    (<>
                    <label><b>{language.selectpaymentmet}</b></label>
                    
                      <FormGroup tag="fieldset">
                        {paymentOptions}
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="newCard" value="101" onChange={this.handleChange} />{' '}
                              {language.newcard}
                          </Label>
                        </FormGroup>
                      </FormGroup>
                      { (this.state.newCard === "101") ? (<NewPaymentMethod success={this.onSubmit}></NewPaymentMethod>):(<div></div>)}
                      
                        <Layout>
                            <BalanceCheckout payment={this.state.newCard} disabled={(this.state.newCard === null || this.state.newCard === undefined || this.state.newCard === "101")? true:false} notify={this.props.notify} smsnumber={this.state.package}></BalanceCheckout>
                        </Layout>
                    </>):(
                    <Button className="btn-fill" color="primary" type="submit" onClick={this.onSubmit}>
                        {language.continue}
                    </Button>)
                  }
                  </Col></Row></>):(<></>)}
                        <br></br>
                        <Row>
                          <Col>
                            
                          </Col>

                        </Row>
                    
                </CardBody>
              </Card>
            </Col>
          </Row>
       </div>
      </>
    );
  }
}

export default Balance;