export const apiLink = "https://portal.stillco.fr:5000";

export const language = {  
    newproduct: "Add Product",
    producttitle: "Product Title",
    price: "Price",
    producttype: "Product Type",
    description: "Description",
    product: "Product",
    subscription: "Subscription",
    title: "Title",
    yourdescription: "Here can be your description",
    save: "Save",
    products: "Products",
    upload : "Upload",
    file: "File",
    uploadfile: "This is some placeholder block-level help text for the above input. It's a bit lighter and easily wraps to a new line.",
    uploadbutton: "UPLOAD",
    mypaymentmethods: "My Payment Methods",
    paymentmethod: "PAYMENT METHOD",
    cardtype: "CARD TYPE",
    number: "NUMBER",
    expirydate: "EXPIRY DATE",
    addpaymentmethod: "Add Payment Method",
    newpaymentmethod: "New Payment Method",
    country: "Country",
    selectcountry: "Select Country",
    region: "Region",
    address: "Address",
    phone: "Phone",
    carddetails: "Card Details",
    cardnumber: "Card number",
    addcard: "Add Card",
    username: "Username",
    password: "Password",
    login: "Login",
    noaccount: "I don't have an account",
    registerhere: "Register Here",
    name: "Name",
    email: "Email",
    confirmpassword: "Confirm Password",
    register: "Register",
    back: "Back to login",
    updateprofile: "Update Profile",
    nume: "Nume",
    prenume: "Prenume",
    taxid: "Tax ID",
    update: "Update",
    mytaxids: "My tax IDs",
    taxcountry: "COUNTRY",
    value: "VALUE",
    verificationstatus: "VERIFICATION STATUS",
    verificationaddress:"VERIFICATION ADDRESS",
    verificationname: "VERIFICATION NAME",
    subscriptionid: "Subscriptions",
    subscriptionplan: "SUBSCRIPTION PLAN",
    startingdate: "STARTING DATE",
    nextchargedate: "NEXT CHARGE DATE",
    priceid: "PRICE",
    status: "STATUS",
    actions: "ACTIONS",
    invoices: "Invoices",
    amount: "AMOUNT",
    currency: "CURRENCY",
    invoicenumber: "INVOICE NUMBER",
    createdat: "CREATED AT",
    dueto: "DUE TO",
    creeazaabonament: "Create subscription",
    pricing: "Pricing",
    productsubscription: "PRODUCT",
    qty: "QTY",
    total: "TOTAL",
    acceptsubscription: "Accept Subscription"
}

