
export const language = {  
    newproduct: "Add Product",
    producttitle: "Product Title",
    price: "Price",
    producttype: "Product Type",
    description: "Description",
    product: "Product",
    subscription: "Subscription",
    title: "Title",
    yourdescription: "Here can be your description",
    save: "Save",
    products: "Products",
    upload : "Upload",
    file: "File",
    uploadfile: "This is some placeholder block-level help text for the above input. It's a bit lighter and easily wraps to a new line.",
    uploadbutton: "UPLOAD",
    mypaymentmethods: "My Payment Methods",
    paymentmethod: "PAYMENT METHOD",
    cardtype: "CARD TYPE",
    number: "NUMBER",
    expirydate: "EXPIRY DATE",
    addpaymentmethod: "Add Payment Method",
    newpaymentmethod: "New Payment Method",
    country: "Country",
    selectcountry: "Select Country",
    region: "Region",
    address: "Address",
    phone: "Phone",
    carddetails: "Card Details",
    cardnumber: "Card number",
    addcard: "Add Card",
    username: "Username",
    password: "Password",
    login: "Login",
    noaccount: "I don't have an account",
    registerhere: "Register Here",
    name: "Name",
    email: "Email",
    confirmpassword: "Confirm Password",
    register: "Register",
    back: "Back to login",
    updateprofile: "Update Profile",
    nume: "Name",
    prenume: "Surname",
    taxid: "Tax ID",
    update: "Update",
    mytaxids: "My tax IDs",
    taxcountry: "COUNTRY",
    value: "VALUE",
    verificationstatus: "VERIFICATION STATUS",
    verificationaddress:"VERIFICATION ADDRESS",
    verificationname: "VERIFICATION NAME",
    subscriptionid: "Subscriptions",
    subscriptionplan: "SUBSCRIPTION PLAN",
    startingdate: "STARTING DATE",
    nextchargedate: "NEXT CHARGE DATE",
    priceid: "PRICE",
    status: "STATUS",
    actions: "ACTIONS",
    invoices: "Invoices",
    amount: "AMOUNT",
    currency: "CURRENCY",
    invoicenumber: "INVOICE NUMBER",
    createdat: "CREATED AT",
    dueto: "DUE TO",
    creeazaabonament: "Create subscription",
    pricing: "Pricing",
    productsubscription: "PRODUCT",
    qty: "QUANTITY",
    total: "TOTAL",
    acceptsubscription: "Accept Subscription",
    nopassword: "Your account does not have a password...",
    addpassword: "Add a password",
    addnewpassword: "Add new password",
    newpassword: "New password",
    addproduct: "Product successfully added",
    problemconnection: "Server connection problems",
    shorttitle: "Title too short",
    balance: "Balance",
    totalbalance: "Total",
    delete: "Delete",
    amountcart: "Price",
    gotocheckout: "Go to checkout",
    changepassword: "Change password",
    checkout: "Checkout",
    selectpaymentmet: "Select Payment Method",
    newcard: "New Card",
    totalabonamente: "Total Subscription",
    totalfacturi: "Total Invoices",
    smsramase: "SMS left",
    totalshipment: "Total Shipments",
    paymentthismounth: "Payments this month",
    payments: "Payments",
    productaddedtocart: "Product Added to cart",
    addtocart: "Add to cart",
    summary: "Summary",
    billedto: "Billed to",
    invoiceno: "Invoice No",
    billingmethod: "Billing method",
    phonenumber: "Phone Number",
    currencyinvoices: "Currency",
    unitprice: "Unit Price",
    subtotal: "Subtotal",
    vat: "Vat",
    downloadaspdf: "Download as PDF",
    viewinvoice : "View Invoice",
    plan: "Plan",
    subscribe: "Subscribe",
    listaservicii : "List of services",
    cancelplan: "Cancel plan",
    payment: "Payment",
    card: "Card",
    paymentsucces: "Payment Successful",
    items: "Items",
    companie: "Company",
    addTax:"Add tax ID",
    deleteTax:"Delete",
    continue:"Continue",
    cupon:"Add coupon",
    successCustomerUpdate: "Customer information was saved!",
    errorResponce: "An error was occured!",
    successPasswordUpdate: "Password was updated!",
    successTaxIdAdd: "Added a tax ID to customer!",
    successPMAdd: "Payment method is added!",
    forgot: "Forgot password?",
    accountRecovery: "Account recovery",
    sendEmail: "Recover your account, send us your email.",
    sendEmailButton: "Send",
    successSendEmailMessage: "Check your email to recover your account!",
    info: "Details",
    package: "SMS package",
    continut2: "This package includes 200 text massages",
    continut1: "This package includes 1000 text massages",
    continut5: "This package includes 5000 text massages",
    orderSMS: "Order",
    refund: "Refund",
    company: "Company",
    postal: "Postal code",
    suport: "Support",
    motiv: "Reason",
    refund: "Refund",
    cerereSuport: "Support request",
    descriere: "Description",
    textDescriere: "Here can be your description",
    send: "Send",
    setari: "Settings",
    subscriptionSetting: "Subscription Settings",
    ipaddress: "IP Address",
    timeSMS: "Time to send SMS",
    print: "Print this out!",
    processing: "Processing...",
    cerereInregsitrata: "Registered support request",
    erroroccurred: "An error occurred",
    setarisalvate: "Settings saved successfully",
    emailused: "Email already used",
    productadded: "Product added to the cart",
    tvanumbervalid: "TVA Number Valid",

    dashboard: "Dashboard",
    myprofile: "My Profile",
    abonamentelemele: "My subscriptions",
    facturilemele: "My invoices",
    listaproduse: "Product list",
    listaabonamente: "Subscription list",
    comandaabonament: "Order subscription",
    abonare: "Subscribe now and if you don't like it, return your equipment 30 days and you get all your money back. Pay for 3 months in advance and get your equipment free of charge.",
    informatiiobligatorii: "Mandatory information",
    informatiioptionale: "Optional information",
    abonament: "Subscription",
    tvanumbervalid: "TVA Number valid",
    emailalreadyused: "Email already used",
    addedproduct: "Product added to the cart",
    tvanumberinvalid: "TVA Number invalid",
    payinvoice: "Pay invoice",
    viewcreditnote: "View credit note",
    pay: "Pay",
    cod: "Code",
    adauga: "Add",
    moredetails: "More Details",
    canceled: "Canceled(Active until ",
    profileincomplete: "Your profile is not completed",
    gotoprofile: "Go to Profile",
    listinvoices: "List Invoices",
    factura: "Invoice ",
    profil: "Profile",
    logout: "Log out",
    passnomatch: "Passwords do not match",
    balancesms: "Buy SMS",
    historysms: "SMS history",
    errorpass: "Passwords do not match",
    validemail: "Use a valid email",
    unpaidinvoice: "Unpaid Invoice N° ",
    welcome: "Welcome To Still-Co",
    descrierestillco: "WE ARE A SOFTWARE DEVELOPMENT COMPANY.",
    getstarted: "GET STARTED",
    verificationcode: "Enter your verification code",
    codenumber: "Input the code we sent to (+40) XXX-XXX-",
    access: " to access your account.",
    resend: "Resend code",
    createdbefore: "Created before:",
    createdafter: "Created after:",
    voidnumber: "Void Number",
    chatsupport: "Chat",
    mymessages: "My Messages",
    iddisputa: "Id Dispute",
    disputetype: "Dispute type",
    openchat: "Open chat",
    chatmessage: "Type in your message ... ",
    cart: "Cart",
    notification: "Notifications",
    messages: "Messages",
    disputa: "Dispute",
    getproduct: "Buy now",
    viewcustomer: "View Customer",
    cust: "Customer",
    statusuri: "Status",
    closetopic: "Close topic",
    with: "with",
    from: "from",
    customers: "Customers",
    customername: "Customer Name",
    actiuni: "Actions",
    idcustomer: "idCustomer",
    data: "Date",
    amount: "Amount",
    priceVAT: "VAT included",
    year: "year",
    month: "month",

    succesfulSmsTitle: "Succesful SMS",
    totalSmsTitle: "Requested SMS",
    remainingSmsTitle: "Remaining SMS",
    loadHistoryButton: "Load data",
    historyChartTitle: "SMS history",
    historyDetailsTitle: "SMS details for",
    deliveredCategory: "delivered",
    expiredCategory: "expired",
    rejectedCategory: "rejected",
    otherCategory: "other",
    confirmedCategory: "confirmed",
    unconfirmedCategory: "unconfirmed",
    totalCategory: "sent",
    locale: 'en-GB',
    messageStatusHeader: 'Status',
    messageNumberHeader: 'Recipient number',
    messageSubmitHeader: 'Submitted at',
    messageConfirmHeader: 'Confirmed at',
    fromDateText: "From date",
    toDateText: "To date",
    months: [ "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december" ],
    dashboardLoadButton: "Load data",

    withvat: "Price with added VAT",
    consumptionInfo: (rate, date) => `With an average rate of ${rate}/day you should recharge by ${date}`,
    successAvatarUpdate: "The profile picture was succesfully updated",
    changeProfile: "Change profile picture",
    saveProfile: "Update profile picture",
    cancelProfile: "Cancel",

    invoiceData: "Invoiced",
    paymentData: "Paid",
    refundData: "Refunded",
}