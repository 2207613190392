import React from "react";
import { getFromStorage } from '../utils/storage';
import { apiLink } from '../utils/constants';
// reactstrap components
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import CreditCardInput from 'react-credit-card-input';
import 'assets/css/login_style.css';
import {
  Button,
  Card,
  UncontrolledAlert,
  FormFeedback,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Table,
  Row,
  FormText,
  Label,
  Col
} from "reactstrap";
import NewPaymentMethod from "./NewPaymentMethod";
import ListSubscriptions from "./ListSubscriptions";
import ListInvoices from "./ListInvoices";
import ProfileInfo from "./ProfileInfe";
import GetTaxes from "./GetTaxes";
import AccountVerified from "./AccountVerified.js";
class Profile extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: false,
      paymentMethods: [],
      newPayment: false,
      customerInfo: {},
      taxIDs: []
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name] : event.target.value })
  }

  showPayment = () => {
    this.setState({newPayment: !this.state.newPayment})
  }

  componentWillMount() {
    try{
      fetch(apiLink + '/api/users/getPaymentOptions?idCustomer=' + getFromStorage('idStripe'), {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();   
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              //console.log(obj);
              this.setState({paymentMethods:obj.data.data});
          })
      .catch((error) => {
        this.props.onLogOut();
      });
      
      fetch(apiLink + '/api/users/getTaxes?idCustomer=' + getFromStorage('idStripe'), {
        method: 'GET',
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getFromStorage("token")
        }
    }).then((res) => {
        return res.text();   
    }).then((responseJson) => {
        var obj = JSON.parse(responseJson);
        this.setState({taxIDs:obj.data.data});
    })
    .catch((error) => {
        console.log(error)
    });
  }catch(err){
      //
  }
  }

  updatePaymentMethods = () => {
    try{
      fetch(apiLink + '/api/users/getPaymentOptions?idCustomer=' + getFromStorage('idStripe'), {
                  method: 'GET',
                  headers: {
                      'Accept': 'application/json',
                      'Authorization': getFromStorage('token'),
                      'Content-Type': 'application/json',
                  }
              }).then((res) => {
                  return res.text();   
              }
          ).then((responseJson) => {
              var obj = JSON.parse(responseJson);
              //console.log(obj);
              this.setState({paymentMethods:obj.data.data, newPayment: false});
          })
      .catch((error) => {
        this.props.onLogOut();
      });
  }catch(err){
  
  }
  }

  updateTax = () => {
    try{
      fetch(apiLink + '/api/users/getTaxes?idCustomer=' + getFromStorage('idStripe'), {
          method: 'GET',
          headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': getFromStorage("token")
          }
      }).then((res) => {
          return res.text();   
      }).then((responseJson) => {
          var obj = JSON.parse(responseJson);
          this.setState({taxIDs:obj.data.data});
      })
      .catch((error) => {
      this.setState({notificationError: true, loading: true})
      });
    }catch(err){
        this.setState({notificationError: true, loading: true})
    }
  }

  removePaymentMethod = (payment_method) => {
    try {
      fetch(apiLink + "/api/users/removePaymentMethod", {
        method: "POST",
        body: JSON.stringify({ payment_method: payment_method }),
        headers: {
          Accept: "application/json",
          Authorization: getFromStorage("token"),
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((responseJson) => {
          console.log(responseJson);

          if(responseJson.status !== 200)
            throw new Error("Failed to remove");
          else
            this.updatePaymentMethods();
        })
        .catch((error) => {
          // this.props.onLogOut();
        });
    } catch (err) {}
  }

  render() {
    let language1 = null;
    let remove = this.removePaymentMethod;

    if(getFromStorage('language') !== null){
      language1 = require(`../utils/language_`+getFromStorage('language')); 
    }else{
      language1 = require(`../utils/language_gb`); 
    }
    let language = language1.language;
    //console.log(this.state)
    let template = []
    if(this.state.paymentMethods)
      template = this.state.paymentMethods.map(function(item, i) {
         return(
             <tr key={i}>
               <td>{language.card}</td>
                <td>{item.card.brand}</td>
                <td>XXXX-XXXX-XXXX-{item.card.last4}</td>
                <td>{item.card.exp_month}/{item.card.exp_year}</td>
                <td>
                  <Button 
                    className="btn-fill" color="danger" onClick={() => {
                      let sure = window.confirm("Confirm?");
                      if(!!sure)
                        remove(item.id)
                    }}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                </td>
             </tr>
         )
     })
    return (
      <>
        <div className="content">

          <AccountVerified  notify={this.props.notify}/>
          <ProfileInfo notify={this.props.notify} taxIDs={this.state.taxIDs} updatePage = {this.updateTax}/>
          <GetTaxes taxIDs={this.state.taxIDs} updateTax={this.updateTax}/>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">{language.mypaymentmethods}</h5>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter" >
                      <thead className="text-primary">
                        <tr>
                          <th>{language.paymentmethod}</th>
                          <th>{language.cardtype}</th>
                          <th>{language.number}</th>
                          <th>{language.expirydate}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {template}
                      </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  {
                    (!this.state.loading) ?
                      (                                    
                        <Button className="btn-fill" color="primary" onClick={this.showPayment}>
                          {language.addpaymentmethod}
                        </Button>
                      ):(
                        <ClipLoader
                          size={43}
                          color={"#F79434"}
                          loading={this.state.loading}
                        />
                      )
                  }
                </CardFooter>
              </Card>
            </Col>
            
          </Row>
          {
            (this.state.newPayment) ? (<NewPaymentMethod notify={this.props.notify} updatePaymentMethods={this.updatePaymentMethods}/>):(<></>)
          }
          
        </div>
      </>
    );
  }
}

export default Profile;
